import { ProfessionsEnum } from "../../components/quest/enums/ProfessionsEnum";
import QuestObjectiveEnum from "../../components/quest/enums/QuestObjectiveEnum";
import QuestRequirementsEnum from "../../components/quest/enums/QuestRequirements";
import { QuestRewardsEnum } from "../../components/quest/enums/QuestRewardsEnum";
import { QuestObject, QuestObjectiveCollectItem, QuestObjectiveEscortNpc, QuestObjectiveSlayCreature, QuestRequirementProfessionLevel, QuestRewardCurrency, QuestRewardExperience, QuestRewardItem } from "../../components/quest/interfaces/quest";
import { getIdForInputfieldDiv } from "../../constants/quest/InputWarningNoticeHelpers";
import { COLLECT_ITEM_NAME_NOTICE, COLLECT_ITEM_QUANTITY_NOTICE, ESCORT_NPC_NAME_NOTICE, QUEST_DESCRIPTION_NOTICE, QUEST_OBJECTIVES_AMOUNT_MAX_NOTICE, QUEST_OBJECTIVES_AMOUNT_MIN_NOTICE, QUEST_REQUIREMENTS_MAX_NOTICE, QUEST_REQUIREMENT_PROFESSION_LEVEL_NOTICE, QUEST_REWARDS_AMOUNT_MAX_NOTICE, QUEST_REWARDS_AMOUNT_MIN_NOTICE, QUEST_TITLE_NOTICE, REWARD_CURRENCY_COPPER_QUANTITY_NOTICE, REWARD_CURRENCY_GOLD_QUANTITY_NOTICE, REWARD_CURRENCY_SILVER_QUANTITY_NOTICE, REWARD_ITEM_NAME_NOTICE, REWARD_ITEM_QUANTITY_NOTICE, REWARD_PROFESSION_EXPERIENCE_NOTICE, REWARD_PROFESSION_NOT_FOUND_NOTICE, SLAY_CREATURE_KILL_COUNT_NOTICE, SLAY_CREATURE_NAME_NOTICE, TALK_WITH_NPC_NAME_NOTICE } from "../../constants/quest/InputfieldQuestNotice";
import { COLLECT_ITEM_NAME_LENGTH_MAX, COLLECT_ITEM_NAME_LENGTH_MIN, COLLECT_ITEM_QUANTITY_MAX, COLLECT_ITEM_QUANTITY_MIN, ESCORT_NPC_NAME_LENGTH_MAX, ESCORT_NPC_NAME_LENGTH_MIN, QUEST_DESCRIPTION_CHARACTERS_MAX, QUEST_DESCRIPTION_CHARACTERS_MIN, QUEST_OBJECTIVES_MAX, QUEST_OBJECTIVES_MIN, QUEST_REQUIRED_PROFESSION_LEVEL_MAX, QUEST_REQUIRED_PROFESSION_LEVEL_MIN, QUEST_REQUIREMENTS_MAX, QUEST_REWARDS_MAX, QUEST_REWARDS_MIN, QUEST_TITLE_CHARACTERS_MAX, QUEST_TITLE_CHARACTERS_MIN, REWARD_CURRENCY_COPPER_MAX, REWARD_CURRENCY_COPPER_MIN, REWARD_CURRENCY_GOLD_MAX, REWARD_CURRENCY_GOLD_MIN, REWARD_CURRENCY_SILVER_MAX, REWARD_CURRENCY_SILVER_MIN, REWARD_ITEM_NAME_LENGTH_MAX, REWARD_ITEM_NAME_LENGTH_MIN, REWARD_ITEM_QUANTITY_MAX, REWARD_ITEM_QUANTITY_MIN, REWARD_PROFESSION_EXPERIENCE_MAX, REWARD_PROFESSION_EXPERIENCE_MIN, SLAY_CREATURE_NAME_LENGTH_MAX, SLAY_CREATURE_NAME_LENGTH_MIN, SLAY_CREATURE_QUANTITY_MAX, SLAY_CREATURE_QUANTITY_MIN, TALK_WITH_NPC_NAME_LENGTH_MAX, TALK_WITH_NPC_NAME_LENGTH_MIN } from "../../constants/quest/InputfieldQuestRules";

export const SanitizeUserInputForQuestObject = (QuestObject: QuestObject): { id: string, message: string } | null => {
    if (QuestObject.title.length < QUEST_TITLE_CHARACTERS_MIN || QuestObject.title.length > QUEST_TITLE_CHARACTERS_MAX) {
        return {id: "quest_title_section", message: QUEST_TITLE_NOTICE};
    }

    if (QuestObject.description.length < QUEST_DESCRIPTION_CHARACTERS_MIN || QuestObject.description.length > QUEST_DESCRIPTION_CHARACTERS_MAX) {
        return {id: "quest_description", message: QUEST_DESCRIPTION_NOTICE};
    }

    const requirements = Object.values(QuestObject.requirements);
    if (requirements.length > QUEST_REQUIREMENTS_MAX) {
        return {id: "quest_requirements_section", message: QUEST_REQUIREMENTS_MAX_NOTICE};
    }

    if (requirements.length > 0) {
        for (const requirement of Object.values(requirements)) {
            if (requirement.type.toLowerCase() === QuestRequirementsEnum.LEVEL_REQUIREMENT.toLowerCase()) {
                const req = (requirement as QuestRequirementProfessionLevel);

                if (req.profession_level < QUEST_REQUIRED_PROFESSION_LEVEL_MIN || req.profession_level > QUEST_REQUIRED_PROFESSION_LEVEL_MAX) {
                    return {id: getIdForInputfieldDiv("requirement", req.id.toString(), req.type), message: QUEST_REQUIREMENT_PROFESSION_LEVEL_NOTICE};
                }
            }
        };
    }

    const objectives = Object.values(QuestObject.objectives);
    if (objectives.length > QUEST_OBJECTIVES_MAX) {
        return {id: "quest_objectives_section", message: QUEST_OBJECTIVES_AMOUNT_MAX_NOTICE};
    } else if (objectives.length < QUEST_OBJECTIVES_MIN) {
        return {id: "quest_objectives_section", message: QUEST_OBJECTIVES_AMOUNT_MIN_NOTICE};
    }

    if (objectives.length > 0) {
        for (const objective of Object.values(objectives)) {
            if (objective.type.toLowerCase() === QuestObjectiveEnum.COLLECT_ITEM.toLowerCase()) {
                const obj = (objective as QuestObjectiveCollectItem);

                if (obj.item_name.length < COLLECT_ITEM_NAME_LENGTH_MIN || obj.item_name.length > COLLECT_ITEM_NAME_LENGTH_MAX) {
                    return {id: getIdForInputfieldDiv("objective", obj.id.toString(), obj.type), message: COLLECT_ITEM_NAME_NOTICE};
                }

                if (obj.item_quantity < COLLECT_ITEM_QUANTITY_MIN || obj.item_quantity > COLLECT_ITEM_QUANTITY_MAX) {
                    return {id: getIdForInputfieldDiv("objective", obj.id.toString(), obj.type), message: COLLECT_ITEM_QUANTITY_NOTICE};
                }
            }

            if (objective.type.toLowerCase() === QuestObjectiveEnum.SLAY_CREATURE.toLowerCase()) {
                const obj = (objective as QuestObjectiveSlayCreature);

                if (obj.npc_name.length < SLAY_CREATURE_NAME_LENGTH_MIN || obj.npc_name.length > SLAY_CREATURE_NAME_LENGTH_MAX) {
                    return {id: getIdForInputfieldDiv("objective", obj.id.toString(), obj.type), message: SLAY_CREATURE_NAME_NOTICE};
                }

                if (obj.npc_kill_count < SLAY_CREATURE_QUANTITY_MIN || obj.npc_kill_count > SLAY_CREATURE_QUANTITY_MAX) {
                    return {id: getIdForInputfieldDiv("objective", obj.id.toString(), obj.type), message: SLAY_CREATURE_KILL_COUNT_NOTICE};
                }
            }

            if (objective.type.toLowerCase() === QuestObjectiveEnum.ESCORT_NPC.toLowerCase()) {
                const obj = (objective as QuestObjectiveEscortNpc);

                if (obj.npc_name.length < ESCORT_NPC_NAME_LENGTH_MIN || obj.npc_name.length > ESCORT_NPC_NAME_LENGTH_MAX) {
                    return {id: getIdForInputfieldDiv("objective", obj.id.toString(), obj.type), message: ESCORT_NPC_NAME_NOTICE};
                }
            }

            if (objective.type.toLowerCase() === QuestObjectiveEnum.TALK_WITH_NPC.toLowerCase()) {
                const obj = (objective as QuestObjectiveEscortNpc);

                if (obj.npc_name.length < TALK_WITH_NPC_NAME_LENGTH_MIN || obj.npc_name.length > TALK_WITH_NPC_NAME_LENGTH_MAX) {
                    return {id: getIdForInputfieldDiv("objective", obj.id.toString(), obj.type), message: TALK_WITH_NPC_NAME_NOTICE};
                }
            }
        };
    }

    const rewards = Object.values(QuestObject.rewards);
    if (rewards.length > QUEST_REWARDS_MAX) {
        return {id: "quest_rewards_section", message: QUEST_REWARDS_AMOUNT_MAX_NOTICE};

    } else if (rewards.length < QUEST_REWARDS_MIN) {
        return {id: "quest_rewards_section", message: QUEST_REWARDS_AMOUNT_MIN_NOTICE};
    }

    if (rewards.length > 0) {
        for (const reward of Object.values(rewards)) {
            if (reward.type.toLowerCase() === QuestRewardsEnum.CURRENCY_REWARD.toLowerCase()) {
                const rew = (reward as QuestRewardCurrency);

                if (rew.copper < REWARD_CURRENCY_COPPER_MIN || rew.copper > REWARD_CURRENCY_COPPER_MAX) {
                    return {id: getIdForInputfieldDiv("reward", rew.id.toString(), rew.type), message: REWARD_CURRENCY_COPPER_QUANTITY_NOTICE};
                }

                if (rew.silver < REWARD_CURRENCY_SILVER_MIN || rew.silver > REWARD_CURRENCY_SILVER_MAX) {
                    return {id: getIdForInputfieldDiv("reward", rew.id.toString(), rew.type), message: REWARD_CURRENCY_SILVER_QUANTITY_NOTICE};
                }

                if (rew.gold < REWARD_CURRENCY_GOLD_MIN || rew.gold > REWARD_CURRENCY_GOLD_MAX) {
                    return {id: getIdForInputfieldDiv("reward", rew.id.toString(), rew.type), message: REWARD_CURRENCY_GOLD_QUANTITY_NOTICE};
                }
            }

            if (reward.type.toLowerCase() === QuestRewardsEnum.ITEM_REWARD.toLowerCase()) {
                const rew = (reward as QuestRewardItem);

                if (rew.item_name.length < REWARD_ITEM_NAME_LENGTH_MIN || rew.item_name.length > REWARD_ITEM_NAME_LENGTH_MAX) {
                    return {id: getIdForInputfieldDiv("reward", rew.id.toString(), rew.type), message: REWARD_ITEM_NAME_NOTICE};
                }

                if (rew.item_quantity < REWARD_ITEM_QUANTITY_MIN || rew.item_quantity > REWARD_ITEM_QUANTITY_MAX) {
                    return {id: getIdForInputfieldDiv("reward", rew.id.toString(), rew.type), message: REWARD_ITEM_QUANTITY_NOTICE};
                }
            }

            if (reward.type.toLowerCase() === QuestRewardsEnum.XP_REWARD.toLowerCase()) {
                const rew = (reward as QuestRewardExperience);

                const professions = Object.values(ProfessionsEnum);
                let profFound = false;
                for (let index = 0; index < professions.length; index++) {
                    if (professions[index].toLowerCase() === rew.profession_name.toLowerCase()) {
                        profFound = true;
                        break;
                    }
                }

                if (!profFound) {
                    return {id: getIdForInputfieldDiv("reward", rew.id.toString(), rew.type), message: REWARD_PROFESSION_NOT_FOUND_NOTICE};
                }

                if (rew.profession_experience < REWARD_PROFESSION_EXPERIENCE_MIN || rew.profession_experience > (REWARD_PROFESSION_EXPERIENCE_MAX + 1)) {
                    return {id: getIdForInputfieldDiv("reward", rew.id.toString(), rew.type), message: REWARD_PROFESSION_EXPERIENCE_NOTICE};
                }
            }
        };
    }

    return null;
}