import React from 'react';
import styled from 'styled-components';
import SelectOption from '../../SelectOption';
import Button from '../../Button';

interface QuestPropertySectionProps extends React.PropsWithChildren {
    title: string;
    htmlId: string;
    selectedValue: string;
    options: string[]; 
    onSelectedChange: (value: string) => void; 
    onAdd: () => void; 
    propertyType: string;
}

const QuestPropertySection: React.FC<QuestPropertySectionProps> = ({
  title,
  htmlId,
  selectedValue,
  options,
  onSelectedChange,
  onAdd,
  children,
  propertyType
}) => {
  return (
    <SectionContainer>
      <div>
        <h2>{title}</h2>
        <div style={{display: 'flex', gap: '8px'}}>
          <SelectOption
            htmlId={htmlId}
            htmlName={htmlId}
            options={options}
            selected={selectedValue}
            onSelect={onSelectedChange}
            style={{height: '100%', marginTop: 'unset'}}
          />
          <Button onClick={onAdd}>Add {propertyType}</Button>
        </div>
      </div>
      {children}
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  margin-bottom: 12px;

  h2 {
    margin: 0;
  }

  > div {
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      
      > div {
        min-width: 120px;
      }

      select {
        display: inline-block;
        height: 100%;
        min-width: 175px;
        max-width: 175px;
        margin-top: 0px;
      }
      
      button:nth-child(2) {
        min-width: 120px;
      }
    }
  }
`;

export default QuestPropertySection;