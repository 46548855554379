export const QuestTitlesEnum = {
    TheCursedRuins: "The Cursed Ruins",
    ShadowsOfDarkness: "Shadows of Darkness",
    TheForgottenTomb: "The Forgotten Tomb",
    EchoesOfEternity: "Echoes of Eternity",
    TheSecretsOfAvalon: "The Secrets of Avalon",
    TheLostKingdom: "The Lost Kingdom",
    TrialsOfValor: "Trials of Valor",
    TheDarkenedForest: "The Darkened Forest",
    TheFallenEmpire: "The Fallen Empire",
    TheSorcerersTower: "The Sorcerer's Tower",
    TheHauntedMoor: "The Haunted Moor",
    TheShadowedCitadel: "The Shadowed Citadel",
    TheFrozenWastes: "The Frozen Wastes",
    TheEnchantedGrove: "The Enchanted Grove",
    TheMysticIsles: "The Mystic Isles",
    TheTwilightRift: "The Twilight Rift",
    TheLabyrinthOfChaos: "The Labyrinth of Chaos",
    TheEmeraldShores: "The Emerald Shores",
    TheBurningDesert: "The Burning Desert",
    TheCrystalCaves: "The Crystal Caves",
    TheEtherealFields: "The Ethereal Fields",
    TheCelestialSanctuary: "The Celestial Sanctuary",
    TheForbiddenCity: "The Forbidden City",
    TheEnigmaOfEldorado: "The Enigma of Eldorado",
    TheShatteredRealms: "The Shattered Realms",
    TheWhisperingWoods: "The Whispering Woods",
    TheEternalFlame: "The Eternal Flame",
    TheForsakenKeep: "The Forsaken Keep",
    TheLostLands: "The Lost Lands",
    TheDivineTemple: "The Divine Temple",
    TheCrypticCaverns: "The Cryptic Caverns",
    TheMysticMarsh: "The Mystic Marsh",
    TheShadowOfThrone: "The Shadow of Throne",
    TheAbyssalDepths: "The Abyssal Depths",
    TheDarkMoonRising: "The Dark Moon Rising",
    TheFrozenHeart: "The Frozen Heart",
    TheSandsOfTime: "The Sands of Time",
    TheAncientRuins: "The Ancient Ruins",
    TheGatesOfEternity: "The Gates of Eternity",
    TheTwilightGrove: "The Twilight Grove",
    TheWhispersOfWisdom: "The Whispers of Wisdom",
    TheLostLegacy: "The Lost Legacy",
    TheEternalForest: "The Eternal Forest",
    TheLairOfDarkness: "The Lair of Darkness",
    TheDreamersGate: "The Dreamer's Gate",
    TheSecretChamber: "The Secret Chamber",
    TheElderScrolls: "The Elder Scrolls",
}