import React, { useContext, PropsWithChildren, useEffect, useState } from 'react';
import ModalsProvider from './contexts/modal'
import ViewportProvider from './contexts/ViewportProvider';
import NavigationBarProvider from './contexts/NavigationSideBar/NavigationSideBar';
import { FirebaseProvider } from './contexts/Firebase/Firebase'
import { BrowserRouter, Routes, Route, Outlet, useNavigate, Navigate } from "react-router-dom";
import Layout from './components/Layout';
import SignIn from './views/SignIn';
import SignUp from './views/SignUp';
import ForgotPassword from './views/ForgotPassword';
import { QuestsOverview } from './views/Quests';
import NavigationSideBar from './components/NavigationSideBar';
import './App.css';
import styled from 'styled-components';
import { Context as NavContext } from './contexts/NavigationSideBar'
import { AlerithProvider } from './contexts/Alerith/Alerith';
import OfficialQuests from './views/Quests/Views/OfficialQuests';
import OfficialStoryLines from './views/Quests/Views/OfficialStoryLines';
import YourStorylines from './views/Quests/Views/YourQuests/YourStorylinesView';
import YourQuests from './views/Quests/Views/YourQuests/YourQuestsView';
import useAuthListener from './hooks/firebase/useAuthListener';
import AuthenticationCheck from './views/AuthenticationCheck';
import useTimer from './hooks/useTimer';
import { eventEmitter } from './components/eventMitter/eventMitter';
import useAuthenticationMessage from './hooks/firebase/useAuthenticationMessage';

const App: React.FC = () => {
  return (
    <Providers >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route element={<PublicRoute />}>
              <Route index element={<SignIn />} />
              <Route path="signin" element={<SignIn />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="forgotpassword" element={<ForgotPassword />} />
            </Route>
            {/* Define the dashboard route and its children here */}
            <Route element={<ProtectedRoute />}>
              <Route path="dashboard" element={<DashboardRoutes />}>
                <Route index path="quests" element={<QuestsOverview />} />
                <Route path="quests/overview" element={<QuestsOverview />} />
                <Route path="quests/official_quests" element={<OfficialQuests />} />
                <Route path="quests/official_quest_story_lines" element={<OfficialStoryLines />} />
                <Route path="quests/your_quests" element={<YourQuests />} />
                <Route path="quests/your_story_lines" element={<YourStorylines />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </Providers>
  );
}

const PublicRoute = () => {
  const { loggedIn, checkingStatus } = useAuthListener();

  if (checkingStatus) {
    return <div></div>; // Optionally display a loading indicator
  }

  return !loggedIn ? <Outlet /> : <Navigate to="/dashboard/quests" replace />;
};

const ProtectedRoute: React.FC = () => {
  const { loggedIn, checkingStatus } = useAuthListener();
  const [readyToNavigate, setReadyToNavigate] = useState(false);
  const navigate = useNavigate();

  const [secRemaining, resetTimer] = useTimer(5, () => {
    if (!checkingStatus) {
      setReadyToNavigate(true);
    }
  });

  useEffect(() => {
    const handleReadyToNavigate = (ready: any) => {
      if (ready) {
        setReadyToNavigate(false)
        resetTimer();
      }
    };

    // Subscribe to the 'readyToNavigate' event
    eventEmitter.on('readyToNavigate', handleReadyToNavigate);

    // Cleanup: remove the event listener on component unmount
    return () => {
      eventEmitter.removeListener('readyToNavigate', handleReadyToNavigate);
    };
  }, [navigate]);

  if (checkingStatus || !readyToNavigate) {
    // Render the AuthenticationCheck component while checking the user's status or waiting for the timer
    // This component will display the relevant messages and handle the countdown
    return <AuthenticationCheck setReadyToNavigate={setReadyToNavigate} isAuthenticated={loggedIn} secRemaining={secRemaining} checkingStatus={checkingStatus} />;
  }

  // If loggedIn is true, render the children components inside this ProtectedRoute (using <Outlet />)
  // If loggedIn is false, and readyToNavigate is true, then redirect to the /signin page
  // This logic ensures that the redirection to /signin only occurs after the timer finishes and checkingStatus is false
  return loggedIn ? <Outlet /> : <Navigate to="/signin" replace />;
};

const DashboardRoutes: React.FC = () => {
  const { navigationBarToggled } = useContext(NavContext);

  return (
    <AlerithProvider>
      <NavigationSideBar />
      <DashboardContainerWrapper toggled={navigationBarToggled}>
        <Outlet />
      </DashboardContainerWrapper>
    </AlerithProvider>
  );
};


const DashboardContainerWrapper = styled.div<{ toggled: boolean }>`
display: flex;
/*height: calc(100% - var(--header-top-size-height) - 32px); previous */
min-height: calc(100% - var(--header-top-size-height) - 32px);
margin-top: var(--header-top-size-height);
  transition: margin .2s ease-in-out;

      @media only screen and (min-width: 968px) {
    padding: 0;
    min-height: calc(100vh - var(--header-top-size-height));
    margin-left: ${(props) => props.toggled ? 200 : 0}px; /*260*/
    min-width: calc(100vw - 220px);
    margin-top: var(--header-top-size-height);

        height: auto;
  }
`

interface ProvidersProps extends PropsWithChildren {
  children: React.ReactNode;
}

const Providers: React.FC<ProvidersProps> = ({ children }) => {
  return (
    <FirebaseProvider>

      <ViewportProvider>
        <NavigationBarProvider>
          <ModalsProvider>
            {children}
          </ModalsProvider>
        </NavigationBarProvider>
      </ViewportProvider>
    </FirebaseProvider >
  );
};
export default App;
