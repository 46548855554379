
import { useState, useEffect, useCallback } from 'react';

type CallbackFunction = () => void;

function useTimer(duration: number, callback: CallbackFunction): [number, () => void] {
    const [timer, setTimer] = useState<number>(duration);

    useEffect(() => {
        if (timer === 0) {
            callback();
            return;
        }

        const intervalId = setInterval(() => {
            setTimer(prevTimer => prevTimer - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timer, callback]);

    const resetTimer = useCallback(() => {
        setTimer(duration);
    }, [duration]);

    return [timer, resetTimer];
}

export default useTimer;