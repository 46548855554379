import React from 'react';
import styled from 'styled-components';

const ListContainer = styled.div`
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px 10px #00000042 inset;
  height: 100%;
  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 8px 0;
  }
  ul {
    padding: unset;
    margin: 0;
    padding: 0 8px;
    flex: 1;
  }
  li {
    list-style-type: none;
    background: rgb(17 16 15);
    color: #756a5c;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border: 1px solid #2b2824;
    display: flex;
    justify-content: space-between;
    span {
      display: inline-block;
      font-size: 1.3rem;
    }
  }
  li:last-child {
    margin-bottom: 0; /* Remove margin for the last li element */
  }
  li:hover {
    border: 1px solid #d88e27;
    color: #e7b051;
  }
`;

export interface GenericListProps<T> {
  items: T[];
  renderItem: (item: T, isSelected: boolean) => React.ReactNode;
  getKey: (item: T) => React.Key;
  selected: T | null;
  onSelect: (item: T) => void;
}

const GenericList = <T,>({
  items,
  renderItem,
  getKey,
  selected,
  onSelect
}: GenericListProps<T>): JSX.Element => {
  return (
    <ListContainer>
      <div>
        <ul>
          {items.map((item) => {
            // Check if selected is not null before comparing keys
            const isSelected = selected !== null ? getKey(selected) === getKey(item) : false;
            return (
              <li key={getKey(item)} className={isSelected ? 'selected_quest' : ''} onClick={() => onSelect(item)}>
                {renderItem(item, isSelected)}
              </li>
            );
          })}
        </ul>
      </div>
    </ListContainer>
  );
};

export default GenericList;
