import React from 'react'
import styled from 'styled-components';
import ImageX from '../../assets/button/X.png'
import Button from '../Button';

interface ClosebuttonProps {
  onClick: () => void
  style?: React.CSSProperties
}

const CloseButton: React.FC<ClosebuttonProps> = ({ onClick, style }) => {
  return (
    <ButtonDivStyled style={style}>
      <Button onClick={onClick}><img src={ImageX} alt='close' /></Button>
    </ButtonDivStyled>
  )
}

export const ButtonDivStyled = styled.div`
  img {
    width: 17px;
    height: 17px;
    display: block;
  }
`

export default CloseButton
