import React from 'react'
import Modal, { ModalProps } from '../Modal'
import styled from 'styled-components'
import TextContainer from '../TextContainer';
import Button from '../Button';

const TermsPolicies: React.FC<ModalProps> = ({ onDismiss }) => {
    const handleDismiss = () => {
        onDismiss && onDismiss()
    }
    return (
        <Modal title={'Terms And Conditions'}>
            <TermsContainer>
                <div>
                    <TextContainer>
                        <h1>Terms of Service and Use Agreement</h1>

                        <p>Welcome to the Alerith Quest Editor, a proprietary tool of Satoro, designed for creating and editing quests for the upcoming MMORPG, Alerith. By accessing and using our services at https://editor.alerith.com, you agree to the following terms and conditions. Please read them carefully.</p>

                        <h2>Acceptance of Terms</h2>
                        <p>By registering for and using the Alerith Quest Editor, you agree to be bound by these Terms of Service ("Terms"), all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this service.</p>

                        <h2>Intellectual Property Rights</h2>
                        <p>
                            All materials and content created, uploaded, or contributed by you on the Alerith Quest Editor, including but not limited to quests, concepts, storylines, general ideas, item names, NPC names, quest storylines, quest names, quest descriptions, and storyline descriptions, become the exclusive intellectual property of Satoro. You grant Satoro a perpetual, irrevocable, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, and display such content in any form, media, or technology.
                        </p>
                        <h2>Restrictions</h2>
                        <p>You are expressly prohibited from:

                            Exporting any data or content from the Alerith Quest Editor without explicit permission.
                            Using the Alerith Quest Editor for the creation or development of any game or interactive content outside of the Alerith ecosystem without a written agreement or license from Satoro.
                            Engaging in any activity that interferes with or disrupts the Alerith Quest Editor services or servers.
                        </p>
                        <h2>Licensing Fees</h2>
                        <p>
                            Should you use the Alerith Quest Editor's tool, content, or intellectual property for commercial purposes outside of the agreed terms, you agree to pay Satoro a licensing fee to be determined by Satoro at the time of the violation discovery.
                        </p>

                        <h2>Disclaimer of Warranties</h2>
                        <p>
                            The Alerith Quest Editor is provided "as is". Satoro makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property, or other violation of rights.
                        </p>
                        <h2>Limitation of Liability</h2>
                        <p>
                            In no event shall Satoro or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on the Alerith Quest Editor, even if Satoro or a Satoro authorized representative has been notified orally or in writing of the possibility of such damage.
                        </p>
                        <h2>Amendments to Terms</h2>
                        <p>
                            Satoro reserves the right, at its sole discretion, to modify or replace these Terms at any time. By continuing to access or use our service after those revisions become effective, you agree to be bound by the revised terms.
                        </p>
                        <h2>Governing Law</h2>
                        <p>
                            Any claim relating to the Alerith Quest Editor's website shall be governed by the laws of the jurisdiction in which Satoro operates without regard to its conflict of law provisions.
                        </p>
                        <h2>User Registration and Account Management</h2>
                        <p>
                            To access certain features of the Alerith Quest Editor, you must register and create a personal account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. Satoro reserves the right to suspend or terminate your account if any information provided during the registration process or thereafter proves to be inaccurate, not current, or incomplete.
                        </p>
                        <p>
                            You are responsible for safeguarding your password and any other credentials used to access your account. You, and not Satoro, are responsible for any activities or actions under your account, whether or not you have authorized such activities or actions. You should immediately notify Satoro of any unauthorized use of your account.
                        </p>
                        <h2>Data Privacy and Security</h2>
                        <p>
                            Protecting your privacy and personal data is of utmost importance to us. Information collected from you, including but not limited to personal identity information, is subject to our Privacy Policy, which governs our collection, use, and disclosure of your information. The Privacy Policy is incorporated into these Terms by reference. By using the Alerith Quest Editor, you consent to the data practices stated in our Privacy Policy.
                        </p>
                        <h2>Use of Google Analytics</h2>
                        <p>
                            The Alerith Quest Editor uses Google Analytics, a web analytics service provided by Google, Inc. ("Google"). Google Analytics uses "cookies", which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States.
                        </p>
                        <p>
                            Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators, and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.
                        </p>
                        <p>
                            For more information on Google Analytics terms of service, please visit <a href="https://www.google.com/analytics/terms/" target="_blank" rel="noopener noreferrer">https://www.google.com/analytics/terms/</a>.
                        </p>
                    </TextContainer>
                    <div>
                        <Button onClick={handleDismiss}>Close Terms And Conditions</Button>
                    </div>
                </div>

            </TermsContainer>
        </Modal>
    )
}

const TermsContainer = styled.div`
height: 100%;
width: 100%;

> div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;

    gap: 8px;
    h2 {
        color: #c1b197;
    }

    > div:nth-child(2) {
        
    }
}
   
`
export default TermsPolicies