import React from 'react';

interface InputfieldItemProps {
    htmlId: string;
    inputType: string;
    inputPlaceholder: string;
    labelValue?: string;
    inputValue: string;
    onChange: (value: string) => void;
    minNumber?: number; // New prop for minimum number
    maxNumber?: number; // New prop for maximum number
    maxLength?: number; // New prop for maximum character length
    allowDecimals?: boolean; // New prop to control decimal input
}

const InputfieldItem: React.FC<InputfieldItemProps> = ({
        htmlId,
        inputType,
        inputPlaceholder,
        labelValue,
        inputValue,
        onChange,
        minNumber,
        maxNumber,
        maxLength,
        allowDecimals = true
    } 
    ) => {
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;

        // Handle number inputs
        if (inputType === 'number' && !allowDecimals) {
            value = value.includes('.') ? value.split('.')[0] : value; // Remove decimals if not allowed
        }

        if (inputType === 'number') {
            const numericValue = parseFloat(value);
            if (minNumber !== undefined && numericValue < minNumber) value = minNumber.toString();
            if (maxNumber !== undefined && numericValue > maxNumber) value = maxNumber.toString();
        }

        // Handle text inputs for maxLength
        if (inputType === 'text' && maxLength !== undefined) {
            value = value.slice(0, maxLength);
        }

        onChange(value);
    };

    return (
        <div>
            {labelValue && (<label htmlFor={htmlId}>{labelValue}</label>)}
            <input
                id={htmlId}
                type={inputType}
                placeholder={inputPlaceholder}
                value={inputValue}
                onChange={handleInputChange}
                maxLength={inputType === 'text' ? maxLength : undefined} // Apply maxLength only for text inputs
                // min and max attributes are only valid for inputs with type="number"
                min={inputType === 'number' ? minNumber : undefined}
                max={inputType === 'number' ? maxNumber : undefined}
            />
    </div>
    )
}

export default InputfieldItem