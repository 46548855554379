import { COLLECT_ITEM_NAME_LENGTH_MAX, COLLECT_ITEM_NAME_LENGTH_MIN, COLLECT_ITEM_QUANTITY_MAX, COLLECT_ITEM_QUANTITY_MIN, ESCORT_NPC_NAME_LENGTH_MAX, ESCORT_NPC_NAME_LENGTH_MIN, QUEST_DESCRIPTION_CHARACTERS_MAX, QUEST_DESCRIPTION_CHARACTERS_MIN, QUEST_OBJECTIVES_MAX, QUEST_OBJECTIVES_MIN, QUEST_REQUIRED_PROFESSION_LEVEL_MAX, QUEST_REQUIRED_PROFESSION_LEVEL_MIN, QUEST_REQUIREMENTS_MAX, QUEST_REWARDS_MAX, QUEST_REWARDS_MIN, QUEST_TITLE_CHARACTERS_MAX, QUEST_TITLE_CHARACTERS_MIN, REWARD_CURRENCY_COPPER_MAX, REWARD_CURRENCY_COPPER_MIN, REWARD_CURRENCY_GOLD_MAX, REWARD_CURRENCY_GOLD_MIN, REWARD_CURRENCY_SILVER_MAX, REWARD_CURRENCY_SILVER_MIN, REWARD_ITEM_NAME_LENGTH_MAX, REWARD_ITEM_NAME_LENGTH_MIN, REWARD_ITEM_QUANTITY_MAX, REWARD_ITEM_QUANTITY_MIN, REWARD_PROFESSION_EXPERIENCE_MAX, REWARD_PROFESSION_EXPERIENCE_MIN, SLAY_CREATURE_NAME_LENGTH_MAX, SLAY_CREATURE_NAME_LENGTH_MIN, SLAY_CREATURE_QUANTITY_MAX, SLAY_CREATURE_QUANTITY_MIN, TALK_WITH_NPC_NAME_LENGTH_MAX, TALK_WITH_NPC_NAME_LENGTH_MIN } from "./InputfieldQuestRules";

export const QUEST_TITLE_NOTICE = "The quest title should be between " + QUEST_TITLE_CHARACTERS_MIN.toString() + " and " + QUEST_TITLE_CHARACTERS_MAX.toString() + " characters.";
export const QUEST_DESCRIPTION_NOTICE = "The quest description should be between " + QUEST_DESCRIPTION_CHARACTERS_MIN.toString() + " and " + QUEST_DESCRIPTION_CHARACTERS_MAX.toString() + " characters.";

export const QUEST_REQUIREMENTS_MAX_NOTICE = "The maximum amount of requirements is " + QUEST_REQUIREMENTS_MAX.toString();

export const QUEST_REQUIREMENT_PROFESSION_LEVEL_NOTICE = "Required profession level must be between " + QUEST_REQUIRED_PROFESSION_LEVEL_MIN.toString() + " and " + QUEST_REQUIRED_PROFESSION_LEVEL_MAX.toString();

export const QUEST_OBJECTIVES_AMOUNT_MAX_NOTICE = "The maximum amount of objectives is " + QUEST_OBJECTIVES_MAX.toString();
export const QUEST_OBJECTIVES_AMOUNT_MIN_NOTICE = "The minimum amount of objectives is " + QUEST_OBJECTIVES_MIN.toString();

export const COLLECT_ITEM_NAME_NOTICE = "The name of the item must be between " + COLLECT_ITEM_NAME_LENGTH_MIN.toString() + " and " + COLLECT_ITEM_NAME_LENGTH_MAX.toString() + " characters.";
export const COLLECT_ITEM_QUANTITY_NOTICE = "The amount of items to collect must be between " + COLLECT_ITEM_QUANTITY_MIN.toString() + " and " + COLLECT_ITEM_QUANTITY_MAX.toString();

export const SLAY_CREATURE_NAME_NOTICE = "The name of the creature/npc must be between " + SLAY_CREATURE_NAME_LENGTH_MIN.toString() + " and " + SLAY_CREATURE_NAME_LENGTH_MAX.toString() + " characters."
export const SLAY_CREATURE_KILL_COUNT_NOTICE = "The amount of creatures/npcs to defeat must be between " + SLAY_CREATURE_QUANTITY_MIN.toString() + " and " + SLAY_CREATURE_QUANTITY_MAX.toString();

export const ESCORT_NPC_NAME_NOTICE = "The name of the creature/npc must be between " + ESCORT_NPC_NAME_LENGTH_MIN.toString() + " and " + ESCORT_NPC_NAME_LENGTH_MAX.toString() + " characters.";

export const TALK_WITH_NPC_NAME_NOTICE = "The name of the creature/npc must be between " + TALK_WITH_NPC_NAME_LENGTH_MIN.toString() + " and " + TALK_WITH_NPC_NAME_LENGTH_MAX.toString() + " characters.";

export const QUEST_REWARDS_AMOUNT_MAX_NOTICE = "The maximum amount of rewards is " + QUEST_REWARDS_MAX.toString();
export const QUEST_REWARDS_AMOUNT_MIN_NOTICE = "The minimum amount of rewards is " + QUEST_REWARDS_MIN.toString();

export const REWARD_CURRENCY_COPPER_QUANTITY_NOTICE = "The amount of copper should be between " + REWARD_CURRENCY_COPPER_MIN.toString() + " and " + REWARD_CURRENCY_COPPER_MAX.toString();
export const REWARD_CURRENCY_SILVER_QUANTITY_NOTICE = "The amount of silver should be between " + REWARD_CURRENCY_SILVER_MIN.toString() + " and " + REWARD_CURRENCY_SILVER_MAX.toString();
export const REWARD_CURRENCY_GOLD_QUANTITY_NOTICE = "The amount of gold should be between " + REWARD_CURRENCY_GOLD_MIN.toString() + " and " + REWARD_CURRENCY_GOLD_MAX.toString();

export const REWARD_ITEM_NAME_NOTICE = "The name of the item must be between " + REWARD_ITEM_NAME_LENGTH_MIN.toString() + " and " + REWARD_ITEM_NAME_LENGTH_MAX.toString() + " characters.";
export const REWARD_ITEM_QUANTITY_NOTICE = "The amount of items to collect must be between " + REWARD_ITEM_QUANTITY_MIN.toString() + " and " + REWARD_ITEM_QUANTITY_MAX.toString();

export const REWARD_PROFESSION_NOT_FOUND_NOTICE = "That's an invalid profession. Please choose a different one from the list.";


export const REWARD_PROFESSION_EXPERIENCE_NOTICE = "The amount of profession experience should be between " + REWARD_PROFESSION_EXPERIENCE_MIN.toString() + " and " + REWARD_PROFESSION_EXPERIENCE_MAX.toString() + ".";