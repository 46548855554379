import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import WarningMessage from "../../components/WarningMessage";
import styled from 'styled-components';
import { useFirebase } from '../../contexts/Firebase/Firebase';
import { doc, setDoc } from 'firebase/firestore';
import MainContainer from "../../components/MainContainer";
import InputfieldNew from "../../components/InputfieldNew";
import AuthFooter from "../../components/AuthFooter";
import Button from "../../components/Button";
import TermsPolicies from "../../components/TermsPolicies";
import useModal from "../../hooks/useModal";


const Signup: React.FC = () => {
  const navigate = useNavigate();
  const { auth, db, createUserWithEmailAndPassword } = useFirebase();
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userConfirmPassword, setUserConfirmPassword] = useState("");
  const [notice, setNotice] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

const [onPresentTermsConditions] = useModal(<TermsPolicies/>)
  const handleInputChange = (field: string, value: string) => {
    switch (field) {
      case "email":
        setUserEmail(value);
        break;
      case "password":
        setUserPassword(value);
        break;
      case "confirmPassword":
        setUserConfirmPassword(value);
        break;
      default:
        break;
    }
  }

  const isValidEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const signupWithUsernameAndPassword = async () => {
    if (!termsAccepted) {
      setNotice("You must agree to the terms and conditions to sign up.");
      return;
    }

    if (!isValidEmail(userEmail)) {
      setNotice("Please enter a valid email address.");
      return;
    }

    if (userPassword.length < 8) {
      setNotice("Password must be at least 8 characters long.");
      return;
    }

    if (userPassword !== userConfirmPassword) {
      setNotice("Passwords don't match. Please try again.");
      return;
    }

try {
      const userCredential = await createUserWithEmailAndPassword(auth, userEmail, userPassword);
      const user = userCredential.user;

      const userDocRef = doc(db, 'users', user.uid);
      await setDoc(userDocRef, {
        display_name: '',
        rank: 'default',
        created_at: new Date(),
        terms_accepted: termsAccepted, 
      });

      navigate("/");
    } catch (error) {
      console.error("Error signing up:", error);
      setNotice("Sorry, something went wrong. Please try again.");
    }
  };

  return (
    <OuterContainer>
      <MainContainerWrapper>
        <MainContainerContent>
          <AppTitleContainer>
            <h1>Alerith</h1>
          </AppTitleContainer>
          <MainContainer title={'Create An Account'} type={'other'}>
            <MainContainerInner>
              <h2>Create your account now</h2>
              <p>Welcome! Please enter your details.</p>
              <InputfieldContainer>
                {notice && <WarningMessage message={notice} />}
                <div className='wrapper_inputfield'>
                  <div>
                    <label htmlFor='signupEmail'>Email</label>
                  </div>
                  <InputfieldNew
                    type="text"
                    id="signupEmail"
                    placeholder="Enter your email"
                    value={userEmail}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                  />
                </div>
                <div className='wrapper_inputfield'>
                  <div>
                    <label htmlFor='password'>Password</label>
                  </div>
                  <InputfieldNew
                    type="password"
                    id="password"
                    placeholder="Enter Password"
                    value={userPassword}
                    onChange={(e) => handleInputChange("password", e.target.value)}
                  />
                </div>
                <div className='wrapper_inputfield'>
                  <div>
                    <label htmlFor='confirmPassword'>Confirm Password</label>
                  </div>
                  <InputfieldNew
                    type="password"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    value={userConfirmPassword}
                    onChange={(e) => handleInputChange("confirmPassword", e.target.value)}
                  />
                </div>
                <div className="wrapper_inputfield">
                <label className="custom-checkbox">
                    <input type="checkbox" hidden required onChange={() => setTermsAccepted(!termsAccepted)}
                    checked={termsAccepted}/>
                    <span className="checkmark"></span>
                    I agree to <a style={{marginLeft: '4px'}}onClick={onPresentTermsConditions}>Terms and conditions</a>
                  </label>
                  </div>
              </InputfieldContainer>
              <ButtonContainer>
                <Button onClick={signupWithUsernameAndPassword}>Sign up</Button>
              </ButtonContainer>
            </MainContainerInner>
          </MainContainer>
        </MainContainerContent>
      </MainContainerWrapper>
      <AuthFooter text="Already have an account?" url_text="Sign In!" url_link="/signin" />

    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
`

const ButtonContainer = styled.div`
  margin-top: 16px;
  width: 100%;

  > button {
    width: 100%;
  }
`

const MainContainerInner = styled.div`
  width: 100%;

  h2 {
    margin: 0;
    color: #c1b197;
  }

  p {
    margin: 8px 0;
  }
`

const InputfieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  
`

const MainContainerContent = styled.div`
display: flex;
flex-direction: column;
align-items: center;

  > #main__container {
    max-width: 500px;
  }
  
  
 .main__container__content {
    padding: 16px;
    box-shadow: 0 0 9px 4px #0000008f;
  }
`

const AppTitleContainer = styled.div`
padding: 40px;
text-align: center;

  h1 {
    font-size: 5rem;
    color: #c1b197;
    text-shadow: 0 0 20px black;
    margin: 0;
  }
`

const MainContainerWrapper = styled.div`
  position: fixed;
  width: calc(100% - 16px);
  height: calc(100% - 16px - 50px);
  padding: 8px;
  box-shadow: 0 0 20px 4px inset #0000008f;

`

export default Signup;