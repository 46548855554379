import React from 'react'
import './TextContainer.css'
import CornerTextContainer from '../../assets/window/text_box_corner.png'

const TextContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <div className="container_text_with_corner_img">
            <img src={CornerTextContainer} className="corner_text_container" />
            <img src={CornerTextContainer} className="corner_text_container" />
            <img src={CornerTextContainer} className="corner_text_container" />
            <img src={CornerTextContainer} className="corner_text_container" />
            <div>
                {children}
            </div>
        </div>
    )
}

export default TextContainer