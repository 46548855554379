import React, { PropsWithChildren, useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../Header";

interface MainContainerProps extends PropsWithChildren {
  title?: string;
  children: React.ReactNode;
  type?: 'primary' | 'node' | 'other';
}

const MainContainer: React.FC<MainContainerProps> = ({ title, children, type = "primary" }) => {
  const [containerHeight, setContainerHeight] = useState(-1);

  useEffect(() => {
    if (containerHeight == -1 && type == "primary") {


      const parentElement = document.getElementById('main__container');

      const headerSize = document.getElementById('main__container__header');

      if (parentElement && headerSize) { // Check if parentElement is not null
        setContainerHeight(parentElement.offsetHeight - headerSize.offsetHeight - 1);

        const resizeListener = () => {
          setContainerHeight(parentElement.offsetHeight - headerSize.offsetHeight - 1);
        };

        window.addEventListener('resize', resizeListener);

        return () => {
          window.removeEventListener('resize', resizeListener);
        };
      }
    }
  }, []);

  return (
    <StyledContainer id="main__container">
      {title && (
        <Header header_title={title} html_id="main__container__header" />
      )}
      <div className="main__container__content" style={type && type == "primary" ? { maxHeight: containerHeight } : { maxHeight: 'unset' }}>
        {children}
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
    /*margin: 16px;*/
    background: #121010;
    width: 100%;
    /*height: 100%;*/
    border: 1px solid #463e32;
    display: flex;
    flex-direction: column;
    background: linear-gradient(0deg, rgb(27 18 17) 0%, rgba(17,16,15,1) 30%, rgba(17,16,15,1) 70%, rgb(27 18 17) 100%);
    
    > div:nth-child(2) {
      display: flex;
        flex: 1;
        height: 100%;
    }
`

export default MainContainer