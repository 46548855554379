import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import './AccordionMenu.css';

interface MenuItem {
  title: string;
  link: string;
}

interface AccordionMenuProps {
  accordionTitle: string;
  subItems: MenuItem[];
}

const AccordionMenu: React.FC<AccordionMenuProps> = ({ accordionTitle, subItems }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
      <li className="sub-menu">
        <a onClick={toggleAccordion}>
          <i className="fa fa-cogs"></i><span>{accordionTitle}</span>
          <i className={`arrow fa fa-angle-right pull-right ${isOpen ? 'open' : ''}`}></i>
        </a>
        <ul className={`accordion-items ${isOpen ? 'open' : ''}`}>
          {subItems.map((item, index) => (
            <li key={index}><Link to={item.link}>{item.title}</Link></li>
          ))}
        </ul>
      </li>
  );
};

export default AccordionMenu;