import React, { createContext, useEffect, useState, ReactNode, useMemo } from 'react';
import {
    getAuth,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    User as FireBaseUser,
    Auth,
    onAuthStateChanged
} from 'firebase/auth';
import { collection, addDoc, getDoc, doc, getFirestore, Firestore, getDocs, setDoc, CollectionReference, deleteDoc, updateDoc } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import 'firebase/firestore';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

import { QuestObject, StoryQuestDetails, StorylineDetails } from '../../components/quest/interfaces/quest';
import { StoryQuestContainer } from '../../components/quest/interfaces/quest/firebase/StoryQuestContainer';
import { AUTH_MESSAGE_NOT_SIGNED_IN } from '../../constants/authMessage';


export interface ExtendedUser extends FireBaseUser {
    rank: string,
    display_name: string,
    created_at: Date,
}


// Define the type for the Firebase context
interface FirebaseContextType {
    auth: Auth;
    db: Firestore;
    sendPasswordResetEmail: typeof sendPasswordResetEmail;
    signInWithEmailAndPassword: typeof signInWithEmailAndPassword;
    createUserWithEmailAndPassword: typeof createUserWithEmailAndPassword;
    createQuestAndSaveToDatabase: (user: ExtendedUser | null, quest: QuestObject | null) => Promise<QuestObject | null>;
    createQuestStorylineAndSaveToDatabase: (user: ExtendedUser | null, quest: StorylineDetails | null) => Promise<StorylineDetails | null>;
    retrieveQuestsForUserUid: (user: ExtendedUser | null) => Promise<QuestObject[]>;
    retrieveQuestStoryLinesForUserUid: (user: ExtendedUser | null) => Promise<StorylineDetails[]>;
    editQuestAndSaveToDatabase: (user: ExtendedUser | null, quest: QuestObject | null) => Promise<QuestObject | null>;
    editQuestStoryLineAndSaveToDatabase: (user: ExtendedUser | null, questStoryLine: StorylineDetails | null) => Promise<StorylineDetails | null>;
    deleteQuestStoryLineFromDatabase: (user: ExtendedUser | null, questStoryLineId: string | null) => Promise<string | null>;
    deleteQuestFromDatabase: (user: ExtendedUser | null, questId: string | null) => Promise<string | null>;
    setUser: (user: ExtendedUser | null) => void;
    user: ExtendedUser | null; // Include User type here
    setAuthCheckType: (value: number) => void;
    authCheckType: number;
    setAuthMessage: (value: string) => void;
    authMessage: string;
}

// Create the Firebase context
export const FirebaseContext = createContext<FirebaseContextType | null>(null);

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
};

// Initialize Firebase app and retrieve auth and firestore instances
const app = initializeApp(firebaseConfig);

//Initialize Firebase App Check
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LfRR2spAAAAAJcvS0Fkj23KMA67JWBMQngKUzX4'),
    isTokenAutoRefreshEnabled: true, // Optional, enable automatic token refreshing
});

const auth = getAuth(app);
const db = getFirestore(app);


//createQuestFirestoreWithSubcollections
const createQuestAndSaveToDatabase = async (user: ExtendedUser | null, quest: QuestObject | null): Promise<QuestObject | null> => {

    if (!user || !quest || Object.values(quest.objectives).length === 0) {
        return null;
    }

    try {
        // Get reference to the user's document under QuestsByUsers collection
        const userQuestsRef = collection(db, "QuestsByUsers", user.uid, "UserQuests");

        // Prepare the quest object for saving
        // Ensure objectives, rewards, and requirements are stored as maps within the quest object
        const questForSave = {
            ...quest,
            objectives: { ...quest.objectives }, // Already in the correct format, included for clarity
            rewards: { ...quest.rewards },
            // If you have requirements similar to objectives and rewards, ensure they're also formatted as a map
            requirements: { ...quest.requirements }, // Uncomment and adjust if applicable
        };

        // Add the quest data to a new document in the user's UserQuests subcollection
        // This now includes objectives and rewards as nested maps
        const questRef = await addDoc(userQuestsRef, questForSave);

        // Update the quest object to include the document ID as its id (instead of uid)
        const questWithId = {
            ...questForSave,
            id: questRef.id // Update property name to 'id'
        };
        return questWithId; // This now includes the Firestore document ID and any other modifications
    } catch (error) {
        console.error("Error adding quest data to Firestore with subcollections: ", error);
        return null;
    }
};

const deleteQuestFromDatabase = async (user: ExtendedUser | null, questId: string | null): Promise<string | null> => {
    if (!user || !questId) return null; // Simplified null check

    try {
        // Get reference to the user's quest document under QuestsByUsers collection
        const questRef = doc(db, "QuestsByUsers", user.uid, "UserQuests", questId);

        // Delete the quest document itself
        await deleteDoc(questRef);

        return questId; // Return the ID of the deleted quest
    } catch (error) {
        console.error("Error deleting quest data from Firestore: ", error);
        return null;
    }
};

const createQuestStorylineAndSaveToDatabase = async (user: ExtendedUser | null, questStoryLine: StorylineDetails | null): Promise<StorylineDetails | null> => {
    if (user == null || questStoryLine == null) {
        return null; // Early return for invalid input or insufficient permissions
    }

    try {
        const userQuestStoryLinesRef = collection(db, "QuestStoryLinesByUsers", user.uid, "UserQuestStoryLines");

        // Construct the story quests object
        const storyQuests: { [quest_id: string]: StoryQuestDetails } = {};
        Object.values(questStoryLine.story_quests).forEach(quest => {
            const prerequisite_quests: Record<string, string> = {};
            Object.values(quest.prerequisite_quests).forEach(prereqQuest => {
                prerequisite_quests[prereqQuest] = prereqQuest;
            });

            storyQuests[quest.quest.id] = {
                x: quest.x,
                y: quest.y,
                prerequisite_quests: prerequisite_quests
            };
        });

        // Create a document with storyline details
        const docRef = await addDoc(userQuestStoryLinesRef, {
            title: questStoryLine.title,
            description: questStoryLine.description,
            type: questStoryLine.type,
            story_quests: storyQuests
        });

        return {
            ...questStoryLine,
            id: docRef.id, // Add the Firestore document ID to the storyline object
        };
    } catch (error) {
        console.error("Error adding quest storyline data to Firestore: ", error);
        return null;
    }
};


// Assuming ExtendedUser is correctly defined elsewhere
const retrieveQuestStoryLinesForUserUid = async (user: ExtendedUser | null): Promise<StorylineDetails[]> => {
    if (user == null) return [];

    const db = getFirestore();
    const userQuestStoryLinesRef = collection(db, `QuestStoryLinesByUsers/${user.uid}/UserQuestStoryLines`);

    try {
        const userQuestStoryLinesSnapshot = await getDocs(userQuestStoryLinesRef);

        const allStorylines: StorylineDetails[] = [];

        userQuestStoryLinesSnapshot.forEach((doc) => {
            const { title, description, type, story_quests } = doc.data() as {
                title: string;
                description: string;
                type: "Main Story" | "Side Story";
                story_quests: StoryQuestContainer;
            };

            const storylineDetails: StorylineDetails = {
                id: doc.id,
                title,
                description,
                type,
                story_quests // Already conforms to `StoryQuestContainer`
            };

            allStorylines.push(storylineDetails);
        })

        return allStorylines;
    } catch (error) {
        console.error("Error retrieving user's quest storylines: ", error);
        return [];
    }
};

const deleteQuestStoryLineFromDatabase = async (user: ExtendedUser | null, questStoryLineId: string | null): Promise<string | null> => {
    if (!user || !questStoryLineId) return null;

    try {
        const db = getFirestore(); // Assuming 'db' initialization is correct
        // Navigate to the specific QuestStoryLine document
        const userQuestStoryLinesRef = collection(db, "QuestStoryLinesByUsers", user.uid, "UserQuestStoryLines");
        const questStoryLineDocRef = doc(userQuestStoryLinesRef, questStoryLineId);

        // Delete the document
        await deleteDoc(questStoryLineDocRef);

        return questStoryLineId;
    } catch (error) {
        console.error("Error deleting quest storyline from Firestore: ", error);
        return null;
    }
};

const editQuestStoryLineAndSaveToDatabase = async (user: ExtendedUser | null, questStoryLine: StorylineDetails | null): Promise<StorylineDetails | null> => {
    if (!user || !questStoryLine) {
        return null; // Early return for invalid input or insufficient permissions
    }


    try {
        const db = getFirestore(); // Assuming 'db' initialization is correct
        const userQuestStoryLinesRef = collection(db, "QuestStoryLinesByUsers", user.uid, "UserQuestStoryLines");
        const questStoryLineDocRef = doc(userQuestStoryLinesRef, questStoryLine.id);

        // Check if the document exists
        const questStoryLineDocSnapshot = await getDoc(questStoryLineDocRef);
        if (!questStoryLineDocSnapshot.exists()) {
            console.error(`QuestStoryLine with ID ${questStoryLine.id} does not exist for user ${user.uid}`);
            return null;
        }

        // Construct the story quests object similar to the create function
        const storyQuests: { [quest_id: string]: StoryQuestDetails } = {};
        Object.values(questStoryLine.story_quests).forEach(quest => {
            const prerequisite_quests: Record<string, string> = {};
            Object.values(quest.prerequisite_quests).forEach(prereqQuest => {
                prerequisite_quests[prereqQuest] = prereqQuest;
            });

            storyQuests[quest.quest.id] = {
                x: quest.x,
                y: quest.y,
                prerequisite_quests: prerequisite_quests
            };
        });

        // Update the document with new storyline details
        await updateDoc(questStoryLineDocRef, {
            title: questStoryLine.title,
            description: questStoryLine.description,
            type: questStoryLine.type,
            story_quests: storyQuests
        });

        return questStoryLine;
    } catch (error) {
        console.error("Error editing quest storyline data in Firestore: ", error);
        return null;
    }
};


const editQuestAndSaveToDatabase = async (user: ExtendedUser | null, quest: QuestObject | null): Promise<QuestObject | null> => {
    if (!user || !quest || Object.values(quest.objectives).length === 0) {
        return null; // Indicate failure due to invalid input or permissions
    }

    try {
        // Get reference to the specific quest document within the user's QuestsByUsers collection
        const questDocRef = doc(db, "QuestsByUsers", user.uid, "UserQuests", quest.id);

        // Since you're storing objectives and rewards inside the quest document,
        // you can update the entire quest document directly.
        // Firestore will replace the existing document with the provided quest object.
        await setDoc(questDocRef, quest, { merge: true });

        return quest; // Return the updated quest object
    } catch (error) {
        console.error("Error editing quest data in Firestore: ", error);
        return null;
    }
};

// Define the type for the data object in the subcollection
type SubcollectionData = Record<string, any>;

// Define the type for the subcollection reference
type SubcollectionRef = CollectionReference;

const updateSubcollection = async (subcollectionRef: SubcollectionRef, data: SubcollectionData) => {
    // Delete existing documents in the subcollection
    await deleteDocuments(subcollectionRef);

    // Add new documents to the subcollection
    for (const itemId in data) {
        const item = data[itemId];
        await addDoc(subcollectionRef, item);
    }
};

// Helper function to delete existing documents in a subcollection
const deleteDocuments = async (subcollectionRef: SubcollectionRef) => {
    const subcollectionSnapshot = await getDocs(subcollectionRef);
    subcollectionSnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
    });
};

const retrieveQuestsForUserUid = async (user: ExtendedUser | null): Promise<QuestObject[]> => {
    if (user == null) return []; // Return an empty array if user is null

    const userQuestsRef = collection(db, "QuestsByUsers", user.uid, "UserQuests");

    try {
        // Query all quests for the user
        const userQuestsSnapshot = await getDocs(userQuestsRef);

        // Array to store all quests
        const allQuests: QuestObject[] = [];

        // Loop through each quest document
        userQuestsSnapshot.forEach((doc) => {
            // Retrieve quest data from the document
            const questData = doc.data();

            // Construct a QuestObject
            const quest: QuestObject = {
                uid: questData.uid,
                id: doc.id, // Assuming the ID is stored in the document ID
                title: questData.title,
                description: questData.description,
                type: questData.type,
                requirements: questData.requirements,
                objectives: questData.objectives,
                rewards: questData.rewards
            };

            // Add the quest to the array
            allQuests.push(quest);
        });

        // Return the array containing all the quests retrieved from Firestore
        return allQuests;
    } catch (error) {
        console.error("Error retrieving user's quests: ", error);
        return []; // Return an empty array in case of error
    }
}

const convertFirebaseUserToExtendedUser = async (firebaseUser: FireBaseUser | null): Promise<ExtendedUser | null> => {
    if (!firebaseUser) return null;

    // Assuming you store extended user properties in a "users" collection in Firestore
    const userDocRef = doc(db, "users", firebaseUser.uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (!userDocSnapshot.exists()) {
        console.error("User document does not exist in Firestore.");
        return null;
    }

    const userData = userDocSnapshot.data();

    return {
        ...firebaseUser,
        rank: userData.rank,
        display_name: userData.display_name,
        created_at: userData.created_at,
    };
};

// Custom hook to manage Firebase authentication and provide Firebase context
export const useFirebase = (): FirebaseContextType => {
    const [user, setUser] = useState<ExtendedUser | null>(null); // Initialize user state

    // -1 == not signed in, 0 signed in, 1 signing in, 2 signing out
    const [authCheckType, setAuthCheckType] = useState(-1);
    const [authMessage, setAuthMessage] = useState(AUTH_MESSAGE_NOT_SIGNED_IN);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
            const extendedUser = await convertFirebaseUserToExtendedUser(firebaseUser);
            setUser(extendedUser);
        });

        return () => unsubscribe();
    }, []);

    const ContextValue = useMemo(() => ({
        auth,
        db,
        sendPasswordResetEmail,
        signInWithEmailAndPassword,
        createUserWithEmailAndPassword,
        createQuestAndSaveToDatabase,
        retrieveQuestsForUserUid,
        editQuestAndSaveToDatabase,
        retrieveQuestStoryLinesForUserUid,
        createQuestStorylineAndSaveToDatabase,
        editQuestStoryLineAndSaveToDatabase,
        deleteQuestStoryLineFromDatabase,
        deleteQuestFromDatabase,
        setUser,
        user,
        setAuthCheckType,
        authCheckType,
        authMessage,
        setAuthMessage
    }), [user, authCheckType])

    return ContextValue;
};

// Firebase provider component
export const FirebaseProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const firebaseContextValue = useFirebase();

    return (
        <FirebaseContext.Provider value={firebaseContextValue}>
            {children}
        </FirebaseContext.Provider>
    );
};