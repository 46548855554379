import React, { useEffect, useState } from 'react'
import { useAlerith } from '../../../../contexts/Alerith/Alerith'
import MainContainer from '../../../../components/MainContainer'
import styled from 'styled-components'
import { QuestStoryLine } from '../../../../components/quest/interfaces/quest'
import './OfficialStoryLines.css'
import StoryLineContainer from '../../../../components/quest/components/StorylineComponents/QuestStorylineDataContainer'

const OfficialStoryLines: React.FC = () => {
    
    const { storylines } = useAlerith()

    const [selectedStoryline, setSelectedStoryline] = useState<QuestStoryLine>();

    useEffect(() => {
        if (storylines.length > 0) {
            setSelectedStoryline(storylines[0]);
        }
    }, [storylines]);

    const ShortenTypeStoryline = (typeQuest: string) => {
        if (typeQuest === "Main Story")
            return "Main";
        else if (typeQuest === "Side Story") {
            return "Side"
        }
    }

    return (
        <MainContainer title="Official Quest Story Lines List">
            <Container id="container__resize">
                <QuestListViewContainer>
                    <div>
                        <QuestListContainer>
                            <ul>
                            {storylines.map(storyline => (
                                    <li className={selectedStoryline === storyline ? 'selected_quest' : ''}
                                        key={storyline.id}
                                        onClick={() => setSelectedStoryline(storyline)}>
                                        <span>{storyline.title}</span>
                                        <span>{ShortenTypeStoryline(storyline.type)}</span>
                                    </li>
                                ))}
                            </ul>
                        </QuestListContainer>
                    </div>
                </QuestListViewContainer>
                <ContainerQuestData>
                    {selectedStoryline !== undefined ? (
                        <StoryLineContainer canEdit={false} storyline={selectedStoryline} selectedStoryline={selectedStoryline}/>
                    )
                        : (
                            <InformationContainer>
                                <h1>Accessing Quest Information: A Step-by-Step guide</h1>
                                <p>
                                    To access detailed information about a quest—including its title, description, necessary requirements, objectives, and rewards—please begin by selecting a quest from the list located on the left side of the screen. Simply click on the quest you're interested in to view all related details.
                                </p>
                            </InformationContainer>
                        )}


                </ContainerQuestData>
            </Container>
        </MainContainer>
    )
}
const InformationContainer = styled.div`
    h1 {
        color: #c1b197 !important;
        margin: 0;
        margin-bottom: 10px;
        color: blue;
    }

    p {
        color: #8b7e6e !important;
        margin: 0;
    }
`

const QuestListContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    box-shadow: 0 0 20px 10px #00000042 inset;

    ul {
        padding: unset;
        margin: 0;
        padding: 0 8px;
        flex: 1;
    }
    
    li {
        list-style-type: none;  
        background: rgb(17 16 15);
        color: #756a5c;
        padding: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        border: 1px solid #2b2824;
        display: flex;
        justify-content: space-between;
        span {
            display: inline-block;
            font-size: 1.3rem;
        }
    }

    li:last-child {
        margin-bottom: 0; /* Remove margin for the last li element */
    }

    li:hover {
        border: 1px solid #d88e27;
        color: #e7b051;
    }
`

const ContainerQuestData = styled.div`
    flex: 1;
    max-height: inherit;
    overflow-y: auto;
    padding: 8px;
`

const QuestListViewContainer = styled.div`
    border-right: 1px solid #2b2824;
    width: 25%;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    > div{
        overflow-y: auto;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
`

const Container = styled.div`
    display: flex;
    height: 100%;
    max-height: inherit;
        flex: 1;
`
export default OfficialStoryLines