import React from 'react'
import './QuestsOverview.css'
import TextContainer from '../../../../components/TextContainer'
import MainContainer from '../../../../components/MainContainer'

const QuestsOverview: React.FC = () => {
    return (
        <MainContainer title="Quests Overview">
            <div className='container_quests_overview'>
                <TextContainer>
                    <h1>Quest Editor Overview</h1>
                    <p>
                        Embark on a creative journey with the Quest Editor, designed exclusively for Alerith's vibrant community. This powerful toolkit enables you to design, manage, and refine quests with unparalleled ease. Discover the array of features at your fingertips:</p>
                </TextContainer>
                <div className='container_grid'>
                    <TextContainer>
                        <h2>Craft Your Quests</h2>
                        <ul>
                            <li>Seamlessly create and refine your quests, bringing your vision to life.</li>
                            <li>Effortlessly modify quest attributes to perfect your narrative.</li>
                            <li>Access a comprehensive overview of your creations in one convenient location.</li>
                            <li>Utilize our robust deletion feature to keep your workspace organized.</li>
                        </ul>
                    </TextContainer>
                    <TextContainer>
                        <h2>Shape Your Storylines</h2>
                        <ul>
                            <li>Forge immersive storylines with our dynamic quest node visualization tool.</li>
                            <li>Streamline quest adjustments with simple property management.</li>
                            <li>Integrate existing quests into new storylines with just a few clicks.</li>
                        </ul>
                    </TextContainer>
                    <TextContainer>
                        <h2>Optimize Quest Flow</h2>
                        <ul>
                            <li>Refine the structure of your quest chains with intuitive node manipulation.</li>
                            <li>Organize your quests visually through drag-and-drop functionality.</li>
                            <li>Purge unnecessary nodes with ease to maintain clarity in your quest designs.</li>
                            <li>Define the progression of your quests by setting links between nodes, ensuring a seamless adventure.</li>
                        </ul>
                    </TextContainer>
                </div>
            </div>
        </MainContainer>
    )
}

export default QuestsOverview