import React from 'react';
import './Header.css'
import Ornament from '../../assets/window/ornament.png'
import OrnamentEffect from '../../assets/window/ornament_effect.png'

interface HeaderProps {
  header_title: string
  html_id?: string
}

const Header: React.FC<HeaderProps> = ({ header_title, html_id }) => {
  return (
    <div id={html_id && html_id} className="container_header">
      <div>
        <img src={Ornament} className="ornament_window"/>
        <img src={OrnamentEffect} className="ornament_effect_window"/>
      </div>
      <h1>{header_title}</h1>
      <div>
        <img src={Ornament} className="ornament_window"/>
        <img src={OrnamentEffect} className="ornament_effect_window"/>
      </div>
    </div>
  );
};

export default Header;
