import React from 'react'
import styled from 'styled-components'
import Icon from './Icon'

interface SvgIconProps {
  className?: string,
  d: string,
  width: number,
  height: number,
}

const SvgIcon: React.FC<SvgIconProps> = ({ className, d, width = 24, height = 24, }) => {
  return (
    <Svg viewBox="0 0 24 24" className={className} width={width} height={height}>   
      <path fill="currentColor" d={d} /> 
    </Svg>
  )
}

interface SvgProps {
  width: number,
  height: number
}

const Svg = styled(Icon)<SvgProps>`
  width: ${(props) => (props.width)}px;
  height: ${(props) => (props.height)}px;
`

export default SvgIcon
