import React, { useEffect, useState } from 'react'
import { StorylineDetails } from '../../interfaces/quest';
import styled, { keyframes } from 'styled-components';
import QuestStorylineNodeView from './QuestStorylineNodeView/QuestStorylineNodeView';
import SectionQuestStorylineTitle from '../../sections/SectionQuestStorylineTitle';
import SectionQuestStorylineDescription from '../../sections/SectionQuestStorylineDescription';
import Button from '../../../Button';
import { useFirebase } from '../../../../contexts/Firebase/Firebase';
import shortUUID from 'short-uuid';
import SaveQuestStorylineConfirmModal from '../Modals/SaveQuestStorylineConfirmModal';
import useModal from '../../../../hooks/useModal';
import DeleteQuestStorylineConfirmModal from '../Modals/DeleteQuestStorylineConfirmModal';
import { SanitizeUserInputForStorylineObject } from '../../../../utils/quests/sanitizeUserInputStoryline';
import WarningMessage from '../../../WarningMessage';
import { QuestStoryTypeEnum } from '../../enums/QuestStoryType';

interface ViewEditStorylineProps {
  storyline: StorylineDetails;
  setSelectedStoryline: React.Dispatch<React.SetStateAction<StorylineDetails>>;
  canEditStoryline: boolean;
  modificationType: "create" | "edit" | "view" | undefined
  modificationButtonHandler: (type: string, QuestStoryLine: StorylineDetails | undefined) => void;
  setUserStorylines: React.Dispatch<React.SetStateAction<StorylineDetails[]>>
}

const ViewEditStoryline: React.FC<ViewEditStorylineProps> = ({ storyline, setSelectedStoryline, setUserStorylines, canEditStoryline, modificationType, modificationButtonHandler }) => {
  const [onPresentSaveEditQuestStorylineConfirmModal] = useModal(<SaveQuestStorylineConfirmModal  setUserStorylines={setUserStorylines}questStoryline={storyline} modificationType={modificationType} modificationButtonHandler={modificationButtonHandler}/>)
  const [onPresentDeleteQuestStorylineModal] = useModal(<DeleteQuestStorylineConfirmModal  setUserStorylines={setUserStorylines}questStoryline={storyline} modificationButtonHandler={modificationButtonHandler} />)
  const { user } = useFirebase();

  const cancelButtonHandler = () => {
    modificationButtonHandler("unset", undefined)
  }

  const [inputfieldNotice, setInputfieldNotice] = useState({ message: "", targetId: "" });

  const CreateEditButtonHandler = () => {
    const issue = SanitizeUserInputForStorylineObject(storyline);

    // Update the state with the new issue ID and message, or clear it
    if (issue) {
        setInputfieldNotice({ message: issue.message, targetId: issue.id });
    } else  {
      // when there are no issues with the input we open the modal
      setInputfieldNotice({ message: "", targetId: "" });
      if (modificationType === "create" || modificationType === "edit") {
        onPresentSaveEditQuestStorylineConfirmModal();
      } else {
        return;
      }
    }
  }

  useEffect(() => {
    // Check if there is a message to display.
    if (inputfieldNotice.message !== "") {
      // Set a timeout to clear the message after 5 seconds (5000 milliseconds).
      const timer = setTimeout(() => {
        setInputfieldNotice({ message: "", targetId: "" });
      }, 11000);
  
      // Clear the timeout if the component unmounts or if the message changes before the 5 seconds are up.
      // This prevents potential memory leaks.
      return () => clearTimeout(timer);
    }
  }, [inputfieldNotice.message]);

  useEffect(() => {
    if (modificationType === "create" && user) {
      const uniqueUUID = shortUUID.generate();
      const storylineId = `storyline_${user.uid}_${uniqueUUID}`;

      storyline.id = storylineId;
    }
  }, [modificationType]);

  return (
    <Container>
      {canEditStoryline ? (
        <AnimatedForm canEdit={canEditStoryline}>
            {inputfieldNotice.targetId === "quest_node_view_section" && <WarningMessage message={inputfieldNotice.message} />}

          <SectionQuestStorylineTitle type={storyline.type as QuestStoryTypeEnum} questTitle={storyline.title} setSelectedStoryline={setSelectedStoryline} inputfieldNotice={inputfieldNotice}/>
          <SectionQuestStorylineDescription questDescription={storyline.description} setSelectedStoryline={setSelectedStoryline} inputfieldNotice={inputfieldNotice}/>
        </AnimatedForm>
      ) : (
        <AnimatedContent canEdit={canEditStoryline}>
          <div style={{ position: 'absolute', top: '0', right: '0', margin: 'unset' }}>
            <Button onClick={() => modificationButtonHandler("edit", undefined)}>Edit Storyline</Button>
          </div>
          <h1>{storyline.title}</h1>
          <p>{storyline.description}</p>
        </AnimatedContent>
      )}

      <QuestStorylineNodeView
        storyline={storyline}
        setSelectedStoryline={setSelectedStoryline}
        canEdit={canEditStoryline}
        modificationType={modificationType}
      />
      {canEditStoryline && (
        <ButtonContainer>
          {modificationType === "edit" && (
            <NewQuestButtonContainer style={{ marginRight: 'auto' }}>
              <Button type="button" variant="primary" onClick={onPresentDeleteQuestStorylineModal} disabled={false}>Delete Storyline</Button>
            </NewQuestButtonContainer>
          )}

          <NewQuestButtonContainer>
            <Button type="button" variant="primary" onClick={() => cancelButtonHandler()} disabled={false}>Cancel</Button>
          </NewQuestButtonContainer>
          <NewQuestButtonContainer>
            <Button type="button" variant="primary" onClick={CreateEditButtonHandler} disabled={false}>{modificationType === "create" ? "Create storyline" : "Save Changes"}</Button>
          </NewQuestButtonContainer>
        </ButtonContainer>
      )}
    </Container>

  )
}



const NewQuestButtonContainer = styled.div`


    > div {
        padding: 4px;
        background-color: #1b1111;
        border: 1px solid #3d2224;
        
        background-color: #311414;
        border: 1px solid #3d2224;
        box-shadow: 0 0 8px 7px #0000003b inset;

        transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;

        > h3 {
            color: #c1b197 !important;
            font-weight: 700;
            user-select: none;
            margin: 0;
            text-align: center;
            font-size: 1.4rem;
            transition: color 0.2s ease-in-out;
        }

        &:hover {
            cursor:pointer;
            background-color: #3f1616;
            border: 1px solid #6a393d;

            h3 {
                color: #efb754 !important;

            }
        }
    }
`

const ButtonContainer = styled.div`
padding-top: 8px;
border-top: 1px solid #2b2824;
    margin-top: 8px;
display: flex;
gap: 8px;
justify-content: flex-end;
`


const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const AnimatedContent = styled.div<{ canEdit: boolean }>`
position: relative;
  animation: ${fadeOut}  0.3s ease-in-out;

  animation: ${({ canEdit }) => (canEdit ? fadeOut : fadeIn)} 0.3s ease-in-out;
  opacity: ${({ canEdit }) => (canEdit ? 0 : 1)};
  pointer-events: ${({ canEdit }) => (canEdit ? 'none' : 'auto')};
  transition: opacity 0.3s ease-in-out;

  h1 {
    color: #c1b197 !important;
    margin: 0;
    margin-bottom: 10px;
  }

  h2 {
    color: #bd8b42 !important;
    margin: 0;
  }

  h3 {
    color: #9a6922 !important;
  }

  p {
    color: #8b7e6e !important;
    margin: 0;
  }

  span {
    color: #8b7e6e!important;
  }
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: inherit;
    position:relative;
`


const AnimatedForm = styled.form<{ canEdit: boolean }>`
  animation: ${fadeIn} 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
animation: ${({ canEdit }) => (canEdit ? fadeIn : fadeOut)} 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  opacity: ${({ canEdit }) => (canEdit ? 1 : 0)};
  pointer-events: ${({ canEdit }) => (canEdit ? 'auto' : 'none')};
  transition: opacity 0.3s ease-in-out;
  // Other styles...

  input {
    background: #1f1e1d;
    margin-bottom: 0px;
    margin-top: 4px;
    padding: 2px 6px;
    text-align: left;
    font-family: 'Goudy Bookletter 1911',sans-serif;
    letter-spacing: 1px;
    line-height: 1.1;
    font-size: 18px;
    color: #A99C8EFF;
    border: 1px solid #635a4d;
    padding: 2px 6px;
    outline: unset;
    margin-bottom: 10px
  }
  

  textarea {
    padding: 6px;
    flex: 1;
    margin-top: 4px;

  }

  select {
    margin-top: 4px;
}

h1 {
    color: #c1b197 !important;
    margin: 0;
    margin-bottom: 10px;
  }

  h2 {
    color: #bd8b42 !important;
    margin: 0;
  }

  h3 {
    color: #9a6922 !important;
  }

  p {
    color: #8b7e6e !important;
    margin: 0;
  }

  span {
    color: #8b7e6e!important;
  }
`;

export default ViewEditStoryline