export const ProfessionsEnum = {
    ATTACK: 'Attack',
    STRENGTH: 'Strength',
    DEFENCE: 'Defence',
    MAGIC: 'Magic',
    RANGED: 'Ranged',
    MINING: 'Mining',
    WOODCUTTING: 'Woodcutting',
    SKINNING: 'Skinning',
    FISHING: 'Fishing',
    BLACKSMITHING: 'Blacksmithing',
    LEATHERWORKING: 'Leatherworking',
    FIREMAKING: 'Firemaking',
    CRAFTING: 'Crafting',
    COOKING: 'Cooking',
    HERBALISM: 'Herbalism',
    JEWELCRAFTING: 'Jewelcrafting',
    INSCRIPTION: 'Inscription',
    ENCHANTING: 'Enchanting',
    THIEVING: 'Thieving',
    ALCHEMY: 'Alchemy',
    ENGINEERING: 'Engineering',
};