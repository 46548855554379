import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import Header from '../Header'

export interface ModalProps extends React.PropsWithChildren{
  onDismiss?: () => void,
  type?: String,
  title?: string,
  hideScroll?: boolean;
}

const Modal: React.FC<ModalProps> = ({ children, type, title, hideScroll }) => {
  return (
    <StyledResponsiveWrapper>
      {title && <Header header_title={title}/>}
      <StyledModal style={hideScroll ? { overflow: 'hidden' } as CSSProperties : undefined}>{children}</StyledModal>
    </StyledResponsiveWrapper>
  )
}

const StyledResponsiveWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  width: 100%;

  min-width: 380px;
  max-width: 800px;
  max-height: calc(100% - 32px);
  border: 1px solid #463e32;
  background: linear-gradient(0deg, rgb(27 18 17) 0%, rgba(17,16,15,1) 30%, rgba(17,16,15,1) 70%, rgb(27 18 17) 100%);


  /* Media query for desktop computers */
  @media (min-width: 1024px) {
    /*max-width: 650px;*/
    /*min-width: 380px;*/
  }
`

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 0;
  border-top: 1px solid #463e32;
  
  max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar-thumb {
      background: #3e2325;
      box-shadow: 3px 3px 2px 0px #251b1e inset, -1px -1px 5px 0px #080100 inset;
      border: 1.5px solid black;
  }

    &::-webkit-scrollbar {
      background: #151011;
      box-shadow: 10px 0px 5px 0px #1b1111 inset, -2px 0px 6px 0px #2a1313 inset;
      border: 1.5px solid black;
      width: 7px;
  }

    &::-webkit-scrollbar-thumb {
      background-image: initial;
      /* background-color: rgb(41, 30, 31); */
      /* box-shadow: rgb(27, 23, 24) 3px 3px 2px 0px inset, rgb(4, 1, 1) -1px -1px 5px 0px inset; */
      /* border-color: rgb(136, 131, 123); */
      border-left: 1px solid #463e32;
      border-top: 1px solid #463e32;
      border-right: unset;
      border-bottom: 1px solid #463e32;
  }

`


export default Modal
