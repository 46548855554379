import React, {createContext, useContext, useState} from "react"
import {Context as ViewportProviderContext} from '../ViewportProvider'

export const Context = createContext({
    navigationBarToggled: false,
    toggleNavigationBar: () => {}
})

const NavigationBarProvider: React.FC<React.PropsWithChildren> = ({ children}) => {
    const { width } = useContext(ViewportProviderContext)
    const [navigationBarToggled, setNavigationBarToggled] = useState<boolean>((width < 786) ? false : true)

    const toggleNavigation = () => {
        setNavigationBarToggled(navigationBarToggled === true ? false : true)
    }

    return (
        <Context.Provider value={{ navigationBarToggled, toggleNavigationBar: toggleNavigation}}>
            {children}
        </Context.Provider>
    )
}

export default NavigationBarProvider