import React, { ChangeEvent } from 'react';
import { QuestObject } from '../interfaces/quest';
import styled from 'styled-components';
import WarningMessage from '../../WarningMessage';

interface SectionQuestDescriptionProps {
  questDescription: string;
  setQuestObj: React.Dispatch<React.SetStateAction<QuestObject>>;
  inputfieldNotice: { message: string, targetId: string }

}

const SectionQuestDescription: React.FC<SectionQuestDescriptionProps> = ({ questDescription, setQuestObj, inputfieldNotice }) => {
  const handleQuestDescriptionChange = (newDescription: string) => {
    setQuestObj((prevQuestObj) => ({
      ...prevQuestObj,
      description: newDescription,
    }));
  };

  return (
    <SectionContainer id="quest_title_section">
      {inputfieldNotice.targetId === "quest_description" && <WarningMessage message={inputfieldNotice.message} />}

      <h2>Quest Description</h2>
      <textarea
        id="quest_description"
        name="quest_description"
        placeholder="Type the quest description in this text box"
        rows={3}
        value={questDescription}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
          handleQuestDescriptionChange(event.target.value);
        }}
      />
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  textarea {
    min-height: 100px;
  }
`

export default SectionQuestDescription;
