import React from 'react'
import styled from 'styled-components';
import InputfieldItem from '../../InputfieldItem';
import CloseButton from '../../CloseButton';
import 
{
    QuestObject,
    QuestProperty,
    QuestRequirementPredecessor,
    QuestRequirementProfessionLevel,
    QuestObjectiveCollectItem,
    QuestObjectiveEscortNpc,
    QuestObjectiveSlayCreature,
    QuestObjectiveTalkToNpc,
    QuestRewardCurrency,
    QuestRewardExperience,
    QuestRewardItem
} from '../interfaces/quest'
import { ProfessionsEnum } from '../enums/ProfessionsEnum';
import SelectOption from '../../SelectOption';
import QuestObjectiveEnum from '../enums/QuestObjectiveEnum';
import { QuestRewardsEnum } from '../enums/QuestRewardsEnum';
import QuestRequirementsEnum from '../enums/QuestRequirements';
import { COLLECT_ITEM_NAME_LENGTH_MAX, COLLECT_ITEM_QUANTITY_MAX, COLLECT_ITEM_QUANTITY_MIN, ESCORT_NPC_NAME_LENGTH_MAX, QUEST_REQUIRED_PROFESSION_LEVEL_MAX, QUEST_REQUIRED_PROFESSION_LEVEL_MIN, REWARD_CURRENCY_COPPER_MAX, REWARD_CURRENCY_COPPER_MIN, REWARD_CURRENCY_GOLD_MAX, REWARD_CURRENCY_GOLD_MIN, REWARD_CURRENCY_SILVER_MAX, REWARD_CURRENCY_SILVER_MIN, REWARD_ITEM_NAME_LENGTH_MAX, REWARD_ITEM_QUANTITY_MAX, REWARD_ITEM_QUANTITY_MIN, REWARD_PROFESSION_EXPERIENCE_MAX, REWARD_PROFESSION_EXPERIENCE_MIN, SLAY_CREATURE_NAME_LENGTH_MAX, SLAY_CREATURE_QUANTITY_MAX, SLAY_CREATURE_QUANTITY_MIN, TALK_WITH_NPC_NAME_LENGTH_MAX } from '../../../constants/quest/InputfieldQuestRules';

interface QuestPropertyProps {
    QuestProperty: QuestProperty
    setQuestObj: React.Dispatch<React.SetStateAction<QuestObject>>;
    deleteQuestProperty: (id: number) => void;
}

const QuestPropertyItem: React.FC<QuestPropertyProps> = ( { QuestProperty, setQuestObj, deleteQuestProperty  } ) => {
    const isQuestRewardCurrency = ('copper' in QuestProperty) && ('silver' in QuestProperty) && ('gold' in QuestProperty);

    const getUpdatedQuestProperty = (
        questProperty: QuestProperty,
        newValue: Partial<
            QuestRequirementProfessionLevel
            | QuestRequirementPredecessor
            | QuestRewardCurrency
            | QuestRewardItem
            | QuestRewardExperience
            | QuestObjectiveSlayCreature
            | QuestObjectiveEscortNpc
            | QuestObjectiveTalkToNpc
            | QuestObjectiveCollectItem
        >
    ): QuestProperty => {
        return { ...questProperty, ...(newValue as Partial<QuestProperty>) };
    };

    const handleChange = (type: string, key: keyof QuestProperty, value: string | number) => {
        setQuestObj((prevQuestObj) => {
            const updatedQuestObj = { ...prevQuestObj };
            const updatedProperty: Partial<QuestProperty> = { [key]: value };

            // Check if the property is an objective,reward or requirement
            if (prevQuestObj.objectives[QuestProperty.id] && type == "objective") {
                updatedQuestObj.objectives[QuestProperty.id] = getUpdatedQuestProperty(
                    prevQuestObj.objectives[QuestProperty.id],
                    updatedProperty
                );
            } else if (prevQuestObj.rewards[QuestProperty.id] && type == "reward") {
                updatedQuestObj.rewards[QuestProperty.id] = getUpdatedQuestProperty(
                    prevQuestObj.rewards[QuestProperty.id],
                    updatedProperty
                );
            } else if (prevQuestObj.requirements[QuestProperty.id] && type == "requirement") {
                updatedQuestObj.requirements[QuestProperty.id] = getUpdatedQuestProperty(
                    prevQuestObj.requirements[QuestProperty.id],
                    updatedProperty
                );
            }

            return updatedQuestObj;
        });
    };
    

    const renderInputs = () => {
        switch (QuestProperty.type) {
            case QuestRequirementsEnum.LEVEL_REQUIREMENT:
                return (
                    <ContainerSelectAndInputfield>
                        <label style={{color: '#9a9081', fontFamily: 'unset'}}>Profession</label>
                        <SelectOption
                            htmlId="quest_req_lvl_select"
                            htmlName="quest_req_lvl_select"
                            options={Object.values(ProfessionsEnum)}
                            selected={(QuestProperty as QuestRequirementProfessionLevel).profession_name}
                            onSelect={(value) => handleChange("requirement" ,'profession_name' as keyof QuestProperty, value)}
                        />
                        <InputfieldItem
                            htmlId="input_quest_req_lvl"
                            inputType="number"
                            inputPlaceholder="0"
                            labelValue="Required Level"
                            inputValue={(QuestProperty as QuestRequirementProfessionLevel).profession_level.toString()}
                            onChange={(value) => handleChange("requirement", 'profession_level' as keyof QuestProperty, parseInt(value, 10))}
                            minNumber={QUEST_REQUIRED_PROFESSION_LEVEL_MIN}
                            maxNumber={QUEST_REQUIRED_PROFESSION_LEVEL_MAX}
                            allowDecimals={false}
                        />
                    </ContainerSelectAndInputfield>
                );
                {/*case QuestRequirementsEnum.QUEST_PREDECESSOR:
                    return (
                        <ContainerOneInputfield>
                            <InputfieldItem
                                htmlId="input_quest_predecessor"
                                inputType="text"
                                inputPlaceholder="Quest name"
                                labelValue="Quest name"
                                inputValue={(QuestProperty as QuestRequirementPredecessor).quest_name.toString()}
                                onChange={(value) => handleChange("requirement", 'quest_name' as keyof QuestProperty, value)}
                            />
                        </ContainerOneInputfield>
                    );*/}
        case QuestRewardsEnum.ITEM_REWARD:
            return (
                <ContainerOneTextOneQuantityInputfield>
                    <InputfieldItem
                        htmlId="input_quest_reward_item_name"
                        inputType="text"
                        inputPlaceholder="Item Name"
                        labelValue="Item Name"
                        inputValue={(QuestProperty as QuestRewardItem).item_name}
                        onChange={(value) => handleChange("reward", 'item_name' as keyof QuestProperty, value)}
                        maxLength={REWARD_ITEM_NAME_LENGTH_MAX}
                    />
                    <InputfieldItem
                        htmlId="input_quest_obj_collect_item_name_quantity"
                        inputType="number"
                        inputPlaceholder="0"
                        labelValue="Quantity"
                        inputValue={(QuestProperty as QuestRewardItem).item_quantity.toString()}
                        onChange={(value) => handleChange("reward", 'item_quantity' as keyof QuestProperty, parseInt(value, 10))}
                        minNumber={REWARD_ITEM_QUANTITY_MIN}
                        maxNumber={REWARD_ITEM_QUANTITY_MAX}
                        allowDecimals={false}
                    />
                </ContainerOneTextOneQuantityInputfield>
            );
            case QuestRewardsEnum.CURRENCY_REWARD:
                return (
                    <ContainerThreeInputfields>
                        <InputfieldItem
                            htmlId="input_quest_reward_copper"
                            inputType="number"
                            inputPlaceholder="0"
                            labelValue="Copper"
                            inputValue={(QuestProperty as QuestRewardCurrency).copper.toString()}
                            onChange={(value) => handleChange("reward", 'copper' as keyof QuestProperty, parseInt(value, 10))}
                            minNumber={REWARD_CURRENCY_COPPER_MIN}
                            maxNumber={REWARD_CURRENCY_COPPER_MAX}
                            allowDecimals={false}
                        />
                        <InputfieldItem
                            htmlId="input_quest_reward_silver"
                            inputType="number"
                            inputPlaceholder="0"
                            labelValue="Silver"
                            inputValue={(QuestProperty as QuestRewardCurrency).silver.toString()}
                            onChange={(value) => handleChange("reward", 'silver' as keyof QuestProperty, parseInt(value, 10))}
                            minNumber={REWARD_CURRENCY_SILVER_MIN}
                            maxNumber={REWARD_CURRENCY_SILVER_MAX}
                            allowDecimals={false}
                        />
                        <InputfieldItem
                            htmlId="input_quest_reward_gold"
                            inputType="number"
                            inputPlaceholder="0"
                            labelValue="Gold"
                            inputValue={(QuestProperty as QuestRewardCurrency).gold.toString()}
                            onChange={(value) => handleChange("reward", 'gold' as keyof QuestProperty, parseInt(value, 10))}
                            minNumber={REWARD_CURRENCY_GOLD_MIN}
                            maxNumber={REWARD_CURRENCY_GOLD_MAX}
                            allowDecimals={false}
                        />
                    </ContainerThreeInputfields>
                );
            case QuestRewardsEnum.XP_REWARD:
                return (
                    <ContainerSelectAndInputfield>
                        <span>Profession</span>
                        <SelectOption
                            htmlId="quest_reward_prof_select"
                            htmlName="quest_reward_prof_select"
                            options={Object.values(ProfessionsEnum)}
                            selected={(QuestProperty as QuestRewardExperience).profession_name}
                            onSelect={(value) => handleChange("reward", 'profession_name' as keyof QuestProperty, value)}
                        />
                        <InputfieldItem
                            htmlId="input_quest_reward_prof_exp"
                            inputType="number"
                            inputPlaceholder="0"
                            labelValue="Experience"
                            inputValue={(QuestProperty as QuestRewardExperience).profession_experience.toString()}
                            onChange={(value) => handleChange("reward", 'profession_experience' as keyof QuestProperty, parseInt(value, 10))}
                            minNumber={REWARD_PROFESSION_EXPERIENCE_MIN}
                            maxNumber={REWARD_PROFESSION_EXPERIENCE_MAX}
                            allowDecimals={false}
                        />
                    </ContainerSelectAndInputfield>
                );
            case QuestObjectiveEnum.SLAY_CREATURE:
                return (
                    <ContainerOneTextOneQuantityInputfield>
                        <InputfieldItem
                            htmlId="input_quest_obj_slay_creature"
                            inputType="text"
                            inputPlaceholder="Creature name"
                            labelValue="Creature name"
                            inputValue={(QuestProperty as QuestObjectiveSlayCreature).npc_name}
                            onChange={(value) => handleChange("objective", 'npc_name' as keyof QuestProperty, value)}
                            maxLength={SLAY_CREATURE_NAME_LENGTH_MAX}
                        />
                        <InputfieldItem
                            htmlId="input_quest_obj_slay_creature_kc"
                            inputType="number"
                            inputPlaceholder="0"
                            labelValue="Quantity"
                            inputValue={(QuestProperty as QuestObjectiveSlayCreature).npc_kill_count.toString()}
                            onChange={(value) => handleChange("objective", 'npc_kill_count' as keyof QuestProperty, parseInt(value, 10))}
                            minNumber={SLAY_CREATURE_QUANTITY_MIN}
                            maxNumber={SLAY_CREATURE_QUANTITY_MAX}
                            allowDecimals={false}
                        />
                    </ContainerOneTextOneQuantityInputfield>
                );
            case QuestObjectiveEnum.COLLECT_ITEM:
                return (
                    <ContainerOneTextOneQuantityInputfield>
                        <InputfieldItem
                            htmlId="input_quest_obj_collect_item_name"
                            inputType="text"
                            inputPlaceholder="Item Name"
                            labelValue="Item Name"
                            inputValue={(QuestProperty as QuestObjectiveCollectItem).item_name}
                            onChange={(value) => handleChange("objective", 'item_name' as keyof QuestProperty, value)}
                            maxLength={COLLECT_ITEM_NAME_LENGTH_MAX}
                        />
                        <InputfieldItem
                            htmlId="input_quest_obj_collect_item_name_quantity"
                            inputType="number"
                            inputPlaceholder="0"
                            labelValue="Quantity"
                            inputValue={(QuestProperty as QuestObjectiveCollectItem).item_quantity.toString()}
                            onChange={(value) => handleChange("objective", 'item_quantity' as keyof QuestProperty, parseInt(value, 10))}
                            minNumber={COLLECT_ITEM_QUANTITY_MIN}
                            maxNumber={COLLECT_ITEM_QUANTITY_MAX}
                            allowDecimals={false}
                        />
                    </ContainerOneTextOneQuantityInputfield>
                );
            case QuestObjectiveEnum.ESCORT_NPC:
                return (
                    <ContainerOneInputfield>
                        <InputfieldItem
                            htmlId="input_quest_obj_escort_to_npc_name"
                            inputType="text"
                            inputPlaceholder="NPC Name"
                            labelValue="NPC Name"
                            inputValue={(QuestProperty as QuestObjectiveEscortNpc).npc_name}
                            onChange={(value) => handleChange("objective", 'npc_name' as keyof QuestProperty, value)}
                            maxLength={ESCORT_NPC_NAME_LENGTH_MAX}

                        />
                    </ContainerOneInputfield>
                );
            case QuestObjectiveEnum.TALK_WITH_NPC:
                return (
                    <ContainerOneInputfield>
                        <InputfieldItem
                            htmlId="input_quest_obj_talk_to_npc_name"
                            inputType="text"
                            inputPlaceholder="NPC Name"
                            labelValue="NPC Name"
                            inputValue={(QuestProperty as QuestObjectiveTalkToNpc).npc_name}
                            onChange={(value) => handleChange("objective", 'npc_name' as keyof QuestProperty, value)}
                            maxLength={TALK_WITH_NPC_NAME_LENGTH_MAX}
                        />
                    </ContainerOneInputfield>
                );
            default:
                return null;
        }
    };

    return (
        <Container>
            {!isQuestRewardCurrency && (
            <div>
                <span>{QuestProperty.type_count + 1}.</span>
            </div>
            )}
            {renderInputs()}
            <CloseButton onClick={() => deleteQuestProperty(QuestProperty.id)} style={{marginLeft: 'auto', flex: '0 0 auto'}}/>
        </Container>
    )
}

export const Container = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;

    input {
        display: inline-block !important;
    }

    label {
        display: inline-block !important;
    }

    div:nth-child(2) {


    input {
        flex: auto;
        width: auto;
        }
    }

    div:nth-child(3) {
        display: flex;

        input {
            width: 50px;
        }
    }
`;

const ContainerSelectAndInputfield = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;

    > div {
        flex: 1;
        display: flex;
        gap: 8px;
    }
`

const ContainerOneTextOneQuantityInputfield = styled.div`
    display: flex;
    flex: 1;
    gap: 8px; 

    > div {
        display: flex;
        gap: 8px;
    }

    > div:nth-child(1) {
        flex: 1 1 auto;
    }

    > div:nth-child(2) {
        min-width: 200px;
        flex: 0 1 auto;
    }
`

const ContainerOneInputfield = styled.div`
    display: flex;
    flex: 1;

    > div {
        display: flex;
        flex: 1;
        gap: 8px;
    }
`

const ContainerThreeInputfields = styled.div`
    display: flex;
    width: 100%;
    gap: 8px;

    > div {
        flex: 1;
        display: flex;
        
        gap: 8px;

        > input {
            flex: 1;
        }
    }
`

export default QuestPropertyItem;