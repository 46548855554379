import React, { createContext, useEffect, useState, ReactNode, useCallback, useMemo } from 'react';
import { QuestObject, QuestStoryLine, StorylineDetails } from '../../components/quest/interfaces/quest';
import { generateRandomQuestStoryLines, generateRandomQuestsByQuantity } from '../../utils/RandomQuests/RandomQuestData';
import { useFirebase } from '../Firebase/Firebase';
import { getQuestsFromLocalStorage, getStorylinesFromLocalStorage, hasInitialQuestStateLoaded, hasInitialStorylineStateLoaded, saveInitialLoadQuestStateToLocalStorage, saveInitialLoadStorylineStateToLocalStorage, saveQuestToLocal, saveQuestsToLocalStorage, saveStorylinesToLocalStorage } from '../../utils/localstorageManager';

interface AlerithContextType {
    quests: QuestObject[];
    userPersonalQuests: QuestObject[];
    userPersonalStorylines: StorylineDetails[];
    storylines: QuestStoryLine[];
    saveQuestAndUpdateState: (quest: QuestObject) => void;
}

export const AlerithContext = createContext<AlerithContextType | null>(null);

export const useAlerith = (): AlerithContextType => {
    const [officialQuests, setOfficialQuests] = useState<QuestObject[]>([]);
    const [officialStorylines, setOfficialStorylines] = useState<QuestStoryLine[]>([])
    const [userPersonalQuests, setUserPersonalQuests] = useState<QuestObject[]>(getQuestsFromLocalStorage());
    const [userPersonalStorylinesRaw, setPersonalStorylinesRaw] = useState<StorylineDetails[]>([]);
    const [userPersonalStorylines, setPersonalStorylines] = useState<StorylineDetails[]>([]);
    const { user, retrieveQuestsForUserUid,retrieveQuestStoryLinesForUserUid } = useFirebase();

    useEffect(() => {
        const fetchAndSetQuests = async () => {
            if (user && !hasInitialStorylineStateLoaded()) {
                try {
                    const firebaseQuests = await retrieveQuestStoryLinesForUserUid(user);
                    if (firebaseQuests && firebaseQuests.length > 0) {
                        setPersonalStorylinesRaw(firebaseQuests);
                        saveStorylinesToLocalStorage(firebaseQuests);
                        saveInitialLoadStorylineStateToLocalStorage();
                    } else {
                        setPersonalStorylinesRaw([]);
                    }
                } catch (error) {
                    console.error("Error retrieving user's quests: ", error);
                }
            } else if (!user && !hasInitialStorylineStateLoaded()){
                // Handle scenario when there is no user logged in
                setPersonalStorylinesRaw([]);
            } else if (user && hasInitialStorylineStateLoaded()) {
                setPersonalStorylinesRaw(getStorylinesFromLocalStorage());
            }
        };
        
        fetchAndSetQuests();
    }, [user, retrieveQuestStoryLinesForUserUid]);

    useEffect(() => {
        const fetchAndSetQuests = async () => {
            if (user && !hasInitialQuestStateLoaded()) {
                try {
                    const firebaseQuests = await retrieveQuestsForUserUid(user);
                    if (firebaseQuests && firebaseQuests.length > 0) {
                        setUserPersonalQuests(firebaseQuests);
                        saveQuestsToLocalStorage(firebaseQuests);
                        saveInitialLoadQuestStateToLocalStorage();
                    } else {
                        // Assuming you want to clear the quests if none are found
                        setUserPersonalQuests([]);
                    }
                } catch (error) {
                    console.error("Error retrieving user's quests: ", error);
                }
            } else if (!user && !hasInitialQuestStateLoaded()){
                setUserPersonalQuests([]);
            } else if (user && hasInitialQuestStateLoaded()){
                setUserPersonalQuests(getQuestsFromLocalStorage());
            }
        };

        fetchAndSetQuests();
    }, [user, retrieveQuestsForUserUid]);

    const saveQuestAndUpdateState = useCallback((quest: QuestObject) => {
        // Save the quest to local storage
        saveQuestToLocal(quest);

        // Update the quest in the local state
        setUserPersonalQuests(prevQuests => {
            const index = prevQuests.findIndex(q => q.id === quest.id);
            if (index !== -1) {
                // Update existing quest
                const updatedQuests = [...prevQuests];
                updatedQuests[index] = quest;
                return updatedQuests;
            } else {
                // Add new quest
                return [...prevQuests, quest];
            }

        });
    }, [userPersonalQuests, setUserPersonalQuests]);

    useEffect(() => {
        const transformStorylines = (rawStorylines: StorylineDetails[]) => {
            return rawStorylines.map(storyline => {
                const storyQuests = { ...storyline.story_quests }; // Assuming story_quests is an object
    
                Object.entries(storyQuests).forEach(([questId, questDetails]) => {
                    const questFound = userPersonalQuests.find(quest => quest.id === questId);
                    if (questFound) {
                        storyQuests[questId] = {
                            ...questDetails,
                            quest: questFound
                        };
                    }
                });
    
                return {
                    ...storyline,
                    story_quests: storyQuests,
                };
            });
        };
        // Transform the data
        const transformedStorylines = transformStorylines(userPersonalStorylinesRaw);
    
        // Update state conditionally
        if (JSON.stringify(transformedStorylines) !== JSON.stringify(userPersonalStorylines)) {
            setPersonalStorylines(transformedStorylines); // Assuming this is the correct state variable to update
        }
    }, [userPersonalStorylinesRaw, userPersonalQuests]);

    useEffect(() => {
        const generatedQuestData = generateRandomQuestsByQuantity(50); // Generate random quests
        setOfficialQuests(generatedQuestData); // Set quests state

        const generatedStorylines = generateRandomQuestStoryLines(20, 10);
        setOfficialStorylines(generatedStorylines); // Set storylines state

    }, []); // Empty dependency array means this effect runs only once


    const contextValue = useMemo(() => ({
        quests: officialQuests,
        storylines: officialStorylines,
        userPersonalQuests: userPersonalQuests,
        userPersonalStorylines: userPersonalStorylines,
        saveQuestAndUpdateState: saveQuestAndUpdateState,
    }), [officialQuests, officialStorylines, userPersonalQuests, userPersonalStorylines, saveQuestAndUpdateState]);

    return contextValue;
};

export const AlerithProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const alerithContextValue = useAlerith();

    return (
        <AlerithContext.Provider value={alerithContextValue}>
            {children}
        </AlerithContext.Provider>
    );
};