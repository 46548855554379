import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from "styled-components";
import ReactFlow, { useNodesState, useEdgesState, addEdge, MiniMap, Controls, Connection, Edge, Node, useReactFlow, getOutgoers } from 'reactflow';
import 'reactflow/dist/style.css';
import QuestNodeObject from './../QuestNodeObject'
import '../ReactflowCustom.css'
import { QuestObject, QuestObjectStoryLine, StorylineDetails } from '../../../interfaces/quest';
import Button from '../../../../Button';
import AddQuestToStorylineModal from '../../Modals/Storyline/AddQuestToStorylineModal';
import useModal from '../../../../../hooks/useModal';
import { StoryQuestContainer } from '../../../interfaces/quest/firebase/StoryQuestContainer';

const connectionLineStyle = { stroke: '#fff' };
const snapGrid: [number, number] = [20, 20];
const nodeTypes = {
    customNode: QuestNodeObject,
};
const defaultViewport = { x: 0, y: 0, zoom: 0 };//1.5
const initBgColor = '#1A192B';

interface ReactflowContainerProps {
    storyline: StorylineDetails;
    setSelectedStoryline: React.Dispatch<React.SetStateAction<StorylineDetails>>;
    canEdit: boolean;
    onClickHandler?: () => void;
    setModificationType?: any;
    modificationType: "create" | "edit" | "view" | undefined
}
const StoryLineContainer: React.FC<ReactflowContainerProps> = ({ storyline, modificationType, setSelectedStoryline, canEdit, onClickHandler }) => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [bgColor, setBgColor] = useState(initBgColor);
    const { getNodes, getEdges } = useReactFlow();
    const [questObjectFromModal, setQuestObjectFromModal] = useState<QuestObjectStoryLine | null>(null)
    const [nextQuestId, setNextQuestId] = useState(0)

    const prevStoryline = usePrevious(storyline);


    function usePrevious<T>(value: T): T | undefined {
        const ref = useRef<T | undefined>();
        useEffect(() => {
          ref.current = value;
        }, [value]);
        return ref.current;
    }

    useEffect(() => {
        if (questObjectFromModal !== null) {
            onAddNewQuestFromModal(questObjectFromModal);
            // Reset to null after adding
            setQuestObjectFromModal(null);
        }

    }, [questObjectFromModal]);

    useEffect(() => {
        // Empty nodes and edges for a new storyline or when switching between storylines
        if (modificationType === "create" && (!prevStoryline || storyline.id !== prevStoryline.id)) {
            setNodes([]);
            setEdges([]);
            return; // Exit early if creating a new storyline or if no storyline is selected
        }
    
        if (storyline && storyline.story_quests && (modificationType === "edit" || modificationType === "view") && (!prevStoryline || storyline.id !== prevStoryline.id)) {
            // Convert storyline quests into React Flow nodes for an existing storyline
            const storylineNodes = Object.entries(storyline.story_quests).map(([questId, quest], index) => ({
                id: questId, // Simplified ID generation based on index
                type: 'customNode', // Assuming you have a custom node type like 'QuestNodeObject'
                data: { quest: { ...quest.quest }, prerequisite_quests: quest.prerequisite_quests}, // Pass the entire quest as data for the node
                position: { x: quest.x, y: quest.y }, // Use the quest's x and y for positioning
            }));
    
             let newEdges: Edge<any>[] = [];

             const findObject = (prerequirisiteId : string) => {
                 for (let index = 0; index < storylineNodes.length; index++) {
                     const element = storylineNodes[index];
    
                    if (element.data.quest.id === prerequirisiteId)
                        return element.id;
                }
    
                return null;
            }
    
            storylineNodes.forEach((node) => {
                const prerequisites = node.data.prerequisite_quests || [];
    

                Object.values(prerequisites).forEach((prerequisite: string) => {
                    // Correctly accessing the id property of the QuestObjectStoryLine object
                    const prerequisiteId = prerequisite; // Adjust this line if the structure is different
                    const targetNode = findObject(prerequisiteId);
                    if (!targetNode) return; // Skip if no target node is found
            
                    const edgeId = `e${node.data.quest.id}-${prerequisiteId}`;
                    const newEdge = {
                        id: edgeId,
                        source: targetNode,
                        target: node.id,
                        animated: false,
                        style: { stroke: '#fff' }
                    };
            
                    newEdges.push(newEdge);
                });
            });
    
            // Update the nodes and edges state with the new nodes and edges for the storyline quests
            setNodes(storylineNodes);
            setEdges(newEdges);

        }
        // Adding a dependency on `storyline.quests` might lead to unnecessary re-renders
        // if the quests object is recreated on each render. Consider memoizing it or
        // restructuring dependencies if this becomes a problem.
    },  [modificationType, storyline]);



const onAddNewQuestFromModal = useCallback((questStorylineObjectFromModal: QuestObject) => {
        const posX = nodes.length > 0 ? nodes[0].position.x : 0;
        const posY = nodes.length > 0 ? nodes[0].position.y + 300 : 0;

        // Assuming getNodeId() generates an ID that can be used for QuestObjectStoryLine as well
        const generatedId = questStorylineObjectFromModal.id; // or some other method to generate a numeric ID

        const newNode = {
            id: generatedId,
            type: 'customNode',
            data: { quest: { ...questStorylineObjectFromModal, x: posX, y: posY } },
            position: { x: posX, y: posY }
        };

        setNodes((nds) => nds.concat(newNode));

        setSelectedStoryline((currentStoryline) => {
            if (!currentStoryline) {
                // Handle case where there is no selected storyline (optional based on your logic)
                return currentStoryline;
            }

            // Update the quests within the selected storyline
            const updatedQuests: StoryQuestContainer = {
                ...currentStoryline.story_quests,
                [generatedId]: { 
                    quest: questStorylineObjectFromModal,
                    x: posX, 
                    y: posY, 
                    prerequisite_quests: {} as Record<string, string> // Explicitly type as Record<string, string>
                }
            };
       
            return {
                ...currentStoryline,
                story_quests: updatedQuests
            };
        });
    }, [nodes, setNodes, setSelectedStoryline]);
    useEffect(() => {
        // Assuming nextQuestId starts from a certain number and increments with each addition
        // This value should ideally be managed globally or fetched to ensure uniqueness
        setNextQuestId((prevId) => prevId + 1);
    }, [onAddNewQuestFromModal]); // This effect runs every time a new quest is added
    
    const [onPresentAddQuestModal] = useModal(<AddQuestToStorylineModal setQuestStorylineObjectFromModal={setQuestObjectFromModal} storyline={storyline}/>)

    useEffect(() => {
        const onChange = (event: { target: { value: any; }; }) => {
            setNodes((nds) =>
                nds.map((node) => {
                    if (node.id !== '2') {
                        return node;
                    }

                    const color = event.target.value;

                    setBgColor(color);

                    return {
                        ...node,
                        data: {
                            ...node.data,
                            color,
                        },
                    };
                })
            );
        };

    }, []);

    const isValidConnection = useCallback(
        (connection: any) => {
            // we are using getNodes and getEdges helpers here
            // to make sure we create isValidConnection function only once
            const nodes = getNodes();
            const edges = getEdges();
            const target = nodes.find((node) => node.id === connection.target);
            const hasCycle = (node: any, visited = new Set<string>()) => {
                if (visited.has(node.id)) return false;

                visited.add(node.id);

                for (const outgoer of getOutgoers(node, nodes, edges)) {
                    if (outgoer.id === connection.source) return true;
                    if (hasCycle(outgoer, visited)) return true;
                }
            };

            if (target === undefined) return false; // extra fail check else it doesnt work
            if (target.id === connection.source) return false;
            return !hasCycle(target);
        },
        [getNodes, getEdges],
    );

    useEffect(() => {
        nodes.forEach((node) => {
          // Assuming each node's ID matches a quest ID in your storyline
          const questId = node.id;
          // Update the selected storyline with the new positions
          setSelectedStoryline(currentStoryline => {
            if (!currentStoryline || !currentStoryline.story_quests[questId]) {
              return currentStoryline;
            }
      
            const updatedQuests = {
              ...currentStoryline.story_quests,
              [questId]: {
                ...currentStoryline.story_quests[questId],
                x: node.position.x,
                y: node.position.y,
              },
            };
      
            return {
              ...currentStoryline,
              story_quests: updatedQuests,
            };
          });
        });
      }, [nodes, setSelectedStoryline]);

    const onConnect = useCallback((params: Edge | Connection) => {
        setEdges((els) => addEdge(params, els));
        // Update the quest nodes based on the connection
        const sourceNode = nodes.find(node => node.id === params.source);
        const targetNode = nodes.find(node => node.id === params.target);
        if (sourceNode && targetNode) {
            // Assuming the quest data is stored in the 'data' property of the node
            const sourceQuest = sourceNode.data.quest;
            const targetQuest = targetNode.data.quest;
            // Update the prerequisite_quests of the target quest
            const updatedPrerequisites = {
                ...targetQuest.prerequisite_quests,
                [sourceNode.id]: sourceQuest.id
            };
            // Now, update the target node with the new prerequisites
            const updatedTargetNode = {
                ...targetNode,
                data: {
                    ...targetNode.data,
                    quest: {
                        ...targetQuest,
                        prerequisite_quests: updatedPrerequisites
                    }
                }
            };


            // Update the nodes state with the updated target node
            setNodes((currentNodes) => currentNodes.map(node => node.id === targetNode.id ? updatedTargetNode : node));

            // Now, update the global QuestStoryLine state
            setSelectedStoryline((currentStoryline) => {
                if (!currentStoryline) return currentStoryline;

                // Assuming quests within currentStoryline are stored similarly to nodes,
                // and we have a unique identifier to access them (e.g., uid)
                const updatedQuests = {
                    ...currentStoryline.story_quests,
                    [targetNode.id]: {
                        ...currentStoryline.story_quests[targetNode.id],
                        prerequisite_quests: updatedPrerequisites,
                    },
                };

                return {
                    ...currentStoryline,
                    story_quests: updatedQuests,
                };
            });
        }
    }, [nodes, setEdges, setNodes, setSelectedStoryline]);

    const onNodesDelete = useCallback((nodesToDelete: Node[]) => {
            // Filter out deleted nodes from the current nodes state
    setNodes((currentNodes) => currentNodes.filter((node) => !nodesToDelete.find((n) => n.id === node.id)));

    // Update the selected storyline state to reflect the deletion of quests
    // and the necessary updates to the prerequisite_quests of the remaining quests
    setSelectedStoryline((currentStoryline) => {
        if (!currentStoryline) return currentStoryline;

        const updatedStoryline = { ...currentStoryline };
        const deletedNodeIds = nodesToDelete.map(node => node.id);

        // Remove the quests corresponding to the deleted nodes
        deletedNodeIds.forEach((nodeId) => {
            delete updatedStoryline.story_quests[nodeId];
        });

        // Iterate over the remaining quests in the storyline to update their prerequisite_quests
        Object.keys(updatedStoryline.story_quests).forEach((questId) => {
            const quest = updatedStoryline.story_quests[questId];

            // Filter out the IDs of the deleted quests from prerequisite_quests
            const updatedPrerequisites = Object.keys(quest.prerequisite_quests)
                .filter(prerequisiteId => !deletedNodeIds.includes(prerequisiteId))
                .reduce((acc, cur) => {
                    // Reconstruct the prerequisite_quests object without the deleted quests
                    acc[cur] = quest.prerequisite_quests[cur];
                    return acc;
                }, {} as Record<string, string>);

            // Update the current quest's prerequisite_quests to the filtered list
            quest.prerequisite_quests = updatedPrerequisites;
        });

        return updatedStoryline;
    });
    }, [setNodes, setSelectedStoryline]);

    // Callback for when edges are deleted
    const onEdgesDelete = useCallback((edgesToDelete: Edge[]) => {
        setEdges((currentEdges) => currentEdges.filter((edge) => !edgesToDelete.find((e) => e.id === edge.id)));
    
        // Update the selected storyline to remove the deleted prerequisites
        setSelectedStoryline((currentStoryline) => {
            if (!currentStoryline) return currentStoryline;
    
            // Create a copy of the current storyline to modify
            const updatedStoryline = { ...currentStoryline };
    
            edgesToDelete.forEach((edge) => {
                // Assuming edge.source is the ID of the quest that was a prerequisite
                // And edge.target is the ID of the quest that had the prerequisite
                const targetQuestId = edge.target;
                const sourceQuestId = edge.source;
    
                // Check if the target quest exists in the storyline
                if (updatedStoryline.story_quests[targetQuestId]) {
                    // Remove the source quest ID from the target quest's prerequisite_quests
                    delete updatedStoryline.story_quests[targetQuestId].prerequisite_quests[sourceQuestId];
                }
            });
    
            return updatedStoryline;
        });
    
    }, [setEdges, setSelectedStoryline]);
    
    return (
        <Container>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onNodesDelete={onNodesDelete}
                onEdgesDelete={onEdgesDelete}
                onConnect={onConnect}
                style={{ background: bgColor }}
                nodeTypes={nodeTypes}
                connectionLineStyle={connectionLineStyle}
                isValidConnection={isValidConnection}
                snapToGrid={true}
                snapGrid={snapGrid}
                defaultViewport={defaultViewport}
                deleteKeyCode={canEdit ? "Delete" : null}
                fitView
                attributionPosition="bottom-left"
                className='react_flow_nodes_custom'
                edgesUpdatable={canEdit}
                edgesFocusable={canEdit}
                nodesDraggable={canEdit}
                nodesConnectable={canEdit}
                nodesFocusable={canEdit}
                elementsSelectable={true}
                minZoom={0}
                maxZoom={2}
            >
                {canEdit && modificationType !== "view" && (
                    <div className='node_view_control_buttons'>
                        <Button type="button" variant="primary" onClick={onPresentAddQuestModal} disabled={!canEdit}>Add Quest To Storyline</Button>
                    </div>
                )}
                <MiniMap
                    nodeStrokeColor={(n) => {
                        if (n.type === 'input') return '#0041d0';
                        if (n.type === 'selectorNode') return bgColor;
                        if (n.type === 'output') return '#ff0072';
                        return '#000'; // Default color if none of the conditions are met
                    }}
                    nodeColor={(n) => {
                        if (n.type === 'selectorNode') return bgColor;
                        return '#fff'; // This already acts as a default return value
                    }}
                />
                <Controls />
            </ReactFlow>
        </Container>

    )
}

export const UtilityButton = styled.div`
    border: none;
    background: #fefefe;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    padding: 16px;

    background-image: initial;
    background-color: rgb(26, 27, 27);
    border-bottom-color: rgb(55, 57, 58);
    color: white;
`

export const UtilityWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    margin: 15px;
    z-index: 5;
`

export const TreeWrapper = styled.div`
> node__root > circle {
    fill: red;
  }
  
  > node__branch > circle {
    fill: yellow;
  }
  
  > node__leaf > circle {
    fill: green;
    /* Let's also make the radius of leaf nodes larger */
    r: 40;
  }
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: inherit;
    position:relative;
`

export default StoryLineContainer