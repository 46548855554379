import React, { ChangeEvent } from 'react';
import { QuestObject, QuestStoryLine, StorylineDetails } from '../interfaces/quest';
import WarningMessage from '../../WarningMessage';
import styled from 'styled-components';

interface SectionQuestStorylineDescriptionProps {
  questDescription: string;
  setSelectedStoryline: React.Dispatch<React.SetStateAction<StorylineDetails>>;
  inputfieldNotice: { message: string, targetId: string }
}

const SectionQuestStorylineDescription: React.FC<SectionQuestStorylineDescriptionProps> = ({ questDescription, setSelectedStoryline, inputfieldNotice }) => {
  const handleQuestDescriptionChange = (newDescription: string) => {
    setSelectedStoryline((prevStoryline) => ({
      ...prevStoryline,
      description: newDescription,
    }));
  };

  return (
    <>
      {inputfieldNotice.targetId === "storyline_description_section" && <WarningMessage message={inputfieldNotice.message} />}
      <Container id="storyline_description_section">
        <h2>Storyline description</h2>
        <textarea
          id="quest_storyline_description"
          name="quest_storyline_description"
          placeholder="Type the quest story linedescription in this text box"
          rows={3}
          value={questDescription}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
            handleQuestDescriptionChange(event.target.value);
          }}
        />
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export default SectionQuestStorylineDescription;
