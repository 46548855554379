import React, { useEffect, useState } from 'react'
import { QuestObject } from '../../interfaces/quest';
import styled, { keyframes } from 'styled-components';
import Button from '../../../Button';
import { useFirebase } from '../../../../contexts/Firebase/Firebase';
import shortUUID from 'short-uuid';
import QuestDataContainer from '../../../QuestDataContainer';
import SectionQuestTitle from '../../sections/SectionQuestTitle';
import SectionQuestDescription from '../../sections/SectionQuestDescription';
import SectionQuestRequirements from '../../sections/SectionQuestRequirements';
import SectionQuestObjectives from '../../sections/SectionQuestObjectives';
import SectionQuestRewards from '../../sections/SectionQuestRewards';
import useModal from '../../../../hooks/useModal';
import SaveQuestConfirmModal from '../Modals/SaveQuestConfirmModal';
import DeleteQuestConfirmModal from '../Modals/DeleteQuestConfirmModal';
import { SanitizeUserInputForQuestObject } from '../../../../utils/quests/sanitizeUserInputQuestObject';
import { QuestStoryTypeEnum } from '../../enums/QuestStoryType';

interface ViewEditQuestProps {
  questObject: QuestObject;
  setUserQuests: React.Dispatch<React.SetStateAction<QuestObject[]>>
  setSelectedQuestObject: React.Dispatch<React.SetStateAction<QuestObject>>;
  canEditQuest: boolean;
  modificationType: "create" | "edit" | "view" | undefined
  modificationButtonHandler: (type: string, QuestObject: QuestObject | undefined) => void;
}

const ViewEditQuest: React.FC<ViewEditQuestProps> = ({ questObject, setUserQuests, setSelectedQuestObject, canEditQuest, modificationType, modificationButtonHandler }) => {
  const { user } = useFirebase();
  const [onPresentSaveEditQuestConfirmModal] = useModal(<SaveQuestConfirmModal setUserQuests={setUserQuests} questDataObject={questObject} modificationButtonHandler={modificationButtonHandler} modificationType={modificationType} />)
  const [onPresentDeleteQuestModal] = useModal(<DeleteQuestConfirmModal setUserQuests={setUserQuests} modificationButtonHandler={modificationButtonHandler} questDataObject={questObject} />)

  const [inputfieldNotice, setInputfieldNotice] = useState({ message: "", targetId: "" });

  const cancelButtonHandler = () => {
    if (modificationType === "create") {
      modificationButtonHandler("view", undefined)
    } else if (modificationType === "edit") {
      modificationButtonHandler("view", questObject)
    }
  }

  const CreateEditButtonHandler = () => {
    const issue = SanitizeUserInputForQuestObject(questObject);

    // Update the state with the new issue ID and message, or clear it
    if (issue) {
        setInputfieldNotice({ message: issue.message, targetId: issue.id });
    } else {
      // when there are no issues with the input we open the modal
      setInputfieldNotice({ message: "", targetId: "" });
      if (modificationType === "create" || modificationType === "edit") {
        onPresentSaveEditQuestConfirmModal();
      } else {
        return;
      }
    }
  }

  useEffect(() => {
    // Check if there is a message to display.
    if (inputfieldNotice.message !== "") {
      // Set a timeout to clear the message after 5 seconds (5000 milliseconds).
      const timer = setTimeout(() => {
        setInputfieldNotice({ message: "", targetId: "" });
      }, 11000);
  
      // Clear the timeout if the component unmounts or if the message changes before the 5 seconds are up.
      // This prevents potential memory leaks.
      return () => clearTimeout(timer);
    }
  }, [inputfieldNotice.message]);

  useEffect(() => {
    if (modificationType == "create" && user) {
      const uniqueUUID = shortUUID.generate();
      const storylineId = `storyline_${user.uid}_${uniqueUUID}`;

      questObject.id = storylineId;
    }
  }, [modificationType]);

  return (
    <Container>
      {canEditQuest ? (
        <AnimatedForm canEdit={canEditQuest}>
          <QuestModalContainer>
            <form>
              <SectionQuestTitle type={questObject.type as QuestStoryTypeEnum} questTitle={questObject.title} setQuestObj={setSelectedQuestObject} inputfieldNotice={inputfieldNotice}/>
              <SectionQuestDescription questDescription={questObject.description} setQuestObj={setSelectedQuestObject}  inputfieldNotice={inputfieldNotice} />
              <SectionQuestRequirements questObj={questObject} setQuestObj={setSelectedQuestObject}  inputfieldNotice={inputfieldNotice} modificationType={modificationType}/>
              <SectionQuestObjectives questObj={questObject} setQuestObj={setSelectedQuestObject} inputfieldNotice={inputfieldNotice} modificationType={modificationType}/>
              <SectionQuestRewards questObj={questObject} setQuestObj={setSelectedQuestObject}  inputfieldNotice={inputfieldNotice} modificationType={modificationType}/>
            </form>
          </QuestModalContainer>
        </AnimatedForm>
      ) : (
        <AnimatedContent canEdit={canEditQuest}>
          <QuestDataContainer canEdit={modificationType == "view"} questObj={questObject} modificationButtonHandler={modificationButtonHandler} />
        </AnimatedContent>
      )}
      {canEditQuest && (
        <ButtonContainer>
          {modificationType == "edit" && (
            <NewQuestButtonContainer style={{ marginRight: 'auto' }}>
              <Button type="button" variant="primary" onClick={onPresentDeleteQuestModal} disabled={false}>Delete Quest</Button>
            </NewQuestButtonContainer>
          )}

          <NewQuestButtonContainer>
            <Button type="button" variant="primary" onClick={() => cancelButtonHandler()} disabled={false}>Cancel</Button>
          </NewQuestButtonContainer>
          <NewQuestButtonContainer>
            <Button type="button" variant="primary" onClick={() => CreateEditButtonHandler()} disabled={false}>{modificationType == "create" ? "Create Quest" : "Save Changes"}</Button>
          </NewQuestButtonContainer>
        </ButtonContainer>
      )}
    </Container>

  )
}



const NewQuestButtonContainer = styled.div`


    > div {
        padding: 4px;
        background-color: #1b1111;
        border: 1px solid #3d2224;
        
        background-color: #311414;
        border: 1px solid #3d2224;
        box-shadow: 0 0 8px 7px #0000003b inset;

        transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;

        > h3 {
            color: #c1b197 !important;
            font-weight: 700;
            user-select: none;
            margin: 0;
            text-align: center;
            font-size: 1.4rem;
            transition: color 0.2s ease-in-out;
        }

        &:hover {
            cursor:pointer;
            background-color: #3f1616;
            border: 1px solid #6a393d;

            h3 {
                color: #efb754 !important;

            }
        }
    }
`

const ButtonContainer = styled.div`
padding: 8px 0;
border-top: 1px solid #2b2824;
display: flex;
gap: 8px;
justify-content: flex-end;
`


const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const AnimatedContent = styled.div<{ canEdit: boolean }>`
position: relative;
  animation: ${fadeOut}  0.3s ease-in-out;

  animation: ${({ canEdit }) => (canEdit ? fadeOut : fadeIn)} 0.3s ease-in-out;
  opacity: ${({ canEdit }) => (canEdit ? 0 : 1)};
  pointer-events: ${({ canEdit }) => (canEdit ? 'none' : 'auto')};
  transition: opacity 0.3s ease-in-out;

  h1 {
    color: #c1b197 !important;
    margin: 0;
    margin-bottom: 10px;
  }

  h2 {
    color: #bd8b42 !important;
    margin: 0;
  }

  h3 {
    color: #9a6922 !important;
  }

  p {
    color: #8b7e6e !important;
    margin: 0;
  }

  span {
    color: #8b7e6e!important;
  }
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: inherit;
    position:relative;
`


const AnimatedForm = styled.div<{ canEdit: boolean }>`
  animation: ${fadeIn} 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
animation: ${({ canEdit }) => (canEdit ? fadeIn : fadeOut)} 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  opacity: ${({ canEdit }) => (canEdit ? 1 : 0)};
  pointer-events: ${({ canEdit }) => (canEdit ? 'auto' : 'none')};
  transition: opacity 0.3s ease-in-out;
  // Other styles...

  input {
    background: #1f1e1d;
    margin-bottom: 0px;
    padding: 2px 6px;
    text-align: left;
    font-family: 'Goudy Bookletter 1911',sans-serif;
    letter-spacing: 1px;
    line-height: 1.1;
    font-size: 18px;
    color: #A99C8EFF;
    border: 1px solid #635a4d;
    padding: 2px 6px;
    outline: unset;
    margin-bottom: 10px
  }

h1 {
    color: #c1b197 !important;
    margin: 0;
    margin-bottom: 10px;
  }

  h2 {
    color: #bd8b42 !important;
    margin: 0;
  }

  h3 {
    color: #9a6922 !important;
  }

  p {
    color: #8b7e6e !important;
    margin: 0;
  }

  span {
    color: #8b7e6e!important;
  }
`;

const AnimatedDiv = styled.div<{ canEdit: boolean }>`
  animation: ${fadeIn} 0.3s ease-in-out;
animation: ${({ canEdit }) => (canEdit ? fadeIn : fadeOut)} 0.3s ease-in-out;
  opacity: ${({ canEdit }) => (canEdit ? 1 : 0)};
  pointer-events: ${({ canEdit }) => (canEdit ? 'auto' : 'none')};
  transition: opacity 0.3s ease-in-out;
`;


const QuestModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
      input {
        display: block;
        margin-top: unset;
      }

       input {
        background: #2a2a29;
      
        margin-bottom: 0px;

        padding: 2px 6px;
        text-align: left;

        background-size: cover;

        font-family: 'Goudy Bookletter 1911', sans-serif;
        letter-spacing: 1px;
        line-height: 1.1;
        font-size: 18px;
        color: #A99C8EFF;
        border: 1px solid #635a4d;
        padding: 2px 6px;
        outline: unset;
      }

      label {
        display: block;
        color: #9a9081;
      }

      span {
        color: #9a9081;
      }

       h2 
      {
        margin: 0 0 5px 0;
        color: #9a9081;
      }

      h3{
        color: #9a9081;
      }
  }
`

export default ViewEditQuest