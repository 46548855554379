import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import WarningMessage from "../../components/WarningMessage";
import { ExtendedUser, FirebaseContext } from '../../contexts/Firebase/Firebase';
import { getDoc, doc } from 'firebase/firestore';
import { AUTH_MESSAGE_SIGNING_IN } from "../../constants/authMessage";
import MainContainer from "../../components/MainContainer";
import InputfieldNew from "../../components/InputfieldNew";
import Button from "../../components/Button";
import AuthFooter from "../../components/AuthFooter";

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState<string>("");
  const [userPassword, setUserPassword] = useState<string>("");
  const [notice, setNotice] = useState<string>("");
  const firebaseContext = useContext(FirebaseContext);

  if (!firebaseContext) {
    console.error('FirebaseContext not available');
    return null;
  }

  const { auth, db, signInWithEmailAndPassword, setUser, setAuthCheckType, setAuthMessage } = firebaseContext;


  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserPassword(e.target.value);
  };

  const loginWithUsernameAndPassword = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, userEmail, userPassword);
      const user = userCredential.user;

      if (user.email !== null) {

        // Retrieve the user document from firestore
        const userDocument = await getDoc(doc(db, "users", user.uid))

        // Extract rank & date from user document
        const userRank = userDocument.data()?.rank || '';
        const userCreatedAt = userDocument.data()?.created_at || '';
        const displayName = userDocument.data()?.display_name || '';
        const authenticatedUser: ExtendedUser = { ...user, rank: userRank, display_name: displayName, created_at: userCreatedAt }
        setUser(authenticatedUser)
        setAuthCheckType(1)
        setAuthMessage(AUTH_MESSAGE_SIGNING_IN)

        setTimeout(() => navigate("/dashboard/quests/overview"), 100);

      }

      //setTimeout(() => navigate("/dashboard/quests/overview"), 100);
      //navigate("./dashboard/quests/overview");
    } catch {
      setNotice("You entered a wrong username or password.");
    }
  };

  return (
    <OuterContainer>
      <MainContainerWrapper>
        <MainContainerContent>
          <AppTitleContainer>
            <h1>Alerith</h1>
          </AppTitleContainer>
          <MainContainer title={'Log in'} type={'other'}>
            <MainContainerInner>
              <h2>Log in to your account</h2>
              <p>Welcome back! Please enter your details.</p>
              <InputfieldContainer>
                {notice !== ""  && (
                  <WarningMessage message={notice}/>
                )}
                <div className='wrapper_inputfield'>
                  <div>
                    <label htmlFor='input_email'>Email</label>
                  </div>
                  <InputfieldNew
                    type="text"
                    id="input_email"
                    placeholder="Enter your email"
                    value={userEmail}
                    onChange={handleEmailChange}
                  />
                </div>
                <div className='wrapper_inputfield'>
                  <div>
                    <label htmlFor='exampleInputPassword1'>Password</label>
                    <Link to={'/forgotpassword'}>Forgot password?</Link>
                  </div>
                  <InputfieldNew
                    type="password"
                    id="exampleInputPassword1"
                    placeholder="Enter your password"
                    value={userPassword}
                    onChange={handlePasswordChange}
                  />
                </div>
              </InputfieldContainer>
              <ButtonContainer>
                <Button onClick={loginWithUsernameAndPassword}>Log in</Button>
              </ButtonContainer>
            </MainContainerInner>
          </MainContainer>
        </MainContainerContent>
      </MainContainerWrapper>
      <AuthFooter text="Don't have an account?" url_text="Sign up!" url_link="/signup" />

    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
`

const ButtonContainer = styled.div`
  margin-top: 16px;
  width: 100%;

  > button {
    width: 100%;
  }
`

const MainContainerInner = styled.div`
  width: 100%;

  h2 {
    margin: 0;
    color: #c1b197;
  }

  p {
    margin: 8px 0;
  }
`

const InputfieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  
`

const MainContainerContent = styled.div`
display: flex;
flex-direction: column;
align-items: center;

  > #main__container {
    max-width: 500px;
  }
  
 .main__container__content {
    padding: 16px;
    box-shadow: 0 0 9px 4px #0000008f;
  }
`

const AppTitleContainer = styled.div`
padding: 40px;
text-align: center;

  h1 {
    font-size: 5rem;
    color: #c1b197;
    text-shadow: 0 0 20px black;
    margin: 0;
  }
`

const MainContainerWrapper = styled.div`
  position: fixed;
  width: calc(100% - 16px);
  height: calc(100% - 16px - 50px);
  padding: 8px;
  box-shadow: 0 0 20px 4px inset #0000008f;

`

export default SignIn;