import React, { createContext, useCallback, useState } from 'react'
import styled from 'styled-components'

interface ModalsContext {
    content?: React.ReactNode,
    isOpen?: boolean,
    onPresent: (content: React.ReactNode, key?: string) => void,
    onDismiss: () => void
  }
  
export const Context = createContext<ModalsContext>({
    onPresent: () => {},
    onDismiss: () => {},
})

const Modals: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [content, setContent] = useState<React.ReactNode>()
    const [modalKey, setModalKey] = useState<string>()
  
    const handlePresent = useCallback((modalContent: React.ReactNode, key?: string) => {
      setModalKey(key)
      setContent(modalContent)
      setIsOpen(true)
    }, [setContent, setIsOpen, setModalKey])
  
    const handleDismiss = useCallback(() => {
      setContent(undefined)
      setIsOpen(false)
    }, [setContent, setIsOpen, modalKey])
  

  return (
    <Context.Provider value={{
        content,
        isOpen,
        onPresent: handlePresent,
        onDismiss: handleDismiss,
      }}>
        {children}
        {isOpen && (
        <StyledModalWrapper>
            <StyledModalBackdrop onClick={handleDismiss} />
            {React.isValidElement(content) &&
            React.cloneElement(content, {
                onDismiss: handleDismiss
            } as React.Attributes) /* Type assertion here */
            }
        </StyledModalWrapper>
        )}
      </Context.Provider>
    )
}

const StyledModalWrapper = styled.div`
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 16px;
`

const StyledModalBackdrop = styled.div`
  background-color: #060606ed;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
`

export default Modals