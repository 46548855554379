import React from "react";
import { QuestObject } from "../quest/interfaces/quest";
import styled from "styled-components";
import QuestPropertyItemView from "../quest/components/QuestPropertyItemView";
import QuestRequirementsEnum from "../quest/enums/QuestRequirements";
import QuestObjectiveEnum from "../quest/enums/QuestObjectiveEnum";
import { QuestRewardsEnum } from "../quest/enums/QuestRewardsEnum";
import { useFirebase } from "../../contexts/Firebase/Firebase";
import useModal from "../../hooks/useModal";
import Button from "../Button";

interface QuestDataContainerProps {
  questObj: QuestObject;
  canEdit: boolean;
  modificationButtonHandler?: (type: string, QuestObject: QuestObject | undefined) => void;
}

const QuestDataContainer: React.FC<QuestDataContainerProps> = ({ questObj, canEdit, modificationButtonHandler }) => {
  return (
    <Container>
      {canEdit && modificationButtonHandler && (
        <div style={{ position: 'absolute', top: '0', right: '0', margin: 'unset' }}>
          <Button onClick={() => modificationButtonHandler("edit", questObj)}>Edit Quest</Button>
        </div>
      )}

      <h1>{questObj.title}</h1>

      <p>{questObj.description}</p>
      <h2>Requirements</h2>

      <OptionContainer className="quest__properties__grid__container">
        {Object.values(QuestRequirementsEnum).map((requirementType) => {
          if (questObj.requirements && Object.keys(questObj.requirements).length > 0) {
            const filteredRequirements = Object.values(questObj.requirements).filter(
              (requirement) => requirement.type === requirementType
            );

            if (filteredRequirements.length > 0) {
              return (
                <div key={requirementType}>
                  <h3>{requirementType}</h3>
                  <SectionContainer>
                    {filteredRequirements.map((filteredRequirement) => (
                      <div key={filteredRequirement.id}>
                        <QuestPropertyItemView QuestProperty={filteredRequirement}
                        />
                      </div>
                    ))}
                  </SectionContainer>
                </div>
              );
            }
          }

          return null;
        })}
      </OptionContainer>
      <h2>Objectives</h2>

      <OptionContainer className="quest__properties__grid__container">
        {Object.values(QuestObjectiveEnum).map((objectiveType) => {
          if (questObj.objectives && Object.keys(questObj.objectives).length > 0) {
            const filteredObjectives = Object.values(questObj.objectives).filter(
              (objective) => objective.type === objectiveType
            );

            if (filteredObjectives.length > 0) {
              return (
                <div key={objectiveType}>
                  <h3>{objectiveType}</h3>
                  <SectionContainer>
                    {filteredObjectives.map((filteredObjective) => (
                      <div key={filteredObjective.id}>
                        <QuestPropertyItemView QuestProperty={filteredObjective}
                        />
                      </div>
                    ))}
                  </SectionContainer>
                </div>
              );
            }
          }

          return null;
        })}
      </OptionContainer>
      <h2>Rewards</h2>
      <OptionContainer className="quest__properties__grid__container">
        {Object.values(QuestRewardsEnum).map((rewardType) => {
          if (questObj.rewards && Object.keys(questObj.rewards).length > 0) {
            const filteredRewards = Object.values(questObj.rewards).filter(
              (reward) => reward.type === rewardType
            );

            if (filteredRewards.length > 0) {
              return (
                <div key={rewardType}>
                  <h3>{rewardType}</h3>
                  <SectionContainer>
                    {filteredRewards.map((filteredReward) => (
                      <div key={filteredReward.id}>
                        <QuestPropertyItemView QuestProperty={filteredReward}

                        />
                      </div>
                    ))}
                  </SectionContainer>
                </div>
              );
            }
          }

          return null;
        })}
      </OptionContainer>
    </Container>
  )
}

const Container = styled.div`
  position: relative;

  h1 {
    color: #c1b197 !important;
    margin: 0;
  margin-bottom: 10px;  
    max-width: calc(100% - 90px);
    overflow-wrap: anywhere;
  }

  h2 {
    color: #bd8b42 !important;
    margin: 0;
    margin-top: 10px;
  }

  h3 {
    color: #9a6922 !important;
  }

  p {
    color: #8b7e6e !important;
    margin: 0;

    white-space: pre-wrap; /* Wraps text and preserves whitespace */
    word-wrap: break-word; /* Allows long words to wrap */
    text-overflow: ellipsis; /* Shows '...' at the end of clipped text */
    max-width: 100%;
    overflow-wrap: anywhere;
  }

  span {
    color: #8b7e6e!important;
    white-space: pre-wrap; /* Wraps text and preserves whitespace */
    word-wrap: break-word; /* Allows long words to wrap */
    text-overflow: ellipsis; /* Shows '...' at the end of clipped text */
    max-width: 100%;
    
  }
`

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const OptionContainer = styled.div`
  /*display: flex;
  flex-direction: column;*/

  display: grid;
  flex-direction: column;
  grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  h3 {
    margin: 5px 0;
  }
`;

export default QuestDataContainer