import React, { memo, useEffect, useRef, useState } from 'react';
import { Handle, Position } from 'reactflow';
import styled from 'styled-components';
import { QuestObjectiveEnum } from '../../enums/QuestObjectiveEnum'
import { QuestRewardsEnum } from '../../enums/QuestRewardsEnum'
import { QuestObjective, QuestRequirement, QuestReward } from '../../interfaces/quest';
import QuestPropertyItemView from '../../components/QuestPropertyItemView';
import QuestRequirementsEnum from '../../enums/QuestRequirements';
import MainContainer from '../../../MainContainer';
import ArrowSvgIcon from '../../../ArrowSvgIcon';
import Button from '../../../Button';
import { shortenString } from '../../../../utils/quests/shortenString';

interface QuestNodeObjectProps {
    data: any
    isConnectable: any;
}

const QuestNodeObject: React.FC<QuestNodeObjectProps> = memo(({ data, isConnectable }) => {
    const [isOpen, setIsOpen] = useState(false);

    const contentRef = useRef<HTMLDivElement>(null);
    const [maxHeight, setMaxHeight] = useState("0px");

    const toggleProperties = () => {
        if (contentRef.current) {
            setMaxHeight(maxHeight === "0px" ? `${contentRef.current.scrollHeight}px` : "0px");
            setIsOpen(!isOpen);

        }
    };
    
    useEffect(() => {
        const updateMaxHeight = () => {
            if (contentRef.current && maxHeight !== "0px") {
                setMaxHeight(`${contentRef.current.scrollHeight}px`);
            }
        };
        window.addEventListener('resize', updateMaxHeight);
        return () => window.removeEventListener('resize', updateMaxHeight);
    }, [maxHeight]);

    return (
        <MainContainer title={data.quest.title} type="node">
            <Handle
                type="target"
                position={Position.Left}
                style={{ left: '-6px' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
                className='quest__node__handle'
            />
            <Container>
                <p>{shortenString(data.quest.description, 300)}</p>

                <Button type="button" variant="primary" onClick={toggleProperties} disabled={false} width={'100%'}>
                     <ArrowSvgIcon direction={isOpen && isOpen ? 'down' : 'up'} size={15} fillColor={'#8b7e6e'} />
                     <span style={{display: 'inline-block'}}>{isOpen && isOpen ? (<>Hide</>) : (<>Show</>)} Quest Properties</span>
                     <ArrowSvgIcon direction={isOpen && isOpen ? 'down' : 'up'} size={15} fillColor={'#8b7e6e'} />

                </Button>

                <QuestProperties style={{ maxHeight }} ref={contentRef} className={`${isOpen ? 'open' : ''}`}>
                    <h2>Requirements</h2>



                    <OptionContainer>
                        {Object.values(QuestRequirementsEnum).map((requirementType) => {
                            if (data.quest.requirements && Object.keys(data.quest.requirements).length > 0) {
                                const filteredRequirements = Object.values(data.quest.requirements).filter(
                                    (requirement) => (requirement as QuestRequirement).type === requirementType
                                );

                                if (filteredRequirements.length > 0) {
                                    return (
                                        <div key={requirementType}>
                                            <h3>{requirementType}</h3>
                                            <SectionContainer>
                                                {filteredRequirements.map((filteredRequirement) => {
                                                    const req: QuestRequirement = filteredRequirement as QuestRequirement;
                                                    return (
                                                        <div key={req.id}>
                                                            <QuestPropertyItemView QuestProperty={req}
                                                            />
                                                        </div>
                                                    )
                                                })}
                                            </SectionContainer>
                                        </div>
                                    );
                                }
                            }

                            return null;
                        })}
                    </OptionContainer>
                    <h2>Objectives</h2>

                    <OptionContainer>
                        {Object.values(QuestObjectiveEnum).map((objectiveType) => {
                            if (data.quest.objectives && Object.keys(data.quest.objectives).length > 0) {
                                const filteredObjectives = Object.values(data.quest.objectives).filter(
                                    (objective) => (objective as QuestObjective).type === objectiveType
                                );

                                if (filteredObjectives.length > 0) {
                                    return (
                                        <div key={objectiveType}>
                                            <h3>{objectiveType}</h3>
                                            <SectionContainer>
                                                {filteredObjectives.map((filteredObjective) => {
                                                    const obj: QuestObjective = filteredObjective as QuestObjective;

                                                    return (
                                                        <div key={obj.id}>
                                                            {/* Use QuestPropertyItem instead of QuestObjectiveRender */}
                                                            <QuestPropertyItemView QuestProperty={obj}

                                                            />
                                                        </div>
                                                    )
                                                })}
                                            </SectionContainer>
                                        </div>
                                    );
                                }
                            }

                            return null;
                        })}
                    </OptionContainer>
                    <h2>Rewards</h2>
                    <OptionContainer>
                        {Object.values(QuestRewardsEnum).map((rewardType) => {
                            if (data.quest.rewards && Object.keys(data.quest.rewards).length > 0) {
                                const filteredRewards = Object.values(data.quest.rewards).filter(
                                    (reward) => (reward as QuestReward).type === rewardType

                                );

                                if (filteredRewards.length > 0) {
                                    return (
                                        <div key={rewardType}>
                                            <h3>{rewardType}</h3>
                                            <SectionContainer>
                                                {filteredRewards.map((filteredReward) => {
                                                    const reward: QuestReward = filteredReward as QuestReward;

                                                    return (
                                                        <div key={reward.id}>
                                                            <QuestPropertyItemView QuestProperty={reward}

                                                            />
                                                        </div>
                                                    )
                                                })}
                                            </SectionContainer>
                                        </div>
                                    );
                                }
                            }

                            return null;
                        })}
                    </OptionContainer>
                </QuestProperties>
            </Container>
            <Handle
                type="source"
                position={Position.Right}
                id="a"
                style={{ right: '-6px' }}
                isConnectable={isConnectable}
                className='quest__node__handle'
            />
        </MainContainer>
    );
});

export const QuestProperties = styled.div`
        transform: translateY(-50px); /* Initially move the elements up */
    opacity: 0; /* Initially hide the elements */
    transition: transform 0.3s ease-out, opacity 0.3s ease-out; /* Add transition for smooth animation */

    overflow: hidden; /* Hide the elements initially */
    transition: max-height 0.3s ease-out; /* Add transition for smooth animation */

    h1,h2,h3,h4,h5,span,p{
        opacity: 0;
        transition: opacity 0.3s ease-out;
    }

    &.open {
        transform: translateY(0); /* Move the elements down when open */
        opacity: 1; /* Show the elements */

        transform: translateY(0);
        opacity: 1;
        h1,h2,h3,h4,h5,span,p{
            opacity: 1;

        }   
    }
`

export const ContainerRewards = styled.div`
    div {
        span {
            display: block;
        }
    }
`

export const ContainerObjectives = styled.div`
    div {
        span:nth-child(1) {
            color: #6E665CFF;
            margin-right: 4px;
        }
        span:nth-child(2) {
            color: #C6B7A2FF;
        }
    }
`

export const ContainerDescription = styled.div`
text-align: center;
`

export const ContainerHeader = styled.div`
    text-align: center;
`

export const ContainerWrapper = styled.div`
    display: flex;
    flex-direction:column;
    max-width: 500px;
    gap: 8px;
    span {
        color: #8B7E6EFF; 
    }

    h1,
    h2,
    h3 {
        color: #C1B197FF;
        margin: 0;
        text-align: center;
    }

    h2 {
        margin: 8px 0 0 0;
    }

    > div:not(:nth-child(1)):not(:nth-child(2)) {
        margin-top: 8px;
    }
`

const RadialBlur = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 50%; /* This creates a circular shape */
    background: radial-gradient(circle, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 1) 100%); /* Red color gradient */
    filter: blur(10px); /* Adjust the blur radius as needed */
`

const Container = styled.div`
padding: 8px;
max-width: 400px;
  h1 {
    color: #c1b197 !important;
    font-size: 2.8rem;
    margin: 0;
    overflow-wrap: anywhere;
  }

  h2 {
    color: #bd8b42 !important;
    margin: 0;
    font-size: 2.6rem;
    margin-top: 10px;
  }

  h3 {
    color: #9a6922 !important;
  }

  p {
    color: #8b7e6e !important;
    margin: 0;
    margin-bottom: 8px;
    text-align: center;
    overflow-wrap: anywhere;
  }

  span {
    color: #8b7e6e!important;
  }

  a {
    cursor: pointer;
  }
`

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const OptionContainer = styled.div`
  /*display: flex;
  flex-direction: column;*/

  display: grid;
  flex-direction: column;
  grid-template-columns: repeat(1, 1fr);;

  h3 {
    margin: 5px 0;
  }
`;

export default QuestNodeObject;