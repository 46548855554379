import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { QuestRewardsEnum } from '../enums/QuestRewardsEnum';
import { ProfessionsEnum } from '../enums/ProfessionsEnum';
import QuestRewardsContainer from '../components/QuestRewardsContainer';

import {
  QuestObject,
  QuestReward
} from '../interfaces/quest';

import {
  templateQuestRewardCurrency,
  templateQuestRewardExperience,
  templateQuestRewardItem
} from '../constants/QuestRewardTemplates';
import QuestPropertySection from '../components/QuestPropertySection';
import WarningMessage from '../../WarningMessage';


const DEFAULT_SELECTED_REWARD = QuestRewardsEnum.XP_REWARD;


interface QuestRewardSectionProps {
  questObj: QuestObject;
  setQuestObj: React.Dispatch<React.SetStateAction<QuestObject>>;
  inputfieldNotice: { message: string, targetId: string }
  modificationType: "create" | "edit" | "view" | undefined
}

const QuestRewardSection: React.FC<QuestRewardSectionProps> = ({ questObj, setQuestObj, inputfieldNotice, modificationType }) => {
  const [selectedReward, setSelectedReward] = useState<string>(DEFAULT_SELECTED_REWARD);
  const [questRewards, setQuestRewards] = useState<QuestReward[]>([]);
  const [questRewardTypeCount, setQuestRewardTypeCount] = useState<number[]>([0, 0, 0]);
  const [initialLoad, setInitialLoad] = useState(false)

  useEffect(() => {
    if (modificationType === "edit" && initialLoad === false) {
      let rew = Object.values(questObj.rewards)
      let countCur = 0;
      let countItem = 0;
      let countXp = 0;

      for (let index = 0; index < rew.length; index++) {
        switch (rew[index].type) {
          case QuestRewardsEnum.CURRENCY_REWARD:
            countCur++
            break;
          case QuestRewardsEnum.ITEM_REWARD:
            countItem++
            break;
          case QuestRewardsEnum.XP_REWARD:
            countXp++
            break;
          default:
            break;
        }

        setQuestRewardTypeCount([countCur, countItem, countXp])
      }

      setInitialLoad(true)
    }
  }, [modificationType]);

  const handleRemoveQuestReward = (id: number) => {
    // Filter out the removed reward
    const filteredRewards = { ...questObj.rewards };
    delete filteredRewards[id];

    const updatedTypeCount = [0, 0, 0];
    let nextId = 0;
    // Update the IDs and type_reward_ids of the remaining rewards
    const filteredRewardsWithAdjustedIds = Object.values(filteredRewards).map((reward, index) => {
      let typeRewardId = 0;

      reward.id = nextId
      nextId++

      switch (reward.type) {
        case QuestRewardsEnum.CURRENCY_REWARD:
          typeRewardId = updatedTypeCount[0];
          reward.type_count = updatedTypeCount[0];
          updatedTypeCount[0]++;
          break;
        case QuestRewardsEnum.ITEM_REWARD:
          typeRewardId = updatedTypeCount[1];
          reward.type_count = updatedTypeCount[1];

          updatedTypeCount[1]++;
          break;
        case QuestRewardsEnum.XP_REWARD:
          typeRewardId = updatedTypeCount[2];
          reward.type_count = updatedTypeCount[2];

          updatedTypeCount[2]++;
          break;
        default:
          break;
      }

      return {
        ...reward
      };
    });

    // Update the count of the quest reward types
    setQuestRewardTypeCount(updatedTypeCount);

    // Update the quest rewards state
    setQuestObj((prevQuestObj) => ({
      ...prevQuestObj,
      rewards: filteredRewardsWithAdjustedIds,
    }));
  };

  const handleAddQuestReward = () => {
    if (!selectedReward) {
      return;
    }

    let nextId = questRewardTypeCount.reduce((acc, count) => acc + count, 0);
    let newQuestReward: QuestReward;

    switch (selectedReward) {
      case QuestRewardsEnum.CURRENCY_REWARD:
        setQuestRewardTypeCount((prevCount) => [
          prevCount[0] + 1,
          prevCount[1],
          prevCount[2],
        ]);

        let currencyRewardTemplate = templateQuestRewardCurrency;
        currencyRewardTemplate.id = nextId;

        newQuestReward = {
          ...currencyRewardTemplate,
        };
        break;
      case QuestRewardsEnum.ITEM_REWARD:
        setQuestRewardTypeCount((prevCount) => [
          prevCount[0],
          prevCount[1] + 1,
          prevCount[2],
        ]);

        let itemRewardTemplate = templateQuestRewardItem;
        itemRewardTemplate.type_count = questRewardTypeCount[1];
        itemRewardTemplate.id = nextId;

        newQuestReward = {
          ...itemRewardTemplate,
        };
        break;
      case QuestRewardsEnum.XP_REWARD:
        setQuestRewardTypeCount((prevCount) => [
          prevCount[0],
          prevCount[1],
          prevCount[2] + 1,
        ]);

        let xpRewardTemplate = templateQuestRewardExperience;
        xpRewardTemplate.type_count = questRewardTypeCount[2];
        xpRewardTemplate.id = nextId;
        xpRewardTemplate.profession_name = ProfessionsEnum.ATTACK;
        newQuestReward = {
          ...xpRewardTemplate,
        };
        break;
      default:
        newQuestReward = { id: nextId, type_count: -1, type: "UNDEFINED?!" };
        break;
    }

    setQuestRewards((prevQuestRewards) => [...prevQuestRewards, newQuestReward]);

    setQuestObj((prevQuestObj) => ({
      ...prevQuestObj,
      rewards: {
        ...prevQuestObj.rewards,
        [nextId]: newQuestReward,
      },
    }));
  };

  return (
    <>
      {inputfieldNotice.targetId === "quest_rewards_section" && <WarningMessage message={inputfieldNotice.message} />}
      <QuestPropertySection
        title={"Quest Rewards"}
        htmlId={"quest_rewards_section"}
        selectedValue={selectedReward}
        options={Object.values(QuestRewardsEnum)}
        onSelectedChange={setSelectedReward}
        onAdd={handleAddQuestReward}
        propertyType='Reward'>

        <QuestRewardsContainer
          questObj={questObj}
          setQuestObj={setQuestObj}
          handleRemoveQuestObjective={handleRemoveQuestReward}
          inputfieldNotice={inputfieldNotice}
        />
      </QuestPropertySection>
    </>
  );
};

export const SectionContainer = styled.div`

  h2 {
    margin: 0;
  }

  > div {
    display: flex;
    justify-content: space-between;
      
    > div {
      display: flex;
          
      > div {
        min-width: 120px;
      }
      
      select {
        display: inline-block;
        height: 100%;
        min-width: 164px;
        max-width: 164px;
      }
    }
  }
`;

export default QuestRewardSection;
