import React from 'react';
import styled from 'styled-components';
import { QuestObjectiveEnum } from '../enums/QuestObjectiveEnum';
import QuestPropertyItem from '../components/QuestPropertyItem';

import {
  QuestObject
} from '../interfaces/quest';
import { getIdForInputfieldDiv } from '../../../constants/quest/InputWarningNoticeHelpers';
import WarningMessage from '../../WarningMessage';

interface QuestObjectivesContainerProps {
  questObj: QuestObject;
  setQuestObj: React.Dispatch<React.SetStateAction<QuestObject>>;
  questObjectiveTypeCount: number[];
  handleRemoveQuestObjective: (id: number) => void;
  inputfieldNotice: { message: string, targetId: string }
}

const QuestObjectivesContainer: React.FC<QuestObjectivesContainerProps> = ({
  questObj,
  setQuestObj,
  handleRemoveQuestObjective,
  inputfieldNotice
}) => {

  return (
    <OptionContainer>
      {Object.values(QuestObjectiveEnum).map((objectiveType) => {
        if (questObj.objectives && Object.keys(questObj.objectives).length > 0) {
          const filteredObjectives = Object.values(questObj.objectives).filter(
            (objective) => objective.type === objectiveType
          );

          if (filteredObjectives.length > 0) {
            return (
              <React.Fragment key={objectiveType}>
                <h3>{objectiveType}</h3>
                <SectionContainer>
                  {filteredObjectives.map((filteredObjective) => (
                    <div key={filteredObjective.id} id={getIdForInputfieldDiv("objective", filteredObjective.id.toString(), filteredObjective.type)}>
                      {inputfieldNotice.targetId === getIdForInputfieldDiv("objective", filteredObjective.id.toString(), filteredObjective.type) && <WarningMessage message={inputfieldNotice.message} />}

                      {/* Use QuestPropertyItem instead of QuestObjectiveRender */}
                      <QuestPropertyItem QuestProperty={filteredObjective}
                        setQuestObj={setQuestObj}
                        deleteQuestProperty={handleRemoveQuestObjective}
                      />
                    </div>
                  ))}
                </SectionContainer>
              </React.Fragment>
            );
          }
        }

        return null;
      })}
    </OptionContainer>
  );
};

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    margin: 5px 0;
  }
`;

export default QuestObjectivesContainer;