import React from 'react';
import './AuthFooter.css'
import Ornament from '../../assets/window/ornament.png'
import OrnamentEffect from '../../assets/window/ornament_effect.png'
import { Link } from 'react-router-dom';

interface AuthFooterProps {
  text: string
  url_link: string
  url_text:string
}

const AuthFooter: React.FC<AuthFooterProps> = ({ text, url_link, url_text }) => {
  return (
    <div className="container__footer__auth">
      <div>
        <img src={Ornament} className="ornament_window"/>
        <img src={OrnamentEffect} className="ornament_effect_window"/>
      </div>
      <p>{text}<Link to={url_link}>{url_text}</Link></p>
      <div>
        <img src={Ornament} className="ornament_window"/>
        <img src={OrnamentEffect} className="ornament_effect_window"/>
      </div>
    </div>
  );
};

export default AuthFooter;
