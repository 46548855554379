export const NPCNamesEnum = {
    VargusTheUnyielding: "Vargus the Unyielding",
    ElaraTheEnchantress: "Elara the Enchantress",
    ThorneShadowblade: "Thorne Shadowblade",
    SylasIronfist: "Sylas Ironfist",
    LyraSwiftwind: "Lyra Swiftwind",
    ZephyrTheWanderer: "Zephyr the Wanderer",
    SeraphinaDarkbane: "Seraphina Darkbane",
    BranTheBrave: "Bran the Brave",
    AstridTheArcane: "Astrid the Arcane",
    RagnarTheRed: "Ragnar the Red",
    FreyaTheFierce: "Freya the Fierce",
    AlaricTheAncient: "Alaric the Ancient",
    HelgaBloodmoon: "Helga Bloodmoon",
    DravenTheDeadly: "Draven the Deadly",
    GwendolynTheGuardian: "Gwendolyn the Guardian",
    MordekaiTheMalevolent: "Mordekai the Malevolent",
    SorenTheSwift: "Soren the Swift",
    IsoldeTheIllusionist: "Isolde the Illusionist",
    ThalgrimTheTerrible: "Thalgrim the Terrible",
    EirikTheEternal: "Eirik the Eternal",
    AureliaTheArdent: "Aurelia the Ardent",
    DariusDarkblade: "Darius Darkblade",
    ThaliaTheThunderer: "Thalia the Thunderer",
    LucianTheLoyal: "Lucian the Loyal",
    ValeriaTheVigilant: "Valeria the Vigilant",
    RurikTheRuthless: "Rurik the Ruthless",
    KaelTheKeeper: "Kael the Keeper",
    IsoldeTheInfernal: "Isolde the Infernal",
    SamaelTheSorcerer: "Samael the Sorcerer",
    SeraphinaTheSavage: "Seraphina the Savage",
    BastionTheBold: "Bastion the Bold",
    MarcellusTheMighty: "Marcellus the Mighty",
    AriaTheArcane: "Aria the Arcane",
    VarianTheValiant: "Varian the Valiant",
    ZephyraTheZealous: "Zephyra the Zealous",
    GalenTheGallant: "Galen the Gallant",
    IsoldeTheInquisitor: "Isolde the Inquisitor",
    ThalgrimTheThunderer: "Thalgrim the Thunderer",
    ElaraTheEnigmatic: "Elara the Enigmatic",
    AlistairTheAbyssal: "Alistair the Abyssal",
    LyraTheLuminous: "Lyra the Luminous",
    ThorneTheTwisted: "Thorne the Twisted",
    ZephyrTheZephyr: "Zephyr the Zephyr",
    SeraphinaTheSerene: "Seraphina the Serene",
    BranTheBright: "Bran the Bright",
    AstridTheAstral: "Astrid the Astral",
}