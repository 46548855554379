import React from 'react';
import styled from 'styled-components';

// Update the props interface to remove 'label' and include 'children'
interface ButtonProps {
  onClick: () => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  className?: string; // Optional for additional styling
  children: React.ReactNode; // Use children for button content
  variant?: 'primary' | 'secondary' | 'danger'; // New variant prop
  width?: string;
}

const StyledButton = styled.button<ButtonProps>`
display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, color 0.3s ease-in-out;
    border: 1px solid #3d2224;
    box-shadow: 0 0 8px 7px #0000003b inset;
  gap: 8px;
     /* font related */
    color: #c1b197 !important;
    font-weight: 700;
    user-select: none;
    margin: 0;
    text-align: center;
    font-size: 1.4rem;
  width: ${(props) => props.width ? props.width : ''};

    background-color: ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return '#311414';
      case 'secondary':
        return '#311414';
      case 'danger':
        return '#311414';
      default:
        return '#311414'; // Default or fallback color
    }
  }};

  color: ${({ variant }) => variant === 'secondary' ? 'black' : 'white'};

  &:hover {
    background-color: ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return '#3f1616';
      case 'secondary':
        return '#3f1616';
      case 'danger':
        return '#3f1616';
      default:
        return '#3f1616';
    }
  }};
    border: 1px solid ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return '#6a393d';
      case 'secondary':
        return '#6a393d';
      case 'danger':
        return '#6a393d';
      default:
        return '#6a393d';
    }
  }};

      color: ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return '#efb754';
      case 'secondary':
        return '#efb754';
      case 'danger':
        return '#efb754';
      default:
        return '#efb754';
    }
  }};
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;


// Button component updated to use children for content
const Button: React.FC<ButtonProps> = ({ onClick, type = "button", disabled = false, className, children, width }) => (
  <StyledButton className={className} onClick={onClick} type={type} width={width} disabled={disabled}>
    {children}
  </StyledButton>
);

export default Button;
