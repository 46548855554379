import { useState } from "react";
import styled from 'styled-components';
import WarningMessage from "../../components/WarningMessage";

import { useFirebase } from '../../contexts/Firebase/Firebase';
import MainContainer from "../../components/MainContainer";
import InputfieldNew from "../../components/InputfieldNew";
import Button from "../../components/Button";
import AuthFooter from "../../components/AuthFooter";

const ForgotPassword: React.FC = () => {
  const { auth, sendPasswordResetEmail } = useFirebase();
  const [email, setEmail] = useState<string>("");
  const [notice, setNotice] = useState<string>("");

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    sendPasswordResetEmail(auth, email).then(data => {
      setNotice("Check your email to reset your password")
    }).catch(error=> {
      setNotice(error.code)
    })
  }

  return (
    <OuterContainer>
    <MainContainerWrapper>
      <MainContainerContent>
        <AppTitleContainer>
          <h1>Alerith</h1>
        </AppTitleContainer>
        <MainContainer title={'Forgot Password'} type={'other'}>
          <MainContainerInner>
            <h2>Reset your password now</h2>
            <p>Welcome! Please enter your email.</p>
            <InputfieldContainer>
            {notice && <WarningMessage message={notice} />}
              <div className='wrapper_inputfield'>
                <div>
                  <label htmlFor='inputfield_email'>Email</label>
                </div>
                <InputfieldNew
                  type="text"
                  id="inputfield_email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={onChangeEmail}
                />
              </div>
            </InputfieldContainer>
            <ButtonContainer>
              <Button onClick={handleSubmit}>Reset your password</Button>
            </ButtonContainer>
          </MainContainerInner>
        </MainContainer>
      </MainContainerContent>
    </MainContainerWrapper>
    <AuthFooter text="Already have an account?" url_text="Sign In!" url_link="/signin" />

    </OuterContainer>
  );
};


const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
`

const ButtonContainer = styled.div`
  margin-top: 16px;
  width: 100%;

  > button {
    width: 100%;
  }
`

const MainContainerInner = styled.div`
  width: 100%;

  h2 {
    margin: 0;
    color: #c1b197;
  }

  p {
    margin: 8px 0;
  }
`

const InputfieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  
`

const MainContainerContent = styled.div`
display: flex;
flex-direction: column;
align-items: center;

  > #main__container {
    max-width: 500px;
  }
  
  
 .main__container__content {
    padding: 16px;
    box-shadow: 0 0 9px 4px #0000008f;
  }
`

const AppTitleContainer = styled.div`
padding: 40px;
text-align: center;

  h1 {
    font-size: 5rem;
    color: #c1b197;
    text-shadow: 0 0 20px black;
    margin: 0;
  }
`

const MainContainerWrapper = styled.div`
  position: fixed;
  width: calc(100% - 16px);
  height: calc(100% - 16px - 50px);
  padding: 8px;
  box-shadow: 0 0 20px 4px inset #0000008f;

`

export const FlexContainerHorizontallyCentered = styled.div`

`


export default ForgotPassword;