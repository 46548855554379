import React from 'react';
import OrnamentInside from '../../assets/input_field/InputField_OrnamentInside.png';
import OrnamentOutside from '../../assets/input_field/InputField_OrnamentOutside.png'
import './InputfieldNew.css'

interface InputfieldNewProps {
  type: string;
  id: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputfieldNew: React.FC<InputfieldNewProps> = ({ type, id, placeholder, value, onChange }) => {
  return (
      <div className='inner_inputfield'>
        <img src={OrnamentOutside} alt="Ornament" className="ornament" />
        <img src={OrnamentOutside} alt="Ornament" className="ornament" />
        <input
          type={type}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
        <img src={OrnamentInside} alt="Ornament" className="ornamentInside" />
        <img src={OrnamentInside} alt="Ornament" className="ornamentInside" />
      </div>
  );
};

export default InputfieldNew;