import { QuestStoryTypeEnum } from "../enums/QuestStoryType";
import { QuestObject } from "../interfaces/quest";

export const questObjectTemplate: QuestObject = {
    uid: "",
    id: "0", 
    title: '',
    description: '',
    type: QuestStoryTypeEnum.MAIN_STORY,
    requirements: {},
    objectives: {},
    rewards: {}
  };