import React, { useContext, useState } from 'react'
import './NavigationSideBar.css'
import { Context as NavContext } from '../../contexts/NavigationSideBar/NavigationSideBar'
import { Context as ViewportProviderContext } from '../../contexts/ViewportProvider'
import styled from 'styled-components'
import SvgIcon from '../SvgIcon'
import MenuOrnament from '../../assets/top_ornament.png'
import { FirebaseContext } from '../../contexts/Firebase/Firebase'
import AccordionMenu from '../AccordionMenu'
import AvatarImage from '../../assets/avatar/avatar_hero_default.png'
import { Link } from 'react-router-dom'
import ArrowSvgIcon from '../ArrowSvgIcon'
import Button from '../Button'
import useSignOut from '../../hooks/firebase/useSignOut'

const NavigationSideBar: React.FC = () => {
  const { navigationBarToggled, toggleNavigationBar } = useContext(NavContext)
  const { width } = useContext(ViewportProviderContext)
  const signOutAndNavigateHome = useSignOut();
  const [navToggledOnWidth, setNavToggledOnWidth] = useState(width < 786 ? false : true)

  const [isOpen, setIsOpen] = useState(false);

  const firebaseContext = useContext(FirebaseContext);

  if (!firebaseContext) {
    console.error('FirebaseContext not available');
    return null;
  }

  const { user } = firebaseContext;

  const toggleUserMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ContainerHeader>
        <InnerHeader>
          <ButtonHeader onClick={() => { toggleNavigationBar(); setNavToggledOnWidth(width < 786 ? false : true); }}>
            <HeaderIcon width={32} height={32} d={`M4 18H15C15.55 18 16 17.55 16 17C16 16.45 15.55 16 15 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H12C12.55 13 13 12.55 13 12C13 11.45 12.55 11 12 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H15C15.55 8 16 7.55 16 7C16 6.45 15.55 6 15 6H4C3.45 6 3 6.45 3 7ZM20.3 14.88L17.42 12L20.3 9.12C20.69 8.73 20.69 8.1 20.3 7.71C19.91 7.32 19.28 7.32 18.89 7.71L15.3 11.3C14.91 11.69 14.91 12.32 15.3 12.71L18.89 16.3C19.28 16.69 19.91 16.69 20.3 16.3C20.68 15.91 20.69 15.27 20.3 14.88Z`} />
          </ButtonHeader>
          <WrapperHeader role="button" >
            {/*<img src={LogoRSGP} />*/}
            <h1>Alerith</h1>
          </WrapperHeader>
        </InnerHeader>
        {user && (
          <ContainerUser>
            {isOpen ? (


              <UserMenuOptionsContainer >

                <Button onClick={signOutAndNavigateHome}>Sign out</Button>
                <Button onClick={toggleUserMenu}>Close</Button>

              </UserMenuOptionsContainer>
            ) : (
              <div onClick={toggleUserMenu}>

                <ContainerAvatarFrame>
                  <div>
                    <img src={AvatarImage} />
                  </div>
                  <div>
                    <span>{user.display_name}</span>
                    <span>{user.rank}</span>
                  </div>
                  <div>
                    <ArrowSvgIcon direction={isOpen && isOpen ? 'down' : 'up'} size={15} fillColor={'#8b7e6e'} />
                  </div>
                </ContainerAvatarFrame>
              </div>
            )}

          </ContainerUser>
        )}
      </ContainerHeader>
      <StyledNavigationSidebar toggle={navigationBarToggled}>
        <NavWrapperContainer>
          <ContainerNavItems>
            <img src={MenuOrnament} className="ornament_menu" />
            <img src={MenuOrnament} className="ornament_menu" />
            <MenuWrapper>
              <MenuGroup>
                <ul className="nano-content">
                  <li>
                    <Link to="/dashboard/quests/overview"><i className="fa fa-dashboard"></i><span>Dashboard</span></Link>
                  </li>
                  {user?.rank == 'administrator' && (
                    <AccordionMenu
                      accordionTitle='Official Quests'
                      subItems={[
                        { title: 'Official Quests', link: '/dashboard/quests/official_quests' },
                        { title: 'Official Story Lines', link: '/dashboard/quests/official_quest_story_lines' }
                      ]}
                    />
                  )}
                  <AccordionMenu
                    accordionTitle='Your Quests'
                    subItems={[
                      { title: 'View Your Quest List', link: '/dashboard/quests/your_quests' },
                      { title: 'View Your Story Line List', link: '/dashboard/quests/your_story_lines' }
                    ]}
                  />
                  {user?.rank == 'administrator' && (
                    <AccordionMenu
                      accordionTitle='Quest Management'
                      subItems={[
                        { title: 'Edit/View Users', link: '/dashboard/admin/edit_user' },
                        { title: 'Edit/View Official Quests', link: '/dashboard/quests/quest_list' },
                        { title: 'Edit/View Official Story Line', link: '/dashboard/quests/quest_list' },
                        { title: 'Create Official Quest', link: '/dashboard/quests/quest_list' },
                        { title: 'Create Official Story Lines', link: '/dashboard/quests/official_quest_story_lines' },
                        { title: 'Review Community Quests', link: '/dashboard/quests/quest_list' }
                      ]}
                    />
                  )}
                </ul>
              </MenuGroup>
            </MenuWrapper>
          </ContainerNavItems>
        </NavWrapperContainer>
      </StyledNavigationSidebar>
    </>
  )
}

const UserMenuOptionsContainer = styled.div`

  width: 100%;
  height: 100%;
  background:red;
  z-index: 300;
  text-align: center;
  height: 100%;
  box-shadow: 0 0 6px 6px inset #00000059;
  background: rgba(43, 20, 20, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 8px;
  button {
    width: 100%;
  }
`

const ContainerUser = styled.div`
  display: flex;
  align-items: center;
  border-left: 1px solid #36312a;
  height: 100%;
  box-shadow: 0 0 6px 6px inset #00000059;
  margin-right: 8px;
  background: rgba(43, 20, 20, 1);
  min-width: 160px;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
`

const ContainerAvatarFrame = styled.div`
  display: flex;
  gap: 8px;
  margin-right: 8px;
  > div:nth-child(1) {
    background: #181413;
    padding: 4px;
    border: 1px solid #36312a;
    box-shadow: 0 0 12px 5px #0000008a inset;

    img {
      width: 20px;
      height: 20px;
    }
  }

  > div:nth-child(2) {
    justify-content: center;
    display: flex;
    flex-direction: column;

    span {
      color: #706a62;
      font-family: unset;
    }

    span:nth-child(1) {
      font-size: 1.3rem
    }

    span:nth-child(2) {
      font-size: 1.1rem;
    }
  }

  > div:nth-child(3) {
    display: flex;
    align-items: center;
  }
`

const StyledNavigationSidebar = styled.div<{ toggle: boolean }>`
  position: fixed;
  min-height: 100vh;
  background: #201c19;
  border-right: 1px solid #39312a;
  overflow-y: auto;
  z-index: 5;
  transition: margin .2s ease-in-out, width 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;

  /* might need to change this later */
  margin-top: var(--header-top-size-height);

  overflow: ${(props) => props.toggle ? 'hidden' : 'initial'};
  white-space: nowrap;
  /*@media (max-width: 769px) {*/
    margin-left: ${(props) => props.toggle ? 0 : -200}px;
  /*}*/

  @media only screen and (min-width: 968px) {
    width: ${(props) => props.toggle ? 200 : 63}px;
  }
`

const NavWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - var(--header-top-size-height));
`

const ContainerHeader = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  background-color: #141210;
  border-bottom: #463e32;
  /*padding: 20px 20px 20px 8px;*/
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #463e32;
  align-items: center;
  padding-left: 8px;
  padding-right: 16px;
  width: 100%;
height: var(--header-top-size-height);
  z-index: 10;

      background: rgb(68,22,22);
    background: linear-gradient(90deg, rgba(68,22,22,1) 0%, rgba(20,16,17,1) 35%, rgba(20,16,17,1) 65%, rgba(68,22,22,1) 100%);
    background: linear-gradient(90deg, rgb(27 18 17) 0%, rgba(17,16,15,1) 30%, rgba(17,16,15,1) 70%, rgb(27 18 17) 100%);
    box-shadow: inset 0 4px 10px 3px rgb(0 0 0 / 30%);
`

const InnerHeader = styled.div`
  display: flex;
  align-items: center;
`

const ButtonHeader = styled.div`
  align-items: center; 
  border: 0px;
  border-radius: 16px;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: none;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 48px;
  padding: 0px 8px;
  background-color: transparent;
  color: rgb(31, 199, 212);
  box-shadow: none;
  margin-right: 12px;
  
  & button:active,
  button:focus,
  button:focus:active {
    background-image: none;
    outline: 0;
    border: none;
    box-shadow: none;
    color: red;
  }

  input[type=button] {
    outline: none !important;
    box-shadow: none !important;
  } 

  @media only screen and (min-width: 480px) {
    margin-right: 8px;
  }
`

const HeaderIcon = styled(SvgIcon)`
  color: #f19613;
`

const WrapperHeader = styled.a`
  text-align: center;

  & img {
    width: 110px;
    height: auto;
  }

  & h1 {
    font-size: 2.2rem;
    margin: 0;
  }
`

const ContainerNavItems = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    /* New part added, might need to be resolved to a different form of CSS */
    height: 100%;

    background: rgb(55,20,18);

    background: linear-gradient(0deg, rgb(27 18 17) 0%, rgba(17,16,15,1) 30%, rgba(17,16,15,1) 70%, rgb(27 18 17) 100%);

    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#371412",endColorstr="#371412",GradientType=1);
    box-shadow: 0px 0px 20px 4px #080808bf inset;


    img.ornament_menu {
        position: absolute;
        filter: brightness(11%);
        width: 200px;
        height: auto;
    }

    img.ornament_menu:nth-child(1) {
        top: 5px;
        left: 0;
    }

    img.ornament_menu:nth-child(2) {
        bottom: 5px;
        left: 0;
        transform: scaleY(-1);
    }
`

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  z-index: 20;
    position: relative;
`

const MenuGroup = styled.div`
  /*width: 240px;
  padding: 0 20px 20px;*/

  & aside {
    & ul {
      & li {
        padding: 10px;

      }
    }
  }
`

export default NavigationSideBar