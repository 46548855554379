import { Outlet } from "react-router-dom";
import styled from 'styled-components';

const Layout: React.FC = () => {
    return (
        <BackgroundContainer>
            <Outlet />
        </BackgroundContainer>
    );
};

const BackgroundContainer = styled.div`
    position: relative;    
    z-index: 1;   
    height: 100%;
    width: 100%;
    /* might need to change this later */
    overflow: auto;
`;

export default Layout;