export const QUEST_TITLE_CHARACTERS_MIN: number = 5;
export const QUEST_TITLE_CHARACTERS_MAX: number = 50;

export const QUEST_DESCRIPTION_CHARACTERS_MIN: number = 5;
export const QUEST_DESCRIPTION_CHARACTERS_MAX: number = 3000;

export const QUEST_REQUIREMENTS_MIN: number = 0;
export const QUEST_REQUIREMENTS_MAX: number = 10;

export const QUEST_OBJECTIVES_MIN: number = 1;
export const QUEST_OBJECTIVES_MAX: number = 10;

export const COLLECT_ITEM_NAME_LENGTH_MIN: number = 3;
export const COLLECT_ITEM_NAME_LENGTH_MAX: number = 50;
export const COLLECT_ITEM_QUANTITY_MIN: number = 1;
export const COLLECT_ITEM_QUANTITY_MAX: number = 30;

export const SLAY_CREATURE_NAME_LENGTH_MIN: number = 3;
export const SLAY_CREATURE_NAME_LENGTH_MAX: number = 50;
export const SLAY_CREATURE_QUANTITY_MIN: number = 1;
export const SLAY_CREATURE_QUANTITY_MAX: number = 30;

export const ESCORT_NPC_NAME_LENGTH_MIN: number = 3;
export const ESCORT_NPC_NAME_LENGTH_MAX: number = 50;

export const TALK_WITH_NPC_NAME_LENGTH_MIN: number = 3;
export const TALK_WITH_NPC_NAME_LENGTH_MAX: number = 50;

export const QUEST_REWARDS_MIN: number = 1;
export const QUEST_REWARDS_MAX: number = 10;

export const QUEST_REQUIRED_PROFESSION_LEVEL_MIN: number = 1;
export const QUEST_REQUIRED_PROFESSION_LEVEL_MAX: number = 100;

export const REWARD_CURRENCY_COPPER_MIN = 0;
export const REWARD_CURRENCY_COPPER_MAX = 99;

export const REWARD_CURRENCY_SILVER_MIN = 0;
export const REWARD_CURRENCY_SILVER_MAX = 99;

export const REWARD_CURRENCY_GOLD_MIN = 0;
export const REWARD_CURRENCY_GOLD_MAX = 250;

export const REWARD_ITEM_NAME_LENGTH_MIN = 3;
export const REWARD_ITEM_NAME_LENGTH_MAX = 50;

export const REWARD_ITEM_QUANTITY_MIN = 1;
export const REWARD_ITEM_QUANTITY_MAX = 50;

export const REWARD_PROFESSION_EXPERIENCE_MIN = 1;
export const REWARD_PROFESSION_EXPERIENCE_MAX = 100000;

