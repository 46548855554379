import React, { useEffect, useState } from 'react'
import { useAlerith } from '../../../../../contexts/Alerith/Alerith'
import MainContainer from '../../../../../components/MainContainer'
import styled from 'styled-components'
import { QuestObject } from '../../../../../components/quest/interfaces/quest'
import './OfficialQuests.css'
import ListviewContainer from '../../../../../components/ListviewContainer'
import Button from '../../../../../components/Button'
import { questObjectTemplate } from '../../../../../components/quest/constants'
import ViewEditQuest from '../../../../../components/quest/components/QuestComponents/ViewEditQuest'

const YourQuests: React.FC = () => {
    const [userQuests, setUserQuests] = useState<QuestObject[]>([])
    const [selectedQuest, setSelectedQuest] = useState<QuestObject>(questObjectTemplate);
    const [isEditingStory, setIsEditingStory] = useState<boolean>(false)
    const [modificationType, setModificationType] = useState<"create" | "edit" | "view" | undefined>(undefined);
    const {userPersonalQuests} = useAlerith();

    useEffect(() => {
        if (userPersonalQuests) {
            setUserQuests(userPersonalQuests)
        }
    }, [userPersonalQuests]);

    const modificationButtonHandler = (type: string, QuestObject: QuestObject | undefined = undefined) => {
        switch (type) {
            case "create":
                setSelectedQuest(questObjectTemplate);
                setModificationType("create");
                setIsEditingStory(true);
                break;
            case "edit":
                setModificationType("edit");
                setIsEditingStory(true);
                break;
            case "view":
                if (QuestObject !== undefined) {
                    setSelectedQuest(QuestObject);
                    setModificationType("view");
                    setIsEditingStory(false);
                } else {
                    setModificationType(undefined);
                    setIsEditingStory(false);
                    setSelectedQuest(questObjectTemplate);
                }
                break;
            case "unset":
                setSelectedQuest(questObjectTemplate);
                setModificationType(undefined);
                setIsEditingStory(false);
                break;
            default:
                break;
        }
    }

    const handleSelectStoryline = (quest: QuestObject) => {
        modificationButtonHandler("view", quest)
    };


    const createStorylineButtonIsDisabled = () => {
        return isEditingStory && modificationType != undefined;
    }

    const ShortenTypeQuest = (typeQuest: string) => {
        if (typeQuest == "Main Story")
            return "Main";
        else if (typeQuest == "Side Story") {
            return "Side"
        }
    }

    return (
        <MainContainer title="Your quest list">
            <Container>
                <ListviewContainer<QuestObject>
                    items={userQuests}
                    isEditing={isEditingStory}
                    selectedStoryline={selectedQuest}
                    handleSelectStoryline={handleSelectStoryline}
                    renderItem={(quest, isSelected) => (
                        <React.Fragment>
                            <span>{quest.title}</span>
                            <span>{ShortenTypeQuest(quest.type)}</span>
                        </React.Fragment>
                    )}
                    renderButton={
                        <React.Fragment>
                            <Button disabled={createStorylineButtonIsDisabled()} onClick={() => modificationButtonHandler("create")}>
                                Create New Quest
                            </Button>
                        </React.Fragment>
                    }
                />
                <ContainerQuestData>
                    {selectedQuest !== undefined && modificationType !== undefined ? (
                        <>
                            <ViewEditQuest
                                canEditQuest={isEditingStory}
                                questObject={selectedQuest}
                                setSelectedQuestObject={setSelectedQuest}
                                modificationType={modificationType}
                                modificationButtonHandler={modificationButtonHandler}
                                setUserQuests={setUserQuests}
                            />
                        </>
                    )
                        :
                        (
                            <InformationContainer>
                                <h1>Accessing Quest Information: A Step-by-Step guide</h1>
                                <p>
                                    To access detailed information about a quest—including its title, description, necessary requirements, objectives, and rewards—please begin by selecting a quest from the list located on the left side of the screen. Simply click on the quest you're interested in to view all related details.
                                </p>
                            </InformationContainer>
                        )}
                </ContainerQuestData>
            </Container>
        </MainContainer>
    )
}

const InformationContainer = styled.div`
h1 {
    color: #c1b197 !important;
    margin: 0;
    margin-bottom: 10px;
  }


  p {
    color: #8b7e6e !important;
    margin: 0;
  }
`

const ContainerQuestData = styled.div`
    flex: 1;
    box-shadow: 0px 0px 20px 4px #080808bf inset;
    
    max-height: inherit;
    overflow-y: auto;
        padding: 8px;
    > span {
        color: white;
    }

    h1,h2,h3,h4,h5,p,span{
        color: white;
    }
`

const Container = styled.div`
    display: flex;
    height: 100%;
    max-height: inherit;
    flex: 1;
`

export default YourQuests