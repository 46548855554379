import React, { useContext } from 'react';
import MainContainer from '../../components/MainContainer';
import TextContainer from '../../components/TextContainer';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../components/Button';
import { FirebaseContext } from '../../contexts/Firebase/Firebase';

const AuthenticationCheck: React.FC<{ secRemaining: number, isAuthenticated: boolean, checkingStatus: boolean, setReadyToNavigate: (value: boolean) => void }> = ({ isAuthenticated, secRemaining, setReadyToNavigate }) => {
    const navigate = useNavigate();
    const navigationUrl = isAuthenticated ? '/dashboard/quests' : '/signin';

    const firebaseContext = useContext(FirebaseContext);
    //const [message, setMessage] = useState("Hmm, it looks like you're wandering around as a guest.");
    if (!firebaseContext) {
      console.error('FirebaseContext not available');
      return null;
    }

    const { authCheckType, authMessage } = firebaseContext;

    const btnHandler = () => {
        setReadyToNavigate(true);
        navigate(navigationUrl)
    }

    let headerTitle = "Processing your request"
    if (authCheckType === -1) {
        headerTitle = "Please sign in"
    } else if (authCheckType === 0) {
        headerTitle = "You are already signed in"
        
    } else if (authCheckType === 1) {
        headerTitle = "You are being signed in"
        
    } else if (authCheckType === 2) {
        headerTitle = "You are being signed out"
    }
    
    return (
        <ContainerWrapper>
            <MainContainer title={headerTitle} type='other'>
                <MainContainerInner>
                    <TextContainer>
                        <TextContainerInner>
                            <h1>{authMessage}</h1>
                            <p>Redirecting to {isAuthenticated ? "dashboard" : "sign in"} in {secRemaining} seconds...</p>
                            {/* Button for immediate navigation */}
                            <div>
                                <Button onClick={btnHandler}>Continue to {isAuthenticated ? "dashboard" : "sign in"}</Button>
                            </div>
                        </TextContainerInner>
                    </TextContainer>
                </MainContainerInner>
            </MainContainer>
        </ContainerWrapper>
    );
};

const TextContainerInner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    h1, p {
        margin: 0;
    }
`

const MainContainerInner = styled.div`
    width: 100%;
    padding: 16px;
    text-align: center;

    a {
        cursor: pointer;
    }
`

const ContainerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    > div {
        width: 600px;
    }
`

export default AuthenticationCheck;