import 
{ 
    QuestRewardCurrency,
    QuestRewardExperience,
    QuestRewardItem
} from '../interfaces/quest';
import { QuestRewardsEnum } from "../enums/QuestRewardsEnum"

export const templateQuestRewardCurrency: QuestRewardCurrency = {
    type: QuestRewardsEnum.CURRENCY_REWARD,
    copper: 0,
    silver: 0,
    gold: 0,
    id: 0,
    type_count: 0
}

export const templateQuestRewardExperience: QuestRewardExperience = {
    type: QuestRewardsEnum.XP_REWARD,
    profession_id: 0,
    profession_name: "",
    profession_experience: 0,
    id: 0,
    type_count: 0
}

export const templateQuestRewardItem: QuestRewardItem = {
    type: QuestRewardsEnum.ITEM_REWARD,
    item_id: 0,
    item_name: "",
    item_quantity: 0,
    id: 0,
    type_count: 0
}
