import { useContext } from 'react';
import { signOut } from 'firebase/auth';
import { FirebaseContext } from '../../contexts/Firebase/Firebase';
import { eventEmitter } from '../../components/eventMitter/eventMitter';
import { AUTH_MESSAGE_SIGNING_OUT } from '../../constants/authMessage';

const useSignOut = () => {
    // This line doesn't conditionally call useContext anymore
    const firebaseContext = useContext(FirebaseContext);

    // If firebaseContext is not available, it's better to handle it gracefully
    // within the useEffect or by throwing an error or logging. Not returning null from a hook.
    if (!firebaseContext) {
        console.error('FirebaseContext not available');
    }

    const { auth, setUser, setAuthCheckType, setAuthMessage } = firebaseContext || {};

    const signOutAndNavigateHome = async () => {
        try {
            if (auth) {
                await signOut(auth);
                if (setUser) {
                    if (setAuthCheckType && setAuthMessage) {
                        eventEmitter.emit("readyToNavigate", true)
                        setAuthCheckType(2)
                        setAuthMessage(AUTH_MESSAGE_SIGNING_OUT)
                        setUser(null);
                        localStorage.clear()
                    }
                }
            }
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    return signOutAndNavigateHome;
};

export default useSignOut;