import React from 'react'
import styled, { css } from 'styled-components';
import GenericList from '../GenericListContainer';

interface ListviewContainerProps<T> {
    items: T[];
    isEditing: boolean;
    selectedStoryline: T | null;
    handleSelectStoryline: (item: T) => void;
    renderItem: (item: T, isSelected: boolean) => React.ReactNode;
    renderButton: React.ReactNode;
}

const ListviewContainer = <T extends { id: string }>({
    items,
    isEditing,
    selectedStoryline,
    handleSelectStoryline,
    renderItem,
    renderButton
}: ListviewContainerProps<T>): JSX.Element => {
    return (
        <QuestListViewContainer isEditingStory={isEditing}>
            <NewQuestButtonContainer>
                {renderButton}
            </NewQuestButtonContainer>
            <div>
                <QuestListContainer>
                    <GenericList<T>
                        items={items}
                        renderItem={renderItem}
                        getKey={(item) => item.id}
                        selected={selectedStoryline}
                        onSelect={handleSelectStoryline}
                    />
                </QuestListContainer>
            </div>
        </QuestListViewContainer>
    );
};


interface QuestListViewContainerProps {
    isEditingStory: boolean;
}

const QuestListViewContainer = styled.div<QuestListViewContainerProps>`
    border-right: 1px solid #2b2824;
    width: 25%;
    max-height: inherit;
    width: 280px;
    opacity: 1;
    transition: width 0.5s ease-in-out, opacity 0.5s ease-in-out;
    display: flex;
    flex-direction: column;

    ${props => props.isEditingStory && css`
        /* Add your conditional styles here */
        width: 0px;
        opacity: 0;
    `}

    > div:nth-child(2) {
        overflow-y: auto;
        flex: 1;
    }
`


const QuestListContainer = styled.div`

        overflow-y: auto;
        height: 100%;

    li {
        span {
            white-space: nowrap; /* Prevent text from wrapping */
            overflow: hidden; /* Hide any overflow text */
            text-overflow: ellipsis; /* Show ellipsis (...) if text overflows */
        }

        span:nth-child(1) {
            flex: 1; /* Expand span to fill remaining space */
        }
    }
`

const NewQuestButtonContainer = styled.div`
    padding: 8px;
    border-bottom: 1px solid #2b2824;
    box-shadow: -6px 0 9px 10px #0000003b inset;

    > button:disabled {
        background-color: #311414;
        border: 1px solid #3d2224;
        color: #c1b197 !important;
        cursor: default;
    }

    > button {
        white-space: nowrap; /* Prevent text from wrapping */
        overflow: hidden; /* Hide any overflow text */
        text-overflow: ellipsis; /* Show ellipsis (...) if text overflows */
        width: 100%;
    }

    > div {
        padding: 4px;
        background-color: #1b1111;
        border: 1px solid #3d2224;
        
        background-color: #311414;
        border: 1px solid #3d2224;
        box-shadow: 0 0 8px 7px #0000003b inset;

        transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;

        > h3 {
            color: #c1b197 !important;
            font-weight: 700;
            user-select: none;
            margin: 0;
            text-align: center;

            transition: color 0.2s ease-in-out;

            white-space: nowrap; /* Prevent text from wrapping */
            overflow: hidden; /* Hide any overflow text */
        text-overflow: ellipsis; /* Show ellipsis (...) if text overflows */

        }

        &:hover {
            cursor:pointer;
            background-color: #3f1616;
            border: 1px solid #6a393d;

            h3 {
                color: #efb754 !important;

            }
        }
    }
`

export default ListviewContainer;