import { QuestStoryLine, StorylineDetails } from "../../components/quest/interfaces/quest";
import { STORYLINE_DESCRIPTION_CHARACTERS_MAX, STORYLINE_DESCRIPTION_CHARACTERS_MIN, STORYLINE_TITLE_CHARACTERS_MAX, STORYLINE_TITLE_CHARACTERS_MIN } from "../../constants/quest/InputfieldQuestStorylineRules";
import { STORYLINE_DESCRIPTION_NOTICE, STORYLINE_NODE_VIEW_QUESTS_NOTICE, STORYLINE_TITLE_NOTICE } from "../../constants/quest/InputfieldStorylineNotice";

export const SanitizeUserInputForStorylineObject = (QuestStoryline: StorylineDetails): { id: string, message: string } | null => {
    if (QuestStoryline.title.length < STORYLINE_TITLE_CHARACTERS_MIN || QuestStoryline.title.length > STORYLINE_TITLE_CHARACTERS_MAX) {
        return {id: "storyline_title_section", message: STORYLINE_TITLE_NOTICE};
    }

    if (QuestStoryline.description.length < STORYLINE_DESCRIPTION_CHARACTERS_MIN || QuestStoryline.description.length > STORYLINE_DESCRIPTION_CHARACTERS_MAX) {
        return {id: "storyline_description_section", message: STORYLINE_DESCRIPTION_NOTICE};
    }

    if (Object.keys(QuestStoryline.story_quests).length <= 1) {
        return {id: "quest_node_view_section", message: STORYLINE_NODE_VIEW_QUESTS_NOTICE};
    }

    return null;
}