// Rename the object to avoid conflict
export const QuestStoryTypeObject = {
    MAIN_STORY_LINE: 'Main Story',
    SIDE_STORY_LINE: 'Side Story'
};

// Define the enum type
export enum QuestStoryTypeEnum {
    MAIN_STORY = 'Main Story',
    SIDE_STORY = 'Side Story'
};