import React from 'react'
import GemImage from '../../assets/ImportantMessage/ImportantMessage_Gem.png'
import './WarningMessage.css';

interface WarningMessageProps {
    message: string;
    className?: string;
}

const WarningMessage: React.FC<WarningMessageProps> = ({ message, className }) => {
    return (
        <div className="container_warning_message_wrapper animation">
            <div className={className ? "container_warning_message " + className : "container_warning_message"}>
                <h5 className='animationText'>WARNING!</h5>
                <p  className='animationText'>{message}</p>
                <img src={GemImage} />
            </div>
        </div>
    )
}

export default WarningMessage