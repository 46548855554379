export const QuestRewardItemsEnum = {
    SWORD_OF_DESTINY: "Sword of Destiny",
    SHIELD_OF_THE_REALM: "Shield of the Realm",
    ELVEN_BOW: "Elven Bow",
    ANCIENT_STAFF: "Ancient Staff",
    DRAGON_PLATE_ARMOR: "Dragon Plate Armor",
    WIZARD_HAT: "Wizard Hat",
    MYSTICAL_ROBE: "Mystical Robe",
    THIEF_DAGGER: "Thief Dagger",
    BATTLE_AXE: "Battle Axe",
    PALADIN_HAMMER: "Paladin Hammer",
    ORCISH_SHIELD: "Orcish Shield",
    MAGE_ROBES: "Mage Robes",
    ANCIENT_SWORD: "Ancient Sword",
    ELVEN_ARMOR: "Elven Armor",
    DRAGON_HELMET: "Dragon Helmet",
    WIZARD_STAFF: "Wizard Staff",
    SHADOW_CLOAK: "Shadow Cloak",
    ASSASSIN_DAGGER: "Assassin Dagger",
    WAR_HAMMER: "War Hammer",
    DIVINE_SHIELD: "Divine Shield",
    KNIGHT_SWORD: "Knight Sword",
    RANGER_BOW: "Ranger Bow",
    PLATE_ARMOR: "Plate Armor",
    NECROMANCER_ROBES: "Necromancer Robes",
    RUNE_SWORD: "Rune Sword",
    BLOODIED_BLADE: "Bloodied Blade",
    TITAN_ARMOR: "Titan Armor",
    ARCANE_STAFF: "Arcane Staff",
    FIERY_SWORD: "Fiery Sword",
    FROSTBITE_AXE: "Frostbite Axe",
    GOLDEN_HELMET: "Golden Helmet",
    SNEAKERS: "Sneakers",
    FLAME_CLOAK: "Flame Cloak",
    VENOMOUS_DAGGER: "Venomous Dagger",
    BATTLE_MACE: "Battle Mace",
    ANGELIC_WINGS: "Angelic Wings",
    SHADOW_BOW: "Shadow Bow",
    CRYSTAL_ARMOR: "Crystal Armor",
    WITCH_ROBES: "Witch Robes",
    DARK_SWORD: "Dark Sword",
    DEMONIC_SHIELD: "Demonic Shield",
    ELEMENTAL_WAND: "Elemental Wand",
    LIGHTNING_ROD: "Lightning Rod",
    MITHRIL_PLATE: "Mithril Plate",
    GHOSTLY_CLOAK: "Ghostly Cloak",
    POISONED_DAGGER: "Poisoned Dagger",
    EXECUTIONER_AXE: "Executioner Axe",
    PHOENIX_FEATHER: "Phoenix Feather",
    RAINBOW_SWORD: "Rainbow Sword",
    SHAMAN_STAFF: "Shaman Staff",
    SHADOW_BLADE: "Shadow Blade",
    FROST_ARMOR: "Frost Armor",
    NECRO_STAFF: "Necro Staff",
    TITANIUM_SWORD: "Titanium Sword",
    DRAGON_SCALE_ARMOR: "Dragon Scale Armor",
    WIZARD_ROBES: "Wizard Robes",
    HOLY_SWORD: "Holy Sword",
    ANCIENT_SHIELD: "Ancient Shield",
    ANGEL_WINGS: "Angel Wings",
    FROSTBITE_BOW: "Frostbite Bow",
    VAMPIRIC_ARMOR: "Vampiric Armor",
    DIVINE_STAFF: "Divine Staff",
    INFERNO_BLADE: "Inferno Blade",
    ELVEN_CLOAK: "Elven Cloak",
    MAGE_STAFF: "Mage Staff",
    GOLDEN_ARMOR: "Golden Armor",
    SHADOW_WAND: "Shadow Wand",
    DRAGON_TOOTH_SWORD: "Dragon Tooth Sword",
    TITANIUM_ARMOR: "Titanium Armor",
    PHOENIX_EGG: "Phoenix Egg",
    GHOSTLY_BLADE: "Ghostly Blade",
    DEMONIC_ARMOR: "Demonic Armor",
    ELEMENTAL_ROD: "Elemental Rod",
    MITHRIL_SWORD: "Mithril Sword",
    ANCIENT_BOOK: "Ancient Book",
    WIND_CLOAK: "Wind Cloak",
    BLOODTHIRSTY_AXE: "Bloodthirsty Axe",
    CRYSTAL_WAND: "Crystal Wand",
    FLAME_SWORD: "Flame Sword",
    SHAMAN_ROBES: "Shaman Robes",
    SILVER_BLADE: "Silver Blade",
    RAINBOW_ARMOR: "Rainbow Armor",
    PHOENIX_WINGS: "Phoenix Wings",
    DIVINE_BOW: "Divine Bow",
    ELVEN_STAFF: "Elven Staff",
    ICE_BLADE: "Ice Blade",
    DEMONIC_WAND: "Demonic Wand",
    ELEMENTAL_ARMOR: "Elemental Armor",
    VOID_STAFF: "Void Staff",
    ANCIENT_RING: "Ancient Ring",
    DRAGON_BLADE: "Dragon Blade",
    ANGELIC_ARMOR: "Angelic Armor",
    THUNDER_ROD: "Thunder Rod",
    TITANIUM_STAFF: "Titanium Staff",
    BERSERKER_AXE: "Berserker Axe",
    SHADOW_ARMOR: "Shadow Armor",
    WITCH_HAT: "Witch Hat",
    MAGE_CLOAK: "Mage Cloak",
    MOLTEN_SWORD: "Molten Sword",
    SPIRIT_STAFF: "Spirit Staff",
    WINTER_ROBES: "Winter Robes",
    FROSTBITE_SPEAR: "Frostbite Spear",
    SILVER_ARROW: "Silver Arrow",
    RAINBOW_WAND: "Rainbow Wand",
    NIGHTBLADE_BOW: "Nightblade Bow",
    DEATH_STAFF: "Death Staff",
    DIVINE_ARMOR: "Divine Armor",
    ELVEN_HELMET: "Elven Helmet",
    FIRE_BLADE: "Fire Blade",
    GRIFFIN_WINGS: "Griffin Wings",
    VOID_BLADE: "Void Blade",
    THUNDER_STAFF: "Thunder Staff",
    TITANIUM_SHIELD: "Titanium Shield",
    BLACK_KNIGHT_ARMOR: "Black Knight Armor",
    ANGELIC_BOW: "Angelic Bow",
    ANCIENT_AMULET: "Ancient Amulet",
    ICE_WAND: "Ice Wand",
    MAGE_HOOD: "Mage Hood",
    FROSTBITE_DAGGER: "Frostbite Dagger",
    DEMONIC_BLADE: "Demonic Blade",
    ELEMENTAL_SWORD: "Elemental Sword",
    VOID_WAND: "Void Wand",
    ANCIENT_CROWN: "Ancient Crown",
    DRAGON_HEART: "Dragon Heart",
    SHADOW_ARROW: "Shadow Arrow",
    HOLY_STAFF: "Holy Staff",
    TITANIUM_HELMET: "Titanium Helmet",
    SILVER_ARMOR: "Silver Armor",
    CRYSTAL_SWORD: "Crystal Sword",
    FLAME_WAND: "Flame Wand",
    SHAMAN_HAT: "Shaman Hat",
    VOID_ARMOR: "Void Armor",
    ANCIENT_SCEPTER: "Ancient Scepter",
    ELVEN_AMULET: "Elven Amulet",
    NIGHTBLADE_DAGGER: "Nightblade Dagger",
    THUNDER_HAMMER: "Thunder Hammer",
    TITANIUM_BOW: "Titanium Bow",
    DARK_ROBES: "Dark Robes",
    GHOSTLY_STAFF: "Ghostly Staff",
    PHOENIX_BLADE: "Phoenix Blade",
    SPIRIT_SHIELD: "Spirit Shield",
    WINTER_HOOD: "Winter Hood",
    FROSTBITE_WAND: "Frostbite Wand",
    ICE_ARMOR: "Ice Armor",
    FLAME_AXE: "Flame Axe",
    THUNDER_BOW: "Thunder Bow",
    ELVEN_RING: "Elven Ring",
    ANGELIC_WAND: "Angelic Wand",
    DEATH_SCYTHE: "Death Scythe",
    TITANIUM_SPEAR: "Titanium Spear",
    DEMONIC_STAFF: "Demonic Staff",
    ELEMENTAL_DAGGER: "Elemental Dagger",
    VOID_SWORD: "Void Sword",
    ANCIENT_BROOCH: "Ancient Brooch",
    DRAGON_SCALE: "Dragon Scale",
    HOLY_CROSSBOW: "Holy Crossbow",
    SILVER_STAFF: "Silver Staff",
    CRYSTAL_BLADE: "Crystal Blade",
    FLAME_SPEAR: "Flame Spear",
    SHAMAN_NECKLACE: "Shaman Necklace",
    ANCIENT_PENDANT: "Ancient Pendant",
    ELVEN_DAGGER: "Elven Dagger",
    NIGHTBLADE_SWORD: "Nightblade Sword",
    THUNDER_AXE: "Thunder Axe",
    TITANIUM_DAGGER: "Titanium Dagger",
    DARK_CLOAK: "Dark Cloak",
    GHOSTLY_SCYTHE: "Ghostly Scythe",
    PHOENIX_BOW: "Phoenix Bow",
    SPIRIT_WAND: "Spirit Wand",
    WINTER_BOOTS: "Winter Boots",
    FROSTBITE_BLADE: "Frostbite Blade",
    ANCIENT_TALISMAN: "Ancient Talisman",
    ICE_SPEAR: "Ice Spear",
    SHADOW_STAFF: "Shadow Staff",
    FLAME_DAGGER: "Flame Dagger",
    THUNDER_SWORD: "Thunder Sword",
    DEATH_SCEPTER: "Death Scepter",
    TITANIUM_SCYTHE: "Titanium Scythe",
    DEMONIC_SWORD: "Demonic Sword",
    ELEMENTAL_BOW: "Elemental Bow",
    VOID_SCYTHE: "Void Scythe",
    ANCIENT_MEDALLION: "Ancient Medallion",
    DRAGON_AMULET: "Dragon Amulet",
    HOLY_WAND: "Holy Wand",
    SILVER_DAGGER: "Silver Dagger",
    CRYSTAL_STAFF: "Crystal Staff",
    FLAME_SCYTHE: "Flame Scythe",
    SHAMAN_RING: "Shaman Ring",
    VOID_SCEPTER: "Void Scepter",
    ELVEN_BLADE: "Elven Blade",
    NIGHTBLADE_STAFF: "Nightblade Staff",
    DARK_STAFF: "Dark Staff",
    GHOSTLY_SWORD: "Ghostly Sword",
    PHOENIX_DAGGER: "Phoenix Dagger",
    SPIRIT_BOW: "Spirit Bow",
    WINTER_CLOAK: "Winter Cloak",
    FROSTBITE_SCYTHE: "Frostbite Scythe",
    ANCIENT_CHARM: "Ancient Charm",
    ICE_STAFF: "Ice Staff",
    SHADOW_SCEPTER: "Shadow Scepter",
    FLAME_STAFF: "Flame Staff",
    THUNDER_SCYTHE: "Thunder Scythe",
    ELVEN_SCEPTER: "Elven Scepter",
    ANGELIC_SWORD: "Angelic Sword",
    DEATH_WAND: "Death Wand",
    TITANIUM_BLADE: "Titanium Blade",
    DEMONIC_AXE: "Demonic Axe",
    ELEMENTAL_SPEAR: "Elemental Spear",
    VOID_BOW: "Void Bow",
    ANCIENT_TOTEM: "Ancient Totem",
    DRAGON_ROD: "Dragon Rod",
    HOLY_SCYTHE: "Holy Scythe",
    SILVER_SCYTHE: "Silver Scythe",
    CRYSTAL_WARHAMMER: "Crystal Warhammer",
    FLAME_BOW: "Flame Bow",
    SHAMAN_STICK: "Shaman Stick",
    VOID_DAGGER: "Void Dagger",
    ANCIENT_SCROLL: "Ancient Scroll",
    ELVEN_SWORD: "Elven Sword",
    NIGHTBLADE_SCYTHE: "Nightblade Scythe",
    THUNDER_DAGGER: "Thunder Dagger",
    TITANIUM_WAND: "Titanium Wand",
    DARK_DAGGER: "Dark Dagger",
    GHOSTLY_SCEPTER: "Ghostly Scepter",
    PHOENIX_STAFF: "Phoenix Staff",
    SPIRIT_SWORD: "Spirit Sword",
    WINTER_GLOVES: "Winter Gloves",
    FROSTBITE_WARHAMMER: "Frostbite Warhammer",
    ANCIENT_TOME: "Ancient Tome",
    ICE_SCYTHE: "Ice Scythe",
    SHADOW_SWORD: "Shadow Sword",
    THUNDER_BLADE: "Thunder Blade",
    ELVEN_WAND: "Elven Wand",
    ANGELIC_WARHAMMER: "Angelic Warhammer",
    DEATH_SCIMITAR: "Death Scimitar",
    DEMONIC_WARHAMMER: "Demonic Warhammer",
    ANCIENT_ARTIFACT: "Ancient Artifact",
    DRAGON_CROWN: "Dragon Crown",
    HOLY_WARHAMMER: "Holy Warhammer",
    SILVER_WAND: "Silver Wand",
    CRYSTAL_SCYTHE: "Crystal Scythe",
    FLAME_SCEPTER: "Flame Scepter",
    SHAMAN_AXE: "Shaman Axe",
    ANCIENT_RELIC: "Ancient Relic",
    NIGHTBLADE_WAND: "Nightblade Wand",
    THUNDER_WARHAMMER: "Thunder Warhammer",
    TITANIUM_SCIMITAR: "Titanium Scimitar",
    GHOSTLY_WAND: "Ghostly Wand",
    PHOENIX_SCYTHE: "Phoenix Scythe",
    WINTER_SCYTHE: "Winter Scythe",
    FROSTBITE_SWORD: "Frostbite Sword",
    ANCIENT_ORB: "Ancient Orb",
    ICE_WARHAMMER: "Ice Warhammer",
    SHADOW_SPEAR: "Shadow Spear",
    FLAME_SCIMITAR: "Flame Scimitar",
    ELVEN_CROWN: "Elven Crown",
    ANGELIC_SCEPTER: "Angelic Scepter",
    DEATH_BOW: "Death Bow",
    TITANIUM_RELIC: "Titanium Relic",
    DEMONIC_SCYTHE: "Demonic Scythe",
    ELEMENTAL_WARHAMMER: "Elemental Warhammer",
    VOID_AXE: "Void Axe",
    ANCIENT_RUNE: "Ancient Rune",
    HOLY_SPEAR: "Holy Spear",
    SILVER_SCIMITAR: "Silver Scimitar",
    CRYSTAL_AXE: "Crystal Axe",
    FLAME_WARHAMMER: "Flame Warhammer",
    SHAMAN_WAND: "Shaman Wand",
    VOID_SCIMITAR: "Void Scimitar",
    ANCIENT_CRYSTAL: "Ancient Crystal",
    ELVEN_GAUNTLETS: "Elven Gauntlets",
    NIGHTBLADE_SCEPTER: "Nightblade Scepter",
    TITANIUM_ORB: "Titanium Orb",
    DARK_SCEPTER: "Dark Scepter",
    GHOSTLY_BOW: "Ghostly Bow",
    PHOENIX_WARHAMMER: "Phoenix Warhammer",
    SPIRIT_SCYTHE: "Spirit Scythe",
    WINTER_STAFF: "Winter Staff",
}
