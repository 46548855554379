import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  QuestObject,
  QuestRequirement,
} from '../interfaces/quest';

import QuestPropertySection from '../components/QuestPropertySection';
import QuestRequirementsEnum from '../enums/QuestRequirements';
import { templateQuestRequirementPredecessor, templateQuestRequirementProfessionLevel } from '../constants/QuestRequirementTemplates';
import QuestRequirementsContainer from '../components/QuestRequirementsContainer';
import WarningMessage from '../../WarningMessage';

const DEFAULT_SELECTED_REQUIREMENT = QuestRequirementsEnum.LEVEL_REQUIREMENT;


interface SectionQuestRequirementsProps {
  questObj: QuestObject;
  setQuestObj: React.Dispatch<React.SetStateAction<QuestObject>>;
  inputfieldNotice: { message: string, targetId: string }
  modificationType: "create" | "edit" | "view" | undefined
}

const SectionQuestRequirements: React.FC<SectionQuestRequirementsProps> = ({ questObj, setQuestObj, inputfieldNotice, modificationType }) => {
  const [selectedRequirement, setSelectedRequirement] = useState<string>(DEFAULT_SELECTED_REQUIREMENT);
  const [questRequirements, setQuestRequirements] = useState<QuestRequirement[]>([]);
  const [questRequirementTypeCount, setQuestRequirementTypeCount] = useState<number[]>([0, 0]);
  const [initialLoad, setInitialLoad] = useState(false)

  useEffect(() => {
    if (modificationType === "edit" && initialLoad === false) {
      let reqs = Object.values(questObj.requirements)
      let countProfLevels = 0;
      let countQuests = 0;

      for (let index = 0; index < reqs.length; index++) {
        switch (reqs[index].type) {
          case QuestRequirementsEnum.LEVEL_REQUIREMENT:
            countProfLevels++
            break;
          //case QuestRequirementsEnum.QUEST_PREDECESSOR:
          //  countQuests++
          //  break;
          default:
            break;
        }
      }
      setQuestRequirementTypeCount([countProfLevels, countQuests])
    }
    setInitialLoad(true)
  }, [modificationType, questObj]);

  const handleRemoveQuestRequirement = (id: number) => {
    // Filter out the removed reward
    const filteredRequirements = { ...questObj.requirements };
    delete filteredRequirements[id];

    const updatedTypeCount = [0, 0];

    let nextId = 0;


    // Update the IDs and type_reward_ids of the remaining rewards
    const filteredRequirementsWithAdjustedIds = Object.values(filteredRequirements).map((requirement, index) => {
      let typeRewardId = 0;

      requirement.id = nextId;
      nextId++

      switch (requirement.type) {
        case QuestRequirementsEnum.LEVEL_REQUIREMENT:
          typeRewardId = updatedTypeCount[0];
          requirement.type_count = updatedTypeCount[0]
          updatedTypeCount[0]++;
          break;
        //case QuestRequirementsEnum.QUEST_PREDECESSOR:
        //  typeRewardId = updatedTypeCount[1];
        //  requirement.type_count = updatedTypeCount[1]
//
        //  updatedTypeCount[1]++;
        //  break;
        default:
          break;
      }

      return {
        ...requirement
      };
    });

    // Update the count of the quest reward types
    setQuestRequirementTypeCount(updatedTypeCount);

    // Update the quest rewards state
    setQuestObj((prevQuestObj) => ({
      ...prevQuestObj,
      requirements: filteredRequirementsWithAdjustedIds,
    }));
  };

  const handleAddQuestRequirement = () => {
    if (!selectedRequirement) {
      return;
    }

    let nextId = questRequirementTypeCount.reduce((acc, count) => acc + count, 0);
    let newQuestRequirement: QuestRequirement;

    switch (selectedRequirement) {
      case QuestRequirementsEnum.LEVEL_REQUIREMENT:
        setQuestRequirementTypeCount((prevCount) => [
          prevCount[0] + 1,
          prevCount[1],
        ]);

        let levelRequirementTemplate = templateQuestRequirementProfessionLevel;
        levelRequirementTemplate.type_count = questRequirementTypeCount[0];
        levelRequirementTemplate.id = nextId;

        newQuestRequirement = {
          ...levelRequirementTemplate,
        };
        break;
      //case QuestRequirementsEnum.QUEST_PREDECESSOR:
      //  setQuestRequirementTypeCount((prevCount) => [
      //    prevCount[0],
      //    prevCount[1] + 1
      //  ]);
//
      //  let predecessorTemplate = templateQuestRequirementPredecessor;
      //  predecessorTemplate.type_count = questRequirementTypeCount[1];
      //  predecessorTemplate.id = nextId;
//
      //  newQuestRequirement = {
      //    ...predecessorTemplate,
      //  };
      //  break;
      default:
        newQuestRequirement = { id: nextId, type_count: -1, type: "UNDEFINED?!" };
        break;
    }

    setQuestRequirements([...questRequirements, newQuestRequirement]);

    setQuestObj((prevQuestObj) => ({
      ...prevQuestObj,
      requirements: {
        ...prevQuestObj.requirements,
        [nextId]: newQuestRequirement,
      },
    }));
  };

  return (
    <>
      {inputfieldNotice.targetId === "quest_requirements_section" && <WarningMessage message={inputfieldNotice.message} />}
      <QuestPropertySection
        title={"Requirements"}
        htmlId={"quest_requirements_section"}
        selectedValue={selectedRequirement}
        options={Object.values(QuestRequirementsEnum)}
        onSelectedChange={setSelectedRequirement}
        onAdd={handleAddQuestRequirement}
        propertyType='Requirement'>
        <QuestRequirementsContainer
          questObj={questObj}
          setQuestObj={setQuestObj}
          handleRemoveQuestObjective={handleRemoveQuestRequirement}
          inputfieldNotice={inputfieldNotice}
        />
      </QuestPropertySection>
    </>
  );
};

export const SectionContainer = styled.div`

  h2 {
    margin: 0;
  }

  div:nth-child(1) {
    align-items: center;
  }

  > div {
    display: flex;
    justify-content: space-between;
      
    > div {
      display: flex;
          
      > div {
        min-width: 120px;
      }
      
      select {
        display: inline-block;
        height: 100%;
        margin-right: 8px;
        min-width: 164px;
        max-width: 164px;
      }
    }
  }
`;

export default SectionQuestRequirements;
