import React from 'react'
import styled from 'styled-components';
import InputfieldItem from '../../InputfieldItem';
import CloseButton from '../../CloseButton';
import {
QuestObject,
QuestProperty,
QuestRequirementPredecessor,
QuestRequirementProfessionLevel,
QuestObjectiveCollectItem,
QuestObjectiveEscortNpc,
QuestObjectiveSlayCreature,
QuestObjectiveTalkToNpc,
QuestRewardCurrency,
QuestRewardExperience,
QuestRewardItem
} from '../interfaces/quest'
import { ProfessionsEnum } from '../enums/ProfessionsEnum';
import SelectOption from '../../SelectOption';
import QuestObjectiveEnum from '../enums/QuestObjectiveEnum';
import { QuestRewardsEnum } from '../enums/QuestRewardsEnum';
import QuestRequirementsEnum from '../enums/QuestRequirements';

interface QuestPropertyProps {
    QuestProperty: QuestProperty
}

const QuestPropertyItem: React.FC<QuestPropertyProps> = ({ QuestProperty }) => {
    const isQuestRewardCurrency = ('copper' in QuestProperty) && ('silver' in QuestProperty) && ('gold' in QuestProperty);

    const renderInputs = () => {
        switch (QuestProperty.type) {
            case QuestRequirementsEnum.LEVEL_REQUIREMENT:
                return (
                    <>
                        <span>{(QuestProperty as QuestRequirementProfessionLevel).profession_name} level {(QuestProperty as QuestRequirementProfessionLevel).profession_level.toString()}</span>
                    </>
                );
            {/*case QuestRequirementsEnum.QUEST_PREDECESSOR:
                return (
                    <>
                        <span>{(QuestProperty as QuestRequirementPredecessor).quest_name.toString()}</span>
                    </>
                );*/}
            case QuestRewardsEnum.ITEM_REWARD:
                return (
                    <>
                        <span>{(QuestProperty as QuestRewardItem).item_quantity.toString()}x {(QuestProperty as QuestRewardItem).item_name}</span>
                    </>
                );
            case QuestRewardsEnum.CURRENCY_REWARD:
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div><span>Copper:</span><span>{(QuestProperty as QuestRewardCurrency).copper.toString()}</span></div>
                        <div><span>Silver:</span><span>{(QuestProperty as QuestRewardCurrency).silver.toString()}</span></div>
                        <div><span>Gold:</span><span>{(QuestProperty as QuestRewardCurrency).gold.toString()}</span></div>
                    </div>
                );
            case QuestRewardsEnum.XP_REWARD:
                return (
                    <>
                        <span>{(QuestProperty as QuestRewardExperience).profession_experience.toString()} {(QuestProperty as QuestRewardExperience).profession_name} experience</span>
                    </>
                );
            case QuestObjectiveEnum.SLAY_CREATURE:
                return (
                    <>
                        <span>Slay {(QuestProperty as QuestObjectiveSlayCreature).npc_kill_count.toString()}x {(QuestProperty as QuestObjectiveSlayCreature).npc_name}</span>
                    </>
                );
            case QuestObjectiveEnum.COLLECT_ITEM:
                return (
                    <>
                        <span>Collect {(QuestProperty as QuestObjectiveCollectItem).item_quantity.toString()}x {(QuestProperty as QuestObjectiveCollectItem).item_name.toString()}</span>
                    </>
                );
            case QuestObjectiveEnum.ESCORT_NPC:
                return (
                    <>
                        <span>Escort {(QuestProperty as QuestObjectiveEscortNpc).npc_name}</span>
                    </>
                );
            case QuestObjectiveEnum.TALK_WITH_NPC:
                return (
                    <>
                        <span>Talk with {(QuestProperty as QuestObjectiveTalkToNpc).npc_name}</span>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Container>
            {!isQuestRewardCurrency && (
                <div>
                    <span>{QuestProperty.type_count + 1}.</span>
                </div>
            )}
            {renderInputs()}
        </Container>
    )
}

export const Container = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;

    input {
        display: inline-block !important;
    }

    label {
        display: inline-block !important;
        margin-right: 8px;
    }

    div {
        display: inline-block;
    }

    > div:nth-child(2) {
        display: flex;
        width: 100%;
        margin-right: 8px;

    input {
        flex: auto;
        width: auto;
        }
    }

    > div:nth-child(3) {
        display: flex;

        input {
            width: 50px;
        }
    }

    > div:last-child {
        margin-left: auto;
    }
`;

export default QuestPropertyItem;