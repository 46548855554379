import React, { useState } from 'react';

interface SelectOptionProps {
    htmlId: string;
    htmlName: string;
    options: string[]; // Assuming options is an array of strings
    selected: string; // Assuming selected is a string
    onSelect: (value: string) => void; // Assuming onSelect is a function that takes a string parameter
    textValue?: string;
    style?: React.CSSProperties
  }

const SelectOption: React.FC<SelectOptionProps> = ({ htmlId, htmlName, options, selected, onSelect,textValue,style }) => {
    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value; // Get the selected value
        onSelect(selectedValue); 
      }

      const [isOpen, setIsOpen] = useState(false);

      const toggleDropdown = () => {
        setIsOpen(prevIsOpen => !prevIsOpen);
      };
      const selectClassName = isOpen ? "select_toggled" : "";


    return (
      <select id={htmlId} className={selectClassName} name={htmlName} value={selected} onChange={handleSelectChange} style={style} onClick={toggleDropdown}>
        {Object.values(options).map((option) => (
          <option key={option} value={option}>
            {textValue && (
              <span>{textValue}</span>
            )} {option}
          </option>
        ))}
      </select>
    );
};

export default SelectOption;