import { QuestObject, QuestStoryLine, StorylineDetails } from '../components/quest/interfaces/quest';

const isValidQuestObject = (obj: any): obj is QuestObject => {
    return 'id' in obj && 'title' in obj && 'description' in obj && 'type' in obj && 'requirements' in obj && 'objectives' in obj && 'rewards' in obj; // Add more checks as necessary
};

const isValidQuestStorylineObject = (obj: any): obj is StorylineDetails => {
    return 'title' in obj && 'description' in obj && 'type' in obj && 'story_quests' in obj; // Add more checks as necessary
};

const quest_key = "VzCEVn59mDZ3oMsiMRk956tVh7f2NbqkpmBwKAVRxjqEE3zEBFeA6kUkbSkKUaPAsE3kjRZnr6evxsFzP9PLp6Z8C3nT9RSbB5ZkCxWop5Htvzod2c8HdMLxE3XVCNrd";
const storyline_key = "qgXXXRz2QQsfC2LjaSorC2NZLBHQnhQPY4DCPDjm43UC4ZoPdffiDdBGwzHx5vsn2zufuDNEH5BBCtNPv5swYyD7b3YRWzcf3wQsW8UCUCbDMsmLAJsnC376LtnUM2Zn"
export const getStorylinesFromLocalStorage = (): StorylineDetails[] => {
    const base64EncodedStorylines = localStorage.getItem(storyline_key);
    if (!base64EncodedStorylines) return [];
    // Decode the Base64 string back into a JSON string
    const storylinesString = atob(base64EncodedStorylines);
    const storylines = JSON.parse(storylinesString).filter(isValidQuestStorylineObject);
    return storylines;
};

export const saveStorylinesToLocalStorage = (storylines: StorylineDetails[]) => {
    const storylinesToSAve = Array.isArray(storylines) ? storylines : [storylines];
    // Convert the quests to a JSON string then to a Base64-encoded string
    const base64EncodedStorylines = btoa(JSON.stringify(storylinesToSAve));
    localStorage.setItem(storyline_key, base64EncodedStorylines);
};

export const getQuestsFromLocalStorage = (): QuestObject[] => {
    const base64EncodedQuests = localStorage.getItem(quest_key);
    if (!base64EncodedQuests) return [];
    // Decode the Base64 string back into a JSON string
    const questsString = atob(base64EncodedQuests);
    const quests = JSON.parse(questsString).filter(isValidQuestObject);
    return quests;
};

export const saveQuestsToLocalStorage = (quests: QuestObject | QuestObject[]) => {
    const questsToSave = Array.isArray(quests) ? quests : [quests];
    // Convert the quests to a JSON string then to a Base64-encoded string
    const base64EncodedQuests = btoa(JSON.stringify(questsToSave));
    localStorage.setItem(quest_key, base64EncodedQuests);
};

export const saveInitialLoadQuestStateToLocalStorage = () => {
    localStorage.setItem("anomaanaafimo", "98aj");
}

export const hasInitialQuestStateLoaded = () => {
    let q = localStorage.getItem("anomaanaafimo");

    if (q === "98aj") {
        return true;
    }
    
    return false;
}

export const saveInitialLoadStorylineStateToLocalStorage = () => {
    localStorage.setItem("iojjefljkfsdf", "fde4");

}

export const hasInitialStorylineStateLoaded = () => {
    let q = localStorage.getItem("iojjefljkfsdf");

    if (q === "fde4") {
        return true;
    }
    
    return false;
}

export const saveQuestToLocal = (quest: QuestObject) => {
    const existingQuests = getQuestsFromLocalStorage();
    const index = existingQuests.findIndex((q) => q.id === quest.id);
    if (index !== -1) {
      existingQuests[index] = quest; // Update existing quest
    } else {
      existingQuests.push(quest); // Add new quest
    }

    saveQuestsToLocalStorage(existingQuests)
  };

  export const saveStorylineToLocal = (storyline: StorylineDetails) => {
    const existingStoryline = getStorylinesFromLocalStorage();
    const index = existingStoryline.findIndex((s) => s.id === storyline.id);
    if (index !== -1) {
      existingStoryline[index] = storyline; // Update existing quest
    } else {
      existingStoryline.push(storyline); // Add new quest
    }

    saveStorylinesToLocalStorage(existingStoryline)
  };


  export const removeQuestFromLocal = (questId: string) => {
    // Fetch the current quests from local storage
    const existingQuests = getQuestsFromLocalStorage();

    // Filter out the quest to be deleted
    const updatedQuests = existingQuests.filter(quest => quest.id !== questId);

    // Check if a quest was actually removed (optional)
    if (existingQuests.length !== updatedQuests.length) {
        //console.log(`Quest with ID ${questId} removed from local storage.`);
    } else {
        //console.log(`No quest with ID ${questId} found in local storage.`);
    }

    // Save the updated list of quests back to local storage
    saveQuestsToLocalStorage(updatedQuests);
};

export const removeStorylineFromLocal = (storylineId: string) => {
    // Fetch the current storylines from local storage
    const existingStorylines = getStorylinesFromLocalStorage();

    // Filter out the storyline to be deleted
    const updatedStorylines = existingStorylines.filter(storyline => storyline.id !== storylineId);

    // Optionally, check if a storyline was actually removed
    if (existingStorylines.length !== updatedStorylines.length) {
        console.log(`Storyline with ID ${storylineId} removed from local storage.`);
    } else {
        console.log(`No storyline with ID ${storylineId} found in local storage.`);
    }

    // Save the updated list of storylines back to local storage
    saveStorylinesToLocalStorage(updatedStorylines);
};