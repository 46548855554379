import React from 'react'
import './Dashboard.css'
import styled from 'styled-components'
import QuestsOverview from './Components/QuestTabContentComponents/QuestsOverview'

const Dashboard: React.FC = () => {
    return (
        <MainContainer title={"Quest overview"}>
            <Container id="container__resize">
                <QuestsOverview />
            </Container>
        </MainContainer>
    )
}

const Container = styled.div`
    display: flex;
    height: 100%;
    max-height: inherit;
    flex: 1;
    position:relative;
`


const MainContainer = styled.div`
    /*margin: 16px;*/
    background: #121010;
    width: 100%;
    /*height: 100%;*/
    border: 1px solid #463e32;
    display: flex;
    flex-direction: column;

        background: linear-gradient(0deg, rgb(27 18 17) 0%, rgba(17,16,15,1) 30%, rgba(17,16,15,1) 70%, rgb(27 18 17) 100%);
`


export default Dashboard