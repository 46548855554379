import React, { useEffect, useState } from 'react'
import { useAlerith } from '../../../../../contexts/Alerith/Alerith'
import MainContainer from '../../../../../components/MainContainer'
import styled from 'styled-components'
import { StorylineDetails } from '../../../../../components/quest/interfaces/quest'
import './OfficialStoryLines.css'
import { ReactFlowProvider } from 'reactflow'
import ViewEditStoryline from '../../../../../components/quest/components/StorylineComponents/ViewEditStoryline'
import { questStoryLineTemplate } from '../../../../../components/quest/constants/QuestStoryLineTemplate'
import Button from '../../../../../components/Button'
import ListviewContainer from '../../../../../components/ListviewContainer'

const YourStorylines: React.FC = () => {
    const [selectedStoryline, setSelectedStoryline] = useState<StorylineDetails>(questStoryLineTemplate);
    const [isEditingStory, setIsEditingStory] = useState<boolean>(false)
    const [modificationType, setModificationType] = useState<"create" | "edit" | "view" | undefined>(undefined);
    const { userPersonalStorylines } = useAlerith()
    const [userStorylines, setUserStorylines] = useState<StorylineDetails[]>([])

    useEffect(() => {
        setUserStorylines(userPersonalStorylines);
    }, [userPersonalStorylines]);

    const ShortenTypeStoryline = (typeQuest: string) => {
        if (typeQuest == "Main Story")
            return "Main";
        else if (typeQuest == "Side Story") {
            return "Side"
        }
    }

    // Button Handlers
    const modificationButtonHandler = (type: string, QuestStoryLine: StorylineDetails | undefined = undefined) => {
        switch (type) {
            case "create":
                setSelectedStoryline(questStoryLineTemplate);
                setModificationType("create");
                setIsEditingStory(true);
                break;
            case "edit":
                //setSelectedStoryline(questStoryLineTemplate);
                setModificationType("edit");
                setIsEditingStory(true);
                break;
            case "view":
                if (QuestStoryLine !== undefined) {
                    setSelectedStoryline(QuestStoryLine);
                    setModificationType("view");
                    setIsEditingStory(false);
                } else {
                    setModificationType(undefined);
                    setIsEditingStory(false);
                    setSelectedStoryline(questStoryLineTemplate);
                }
                break;
            case "unset":
                setSelectedStoryline(questStoryLineTemplate);
                setModificationType(undefined);
                setIsEditingStory(false);
                break;
            default:
                break;
        }
    }

    const createStorylineButtonIsDisabled = () => {
        return isEditingStory && modificationType != undefined;
    }

    const getMainContainerTitle = () => {
        let title = "Your Quest Story lines";
        switch (modificationType) {
            case "create":
                title = "Creating New Storyline";
                break;
            case "edit":
                title = "Editing Storyline";
                break;
            case "view":
            case undefined:
            default:
                title = "Your Quest Story lines";
                break;
        }

        return title;
    }

    const handleSelectStoryline = (storyline: StorylineDetails) => {
        modificationButtonHandler("view", storyline)
    };

    return (
        <MainContainer title={getMainContainerTitle()}>
            <Container id="container__resize">
                <ListviewContainer<StorylineDetails>
                    items={userStorylines}
                    isEditing={isEditingStory}
                    selectedStoryline={selectedStoryline}
                    handleSelectStoryline={handleSelectStoryline}
                    renderItem={(storyline, isSelected) => (
                        <React.Fragment>
                            <span>{storyline.title}</span>
                            <span>{ShortenTypeStoryline(storyline.type)}</span>
                        </React.Fragment>
                    )}
                    renderButton={
                        <React.Fragment>
                            <Button disabled={createStorylineButtonIsDisabled()} onClick={() => modificationButtonHandler("create")}>
                                Create New Story
                            </Button>
                        </React.Fragment>
                    }
                />
                <ContainerQuestData>
                    {selectedStoryline !== undefined && modificationType !== undefined ? (
                        <ReactFlowProvider>
                            <ViewEditStoryline
                                storyline={selectedStoryline}
                                setUserStorylines={setUserStorylines}
                                setSelectedStoryline={setSelectedStoryline}
                                canEditStoryline={isEditingStory}
                                modificationType={modificationType}
                                modificationButtonHandler={modificationButtonHandler}
                            />
                        </ReactFlowProvider>

                    )
                        : (
                            <InformationContainer>
                                <h1>Accessing Quest Storyline Information: A Step-by-Step guide</h1>
                                <p>
                                    To access detailed information about a quest storylines—including its title, description, necessary requirements, objectives, and rewards—please begin by selecting a storyline from the list located on the left side of the screen. Simply click on the storyline you're interested in to view all related details.
                                </p>
                                <h2>Important</h2>
                                <p>
                                To remove a quest node from your storyline, simply click on the node you wish to delete. Once selected, press the 'Delete' key on your keyboard, and the node will be removed from the storyline. This action also applies to the connection lines between quest nodes. By selecting a connection line in the same manner and pressing 'Delete', you can easily remove any connections that you no longer require. This feature allows you to efficiently manage and refine the structure of your storyline by deleting unnecessary elements with just a few keystrokes.
                                </p>
                            </InformationContainer>
                        )}


                </ContainerQuestData>
            </Container>
        </MainContainer >
    )
}


const InformationContainer = styled.div`
> h1 {
    color: #c1b197 !important;
    margin: 0;
  }

  > h2 {
    color: #c1b197 !important;
    margin: 0;
    margin-top: 8px;
  }

  > p {
    color: #8b7e6e !important;
    margin: 0;
  }
`

const ContainerQuestData = styled.div`
    flex: 1;

    max-height: inherit;
    overflow-y: auto;
        padding: 8px;
        box-shadow: 0px 0px 20px 4px #080808bf inset;

`

const Container = styled.div`
    display: flex;
    height: 100%;
    max-height: inherit;
    flex: 1;
    position:relative;
`

export default YourStorylines