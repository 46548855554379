import { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../contexts/Firebase/Firebase'; // Adjust the import path if necessary
import { AUTH_MESSAGE_NOT_SIGNED_IN, AUTH_MESSAGE_SIGNED_IN } from '../../constants/authMessage';

const useAuthListener = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [checkingStatus, setCheckingStatus] = useState(true);

    // This line doesn't conditionally call useContext anymore
    const firebaseContext = useContext(FirebaseContext);

    // If firebaseContext is not available, it's better to handle it gracefully
    // within the useEffect or by throwing an error or logging. Not returning null from a hook.
    if (!firebaseContext) {
        console.error('FirebaseContext not available');
        // Optionally, handle the error state here. For example, you could return
        // an error state from this hook if necessary.
    }

    const { auth, user, setAuthCheckType, setAuthMessage } = firebaseContext || {}; // Destructure auth, providing an empty object as fallback

    useEffect(() => {
        // Ensure auth is defined before setting up the listener
        if (auth) {
            const unsubscribe = auth.onAuthStateChanged((authUser) => {
                if (setAuthCheckType && setAuthMessage) {
                    if (authUser) {
                        // User is signed in.
                        setLoggedIn(true);
                        setAuthMessage(AUTH_MESSAGE_SIGNED_IN)

                        setAuthCheckType(0); // 0 for signed in
                        // set the auth message here?>
                    } else {
                        // User is signed out.
                        setLoggedIn(false);
                        setAuthMessage(AUTH_MESSAGE_NOT_SIGNED_IN)
                    }
                    setCheckingStatus(false);
                }
            });

            // Cleanup subscription on unmount
            return () => unsubscribe();
        }
    }, [auth, user]); // Ensure effect runs again if auth object changes

    return { loggedIn, checkingStatus };
};

export default useAuthListener;
