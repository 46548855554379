export type QuestObjectiveEnumType = {
    COLLECT_ITEM: string;
    SLAY_CREATURE: string;
    ESCORT_NPC: string;
    TALK_WITH_NPC: string;
};

export const QuestObjectiveEnum: QuestObjectiveEnumType = {
    COLLECT_ITEM: 'Collect Item',
    SLAY_CREATURE: 'Slay Creature',
    ESCORT_NPC: 'Escort NPC',
    TALK_WITH_NPC: 'Talk with NPC',
};

export default QuestObjectiveEnum