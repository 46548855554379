import React from 'react';
import styled from 'styled-components';
import { QuestRewardsEnum } from '../enums/QuestRewardsEnum';
import QuestPropertyItem from '../components/QuestPropertyItem';

import { 
  QuestObject
} from '../interfaces/quest';
import { getIdForInputfieldDiv } from '../../../constants/quest/InputWarningNoticeHelpers';
import WarningMessage from '../../WarningMessage';


interface QuestRewardsContainerProps {
  questObj: QuestObject;
  setQuestObj: React.Dispatch<React.SetStateAction<QuestObject>>;
  handleRemoveQuestObjective: (id: number) => void;
  inputfieldNotice: { message: string, targetId: string }

}

const QuestRewardsContainer: React.FC<QuestRewardsContainerProps> = ({
  questObj,
  setQuestObj,
  handleRemoveQuestObjective,
  inputfieldNotice
}) => {

  return (
    <OptionContainer>
      {Object.values(QuestRewardsEnum).map((rewardType) => {
        if (questObj.rewards && Object.keys(questObj.rewards).length > 0) {
          const filteredRewards = Object.values(questObj.rewards).filter(
            (reward) => reward.type === rewardType
          );

          if (filteredRewards.length > 0) {
            return (
              <React.Fragment key={rewardType}>
                <h3>{rewardType}</h3>
                <SectionContainer>
                  {filteredRewards.map((filteredReward) => (
                    <div key={filteredReward.id} id={getIdForInputfieldDiv("reward", filteredReward.id.toString(), filteredReward.type)}>
                      {inputfieldNotice.targetId === getIdForInputfieldDiv("reward", filteredReward.id.toString(), filteredReward.type) && <WarningMessage message={inputfieldNotice.message} />}

                       <QuestPropertyItem QuestProperty={filteredReward}
                       setQuestObj={setQuestObj}
                       deleteQuestProperty={handleRemoveQuestObjective}
                       />
                    </div>
                  ))}
                </SectionContainer>
              </React.Fragment>
            );
          }
        }

        return null;
      })}
    </OptionContainer>
  );
};

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    margin: 5px 0;
  }
`;

export default QuestRewardsContainer;
