import React, { useState, useEffect, useCallback, SetStateAction } from 'react';
import styled, { keyframes } from "styled-components";
import ReactFlow, { useNodesState, useEdgesState, addEdge, MiniMap, Controls, Position, Connection, Edge, useReactFlow, getOutgoers, Node, ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import { questObjectTemplate } from '../../constants/QuestObjectTemplate'
import QuestNodeObject from './QuestNodeObject'
import './ReactflowCustom.css'
import { useAlerith } from '../../../../contexts/Alerith/Alerith';
import { QuestObject, QuestObjectStoryLine, QuestStoryLine } from '../../interfaces/quest';
import SectionQuestStorylineTitle from '../../sections/SectionQuestStorylineTitle';
import SectionQuestStorylineDescription from '../../sections/SectionQuestStorylineDescription';
import ButtonDivElement from '../../../ButtonDivElement';

import Button from '../../../Button';

const connectionLineStyle = { stroke: '#fff' };
const snapGrid: [number, number] = [20, 20];
const nodeTypes = {
    customNode: QuestNodeObject,
};
const defaultViewport = { x: 0, y: 0, zoom: 0 };//1.5

const initBgColor = '#1A192B';

const getNodeId = () => `randomnode_${+new Date()}`;

const getNodeId2 = (index: number) => `randomnode_${index}_${+new Date()}`;

interface ReactflowContainerProps {
    storyline: QuestStoryLine;
    selectedStoryline: any;
    canEdit: boolean;
    onClickHandler?: () => void;
    modificationType?: string;
    setModificationType?: any;
}
const StoryLineContainer: React.FC<ReactflowContainerProps> = ({ setModificationType, modificationType, storyline, selectedStoryline, canEdit, onClickHandler }) => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [bgColor, setBgColor] = useState(initBgColor);
    const { getNodes, getEdges } = useReactFlow();
    const [questObjectFromModal, setQuestObjectFromModal] = useState(questObjectTemplate)
    const { quests } = useAlerith();

    const [nextQuestId, setNextQuestId] = useState(0)
    const addNewQuest = () => {
        onAdd(nextQuestId);
    }

    const getNodeById = (node_id: string) => {
        return nodes.find((n) => n.id === node_id);
    }
    const onAddFromModal = useCallback((node_id: string) => {
        const predecessor = getNodeById(node_id);

        if (predecessor) {
            const newNode = {
                id: getNodeId(),
                type: 'customNode',
                //data: { quest: questObjectFromModal },
                data: { quest: quests[3] },
                position: {
                    x: predecessor?.position.x + 40,
                    y: predecessor?.position.y
                }
            };

            setNodes((nds) => nds.concat(newNode));
        }
    }, [nodes, setNodes, questObjectFromModal]);

    const onAdd = useCallback((questId: number) => {
        const newNode = {
            id: getNodeId(),
            type: 'customNode',
            data: { quest: quests[questId], onAddFromModal: onAddFromModal },
            position: {
                x: 0,
                y: 0 + (nodes.length + 1) * 20
            }
        };
        setNextQuestId(nextQuestId + 1);
        setNodes((nds) => nds.concat(newNode));
    }, [nodes, setNodes, questObjectFromModal]);

    const [indexCount, setIndexCount] = useState(0)

    const onAddTemplates = useCallback((qstorylineobject: QuestObjectStoryLine[]) => {
        console.log(qstorylineobject)

        const nodePositions: { [key: string]: { x: number, y: number } } = {};

        const newNodes: any[] = []; // Define an array to store the new nodes

        // Calculate y-positions of each quest node based on prerequisite quests
        const modifiedPositions: Set<string> = new Set(); // Keep track of modified positions

        for (let i = 0; i < qstorylineobject.length; i++) {
            const quest = qstorylineobject[i];
            let yPos = 0;

            Object.values(quest.prerequisite_quests).forEach((prerequisiteQuest) => {
                const prerequisiteIndex = qstorylineobject.findIndex(q => q.id === prerequisiteQuest.id);
                if (prerequisiteIndex !== -1) {
                    // Set nodePositions[prerequisiteIndex] if it's not already set
                    if (!nodePositions[prerequisiteIndex]) {
                        nodePositions[prerequisiteIndex] = { x: 0, y: 0 }; // You can set initial values here
                    }
                }
            });

            if (quest.prerequisite_quests && Object.values(quest.prerequisite_quests).length > 0) {
                // Check if the position for any prerequisite quest has already been modified
                const isPositionModified = Object.values(quest.prerequisite_quests).some(prerequisiteQuest => modifiedPositions.has(prerequisiteQuest.id));
                if (isPositionModified) {
                    // If position has been modified, set yPos to the previous value
                    yPos = nodePositions[i].y;
                } else {
                    // Calculate yPos based on prerequisite positions
                    const prerequisitePositions = Object.values(quest.prerequisite_quests).map(prerequisiteQuest => nodePositions[prerequisiteQuest.id]?.y || 0);
                    yPos = Math.max(...prerequisitePositions) + 200; // Adjust yPos based on prerequisite positions

                    // Modify yPos based on the index of the prerequisite quest
                    Object.values(quest.prerequisite_quests).forEach((prerequisiteQuest, index, array) => {
                        const prerequisiteIndex = qstorylineobject.findIndex(q => q.id === prerequisiteQuest.id);
                        if (prerequisiteIndex !== -1) {
                            if (!nodePositions[prerequisiteIndex]) {
                                nodePositions[prerequisiteIndex] = { x: 0, y: 0 };
                            }
                            // Adjust yPos based on the index of the prerequisite quest
                            if (array.length === 3) {
                                if (index === 0) {
                                    yPos += 200; // Move the 2nd quest up by 200px
                                } else if (index === 1) {
                                    yPos -= 200; // Move the 3rd quest down by 200px
                                } else if (index === 2) {
                                    // Ensure yPos remains unchanged for the 3rd quest
                                }
                            } else if (array.length === 2) {
                                if (index === 0) {
                                    yPos -= 200; // Move the 1st quest down by 200px
                                } else if (index === 1) {
                                    yPos += 400; // Move the 2nd quest up by 200px
                                }
                            } else {
                                yPos += (index - 1) * 200; // Adjust yPos for other cases
                            }
                        }
                    });
                }
            }

            nodePositions[i] = { x: (420 * i) + (i * 50), y: yPos }; // Store the position of the node

            // Mark the positions of current quest and its prerequisites as modified
            modifiedPositions.add(quest.id);
            Object.values(quest.prerequisite_quests).forEach(prerequisiteQuest => modifiedPositions.add(prerequisiteQuest.id));

            // Create the node object
            newNodes.push({
                id: getNodeId2(i),
                type: 'customNode',
                data: { quest: quest },
                position: {
                    x: (420 * i) + (i * 100), // Calculate xPos
                    y: yPos // Set yPos
                }
            });
        }

        let newEdges: Edge<any>[] = [];

        const findObject = (prerequirisiteId: string) => {
            for (let index = 0; index < newNodes.length; index++) {
                const element = newNodes[index];

                if (element.data.quest.id == prerequirisiteId)
                    return element.id;
            }

            return -1;
        }

        for (let i = 0; i < newNodes.length - 1; i++) {
            const prerequisites: QuestObjectStoryLine[] = Object.values(newNodes[i].data.quest.prerequisite_quests);

            if (prerequisites.length === 0) continue;

            for (let p = 0; p < prerequisites.length; p++) {
                if (!prerequisites[p]) continue;

                const prerequisiteId = prerequisites[p].id;

                const edgeId = 'e' + newNodes[i].data.quest.id + '-' + prerequisiteId;
                const targetNode = findObject(prerequisiteId).toString();

                const newEdge: Edge<any> = {
                    id: edgeId,
                    source: newNodes[i].id,
                    target: targetNode,
                    animated: false,
                    style: { stroke: 'rgb(239 183 84)', strokeWidth: '2px' }

                };

                newEdges.push(newEdge);
            }
        }

        setNodes((prevNodes) => [...prevNodes, ...newNodes]);
        setEdges((prevEdges) => [...prevEdges, ...newEdges]);
    }, [nodes, setNodes, questObjectFromModal]);

    useEffect(() => {
        setNodes([])
        //onAddTemplates(storyline.quests as QuestObjectStoryLine[]);

    }, [storyline, selectedStoryline]);

    useEffect(() => {
        const onChange = (event: { target: { value: any; }; }) => {
            setNodes((nds) =>
                nds.map((node) => {
                    if (node.id !== '2') {
                        return node;
                    }

                    const color = event.target.value;

                    setBgColor(color);

                    return {
                        ...node,
                        data: {
                            ...node.data,
                            color,
                        },
                    };
                })
            );
        };

        setNodes([
            {
                id: '1',
                type: 'selectorNode',
                data: { label: 'An input node' },
                position: { x: 0, y: 50 },
                sourcePosition: Position.Right,
            },
            {
                id: '2',
                type: 'selectorNode',
                data: { onChange: onChange, color: initBgColor },
                style: { border: '1px solid #777', padding: 10 },
                position: { x: 300, y: 50 },
            },
            {
                id: '3',
                type: 'selectorNode',
                data: { label: 'Output A' },
                position: { x: 650, y: 25 },
                targetPosition: Position.Left,
            },
            {
                id: '4',
                type: 'selectorNode',
                data: { label: 'Output B' },
                position: { x: 650, y: 100 },
                targetPosition: Position.Left,
            },
        ]);

        setEdges([
            {
                id: 'e1-2',
                source: '1',
                target: '2',
                animated: false,
                style: { stroke: '#fff' },
            },
            {
                id: 'e2a-3',
                source: '2',
                target: '3',
                sourceHandle: 'a',
                animated: false,
                style: { stroke: '#fff' },
            },
            {
                id: 'e2b-4',
                source: '2',
                target: '4',
                sourceHandle: 'b',
                animated: false,
                style: { stroke: '#fff' },
            },
        ]);
    }, []);

    const isValidConnection = useCallback(
        (connection: any) => {
            // we are using getNodes and getEdges helpers here
            // to make sure we create isValidConnection function only once
            const nodes = getNodes();
            const edges = getEdges();
            const target = nodes.find((node) => node.id === connection.target);
            const hasCycle = (node: any, visited = new Set<string>()) => {
                if (visited.has(node.id)) return false;

                visited.add(node.id);

                for (const outgoer of getOutgoers(node, nodes, edges)) {
                    if (outgoer.id === connection.source) return true;
                    if (hasCycle(outgoer, visited)) return true;
                }
            };

            if (target === undefined) return false; // extra fail check else it doesnt work
            if (target.id === connection.source) return false;
            return !hasCycle(target);
        },
        [getNodes, getEdges],
    );

    const onConnect = useCallback(
        (params: Edge | Connection) => {

            setEdges((els) => addEdge(params, els))

        },
        []
    );


    function setQuestObj(value: SetStateAction<QuestObject>): void {
        throw new Error('Function not implemented.');
    }

    return (
        <Container>
            <>
                {modificationType != "edit_story" && modificationType != "create_story" && onClickHandler && (
                    <AnimatedDiv canEdit={modificationType != "edit_story" && modificationType != "create_story"} style={{ position: 'absolute', top: '0', right: '0', margin: 'unset' }}>

                        <NewQuestButtonContainer style={{ marginRight: 'auto' }} onClick={() => setModificationType("edit_story")}>
                            <div>
                                <h3>Edit Storyline</h3>
                            </div>
                        </NewQuestButtonContainer>

                    </AnimatedDiv>
                )}

                {canEdit ? (
                    <AnimatedForm canEdit={canEdit}>
                       {/*} <SectionQuestStorylineTitle questTitle={storyline.title} setQuestObj={setQuestObj} />
                        <SectionQuestStorylineDescription questDescription={storyline.description} setQuestObj={setQuestObj} />
                */} </AnimatedForm>
                ) : (
                    <AnimatedContent canEdit={canEdit}>
                        <h1>{storyline.title}</h1>
                        <p>{storyline.description}</p>
                    </AnimatedContent>
                )}
            </>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                style={{ background: bgColor }}
                nodeTypes={nodeTypes}
                connectionLineStyle={connectionLineStyle}
                isValidConnection={isValidConnection}
                snapToGrid={true}
                snapGrid={snapGrid}
                defaultViewport={defaultViewport}
                deleteKeyCode={"null"}
                fitView
                attributionPosition="bottom-left"
                className='react_flow_nodes_custom'

                edgesUpdatable={false}
                edgesFocusable={false}
                nodesDraggable={true}
                nodesConnectable={false}
                nodesFocusable={false}
                elementsSelectable={true}
                minZoom={0}
                maxZoom={2}
            >
                <div className='node_view_control_buttons'>
                    <Button type="button" variant="primary" onClick={() => console.log("")} disabled={false}>Add Quest To Storyline</Button>
                </div>
                <MiniMap
                    nodeStrokeColor={(n) => {
                        if (n.type === 'input') return '#0041d0';
                        if (n.type === 'selectorNode') return bgColor;
                        if (n.type === 'output') return '#ff0072';
                        return '#000'; // Default color if none of the conditions are met
                    }}
                    nodeColor={(n) => {
                        if (n.type === 'selectorNode') return bgColor;
                        return '#fff'; // This already acts as a default return value
                    }}
                />
                <Controls />
            </ReactFlow>
            {canEdit && onClickHandler && (
                <ButtonContainer>
                    {modificationType == "edit_story" && (
                        <NewQuestButtonContainer style={{ marginRight: 'auto' }}>
                            <Button type="button" variant="primary" onClick={() => onClickHandler()} disabled={false}>Delete Storyline</Button>
                        </NewQuestButtonContainer>
                    )}

                    <NewQuestButtonContainer>
                        <Button type="button" variant="primary" onClick={() => onClickHandler()} disabled={false}>Cancel</Button>
                    </NewQuestButtonContainer>
                    <NewQuestButtonContainer>
                        <Button type="button" variant="primary" onClick={() => onClickHandler()} disabled={false}>Save Changes</Button>
                    </NewQuestButtonContainer>
                </ButtonContainer>
            )}
        </Container>

    )
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const AnimatedDiv = styled.div<{ canEdit: boolean }>`
  animation: ${fadeIn} 0.3s ease-in-out;
animation: ${({ canEdit }) => (canEdit ? fadeIn : fadeOut)} 0.3s ease-in-out;
  opacity: ${({ canEdit }) => (canEdit ? 1 : 0)};
  pointer-events: ${({ canEdit }) => (canEdit ? 'auto' : 'none')};
  transition: opacity 0.3s ease-in-out;
`;

const AnimatedForm = styled.form<{ canEdit: boolean }>`
  animation: ${fadeIn} 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
animation: ${({ canEdit }) => (canEdit ? fadeIn : fadeOut)} 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  opacity: ${({ canEdit }) => (canEdit ? 1 : 0)};
  pointer-events: ${({ canEdit }) => (canEdit ? 'auto' : 'none')};
  transition: opacity 0.3s ease-in-out;
  // Other styles...

  input {
    background: #1f1e1d;
    margin-bottom: 0px;
    margin-top: 4px;
    padding: 2px 6px;
    text-align: left;
    font-family: 'Goudy Bookletter 1911',sans-serif;
    letter-spacing: 1px;
    line-height: 1.1;
    font-size: 18px;
    color: #A99C8EFF;
    border: 1px solid #635a4d;
    padding: 2px 6px;
    outline: unset;
    margin-bottom: 10px
  }
  

  textarea {
    padding: 6px;
    flex: 1;
    margin-top: 4px;

  }

  select {
    margin-top: 4px;
}

h1 {
    color: #c1b197 !important;
    margin: 0;
    margin-bottom: 10px;
  }

  h2 {
    color: #bd8b42 !important;
    margin: 0;
  }

  h3 {
    color: #9a6922 !important;
  }

  p {
    color: #8b7e6e !important;
    margin: 0;
  }

  span {
    color: #8b7e6e!important;
  }
`;

const AnimatedContent = styled.div<{ canEdit: boolean }>`
  animation: ${fadeOut}  0.3s ease-in-out;

  animation: ${({ canEdit }) => (canEdit ? fadeOut : fadeIn)} 0.3s ease-in-out;
  opacity: ${({ canEdit }) => (canEdit ? 0 : 1)};
  pointer-events: ${({ canEdit }) => (canEdit ? 'none' : 'auto')};
  transition: opacity 0.3s ease-in-out;

  h1 {
    color: #c1b197 !important;
    margin: 0;
    margin-bottom: 10px;
  }

  h2 {
    color: #bd8b42 !important;
    margin: 0;
  }

  h3 {
    color: #9a6922 !important;
  }

  p {
    color: #8b7e6e !important;
    margin: 0;
  }

  span {
    color: #8b7e6e!important;
  }
`;

const ButtonContainer = styled.div`
padding-top: 8px;
border-top: 1px solid #2b2824;
    margin-top: 8px;
display: flex;
gap: 8px;
justify-content: flex-end;
`

const NewQuestButtonContainer = styled.div`


    > div {
        padding: 4px;
        background-color: #1b1111;
        border: 1px solid #3d2224;
        
        background-color: #311414;
        border: 1px solid #3d2224;
        box-shadow: 0 0 8px 7px #0000003b inset;

        transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;

        > h3 {
            color: #c1b197 !important;
            font-weight: 700;
            user-select: none;
            margin: 0;
            text-align: center;
            font-size: 1.4rem;
            transition: color 0.2s ease-in-out;
        }

        &:hover {
            cursor:pointer;
            background-color: #3f1616;
            border: 1px solid #6a393d;

            h3 {
                color: #efb754 !important;

            }
        }
    }
`

export const UtilityButton = styled.div`
    border: none;
    background: #fefefe;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    padding: 16px;

    background-image: initial;
    background-color: rgb(26, 27, 27);
    border-bottom-color: rgb(55, 57, 58);
    color: white;
`

export const UtilityWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    margin: 15px;
    z-index: 5;
`

export const TreeWrapper = styled.div`
> node__root > circle {
    fill: red;
  }
  
  > node__branch > circle {
    fill: yellow;
  }
  
  > node__leaf > circle {
    fill: green;
    /* Let's also make the radius of leaf nodes larger */
    r: 40;
  }
`

const Container = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
max-height: inherit;
position:relative;

`


interface ReactflowContainerProps {
    storyline: QuestStoryLine;
    selectedStoryline: any;
    canEdit: boolean;
    modificationType?: string;
    onClickHandler?: () => void;
    setModificationType?: any
}

const ReactflowContainer: React.FC<ReactflowContainerProps> = ({ setModificationType, storyline, selectedStoryline, canEdit, onClickHandler, modificationType }) => {
    return (
        <ReactFlowProvider>
            <StoryLineContainer setModificationType={setModificationType} storyline={storyline} modificationType={modificationType} selectedStoryline={selectedStoryline} canEdit={canEdit} onClickHandler={onClickHandler} />
        </ReactFlowProvider>
    )
}


export default ReactflowContainer