import React, { useState,useEffect } from 'react';

import { QuestObjectiveEnum } from '../enums/QuestObjectiveEnum';
import QuestObjectivesContainer from '../components/QuestObjectivesContainer';
import ButtonDivElement from '../../ButtonDivElement';

import QuestPropertySection from '../components/QuestPropertySection';


import {
  QuestObject,
  QuestObjective
} from '../interfaces/quest';

import {
templateQuestObjectiveSlayCreature,
templateQuestObjectiveCollectItem,
templateQuestObjectiveEscortNpc,
templateQuestObjectiveTalkToNpc
} from '../constants/QuestObjectiveTemplates'
import WarningMessage from '../../WarningMessage';

const DEFAULT_SELECTED_OBJECTIVE = QuestObjectiveEnum.COLLECT_ITEM;

interface QuestObjectiveSectionProps {
  questObj: QuestObject;
  setQuestObj: React.Dispatch<React.SetStateAction<QuestObject>>;
  inputfieldNotice: { message: string, targetId: string }
  modificationType: "create" | "edit" | "view" | undefined
}

const QuestObjectiveSection: React.FC<QuestObjectiveSectionProps> = ({ questObj, setQuestObj, inputfieldNotice, modificationType }) => {
  const [selectedObjective, setSelectedObjective] = useState<string>(DEFAULT_SELECTED_OBJECTIVE);
  const [questObjectives, setQuestObjectives] = useState<QuestObjective[]>([]);
  const [questObjectiveTypeCount, setQuestObjectivesTypeCount] = useState<number[]>([0, 0, 0, 0]);
  const [initialLoad, setInitialLoad] = useState(false)

  useEffect(() => {
    if (modificationType == "edit" && initialLoad == false) {
      let objectives = Object.values(questObj.objectives)
      let countSlayCreatures = 0;
      let countCollectItems = 0;
      let countEscortNpc = 0;
      let countTalkWithNpc = 0;

      for (let index = 0; index < objectives.length; index++) {

        switch (objectives[index].type) {
          case QuestObjectiveEnum.SLAY_CREATURE:
            countSlayCreatures++
            break;
          case QuestObjectiveEnum.COLLECT_ITEM:
            countCollectItems++
            break;
          case QuestObjectiveEnum.ESCORT_NPC:
            countEscortNpc++
            break;
          case QuestObjectiveEnum.TALK_WITH_NPC:
            countTalkWithNpc++
            break;
          default:
            break;
        }

        setQuestObjectivesTypeCount([countSlayCreatures, countCollectItems, countEscortNpc, countTalkWithNpc])

      }
      setInitialLoad(true)
    }
  }, [modificationType]);          

  const handleAddQuestObjective = () => {
    if (!selectedObjective) {
      return;
    }

    let nextId = questObjectiveTypeCount.reduce((acc, count) => acc + count, 0);
    let newQuestObjective: QuestObjective;

    switch (selectedObjective) {
      case QuestObjectiveEnum.SLAY_CREATURE:
        setQuestObjectivesTypeCount((prevCount) => [prevCount[0] + 1, prevCount[1], prevCount[2], prevCount[3]]);
        const slayCreatureTemplate = templateQuestObjectiveSlayCreature;

        slayCreatureTemplate.type_count = questObjectiveTypeCount[0];
        slayCreatureTemplate.id = nextId;

        newQuestObjective = {
          ...slayCreatureTemplate,
        };
        break;
      case QuestObjectiveEnum.COLLECT_ITEM:
        setQuestObjectivesTypeCount((prevCount) => [prevCount[0], prevCount[1] + 1, prevCount[2], prevCount[3]]);

        const collectItemTemplate = templateQuestObjectiveCollectItem;
        collectItemTemplate.type_count = questObjectiveTypeCount[1]
        collectItemTemplate.id = nextId

        newQuestObjective = {
          ...collectItemTemplate,
        };
        break;
      case QuestObjectiveEnum.ESCORT_NPC:
        setQuestObjectivesTypeCount((prevCount) => [prevCount[0], prevCount[1], prevCount[2] + 1, prevCount[3]]);

        const escortNpcTemplate = templateQuestObjectiveEscortNpc;
        escortNpcTemplate.type_count = questObjectiveTypeCount[2];
        escortNpcTemplate.id = nextId;
        newQuestObjective = {
          ...escortNpcTemplate,
        };
        break;
      case QuestObjectiveEnum.TALK_WITH_NPC:
        setQuestObjectivesTypeCount((prevCount) => [prevCount[0], prevCount[1], prevCount[2], prevCount[3] + 1]);

        const talkWithNpcTemplate = templateQuestObjectiveTalkToNpc;
        talkWithNpcTemplate.type_count = questObjectiveTypeCount[3];
        talkWithNpcTemplate.id = nextId;
        newQuestObjective = {
          ...talkWithNpcTemplate,
        };
        break;
      default:
        //newQuestObjective = { id: nextId, type_objective_id: 0 };
        newQuestObjective = { id: nextId, type_count: -1, type: "UNDEFINED?!" };
        break;
    }

    setQuestObjectives((prevQuestObjectives) => [...prevQuestObjectives, newQuestObjective]);

    setQuestObj((prevQuestObj) => ({
      ...prevQuestObj,
      objectives: {
        ...prevQuestObj.objectives,
        [nextId]: newQuestObjective,
      },
    }));
  };

  const handleRemoveQuestObjective = (id: number) => {
    // Filter out the removed objective
    const filteredObjs = { ...questObj.objectives };
    delete filteredObjs[id];

    const updatedTypeCount = [0, 0, 0, 0];
    let nextId = 0;
    // Update the IDs and type_objective_ids of the remaining objectives
    const filteredObjsWithAdjustedIds = Object.values(filteredObjs).map((objective, index) => {
      let typeObjectiveId = 0;

      objective.id = nextId;
      nextId++
      // @ts-ignore
      switch (objective.type) {
        case QuestObjectiveEnum.SLAY_CREATURE:
          typeObjectiveId = updatedTypeCount[0];
          objective.type_count = updatedTypeCount[0]
          updatedTypeCount[0]++;
          break;
        case QuestObjectiveEnum.COLLECT_ITEM:
          typeObjectiveId = updatedTypeCount[1];
          objective.type_count = updatedTypeCount[1]

          updatedTypeCount[1]++;
          break;
        case QuestObjectiveEnum.ESCORT_NPC:
          typeObjectiveId = updatedTypeCount[2];
          objective.type_count = updatedTypeCount[2]

          updatedTypeCount[2]++;
          break;
        case QuestObjectiveEnum.TALK_WITH_NPC:
          typeObjectiveId = updatedTypeCount[3];
          objective.type_count = updatedTypeCount[3]

          updatedTypeCount[3]++;
          break;
        default:
          break;
      }

      return {
        ...objective,
      };
    });

    // Update the count of the quest objective types
    setQuestObjectivesTypeCount(updatedTypeCount);

    // Update the quest objectives state
    setQuestObj((prevQuestObj) => ({
      ...prevQuestObj,
      objectives: filteredObjsWithAdjustedIds,
    }));
  };

  return (
    <>
      {inputfieldNotice.targetId === "quest_objectives_section" && <WarningMessage message={inputfieldNotice.message} />}
      <QuestPropertySection
        title={"Quest Objectives"}
        htmlId={"quest_objectives_section"}
        selectedValue={selectedObjective}
        options={Object.values(QuestObjectiveEnum)}
        onSelectedChange={setSelectedObjective}
        onAdd={handleAddQuestObjective}
        propertyType='Objective'>
        <QuestObjectivesContainer
          questObj={questObj}
          setQuestObj={setQuestObj}
          questObjectiveTypeCount={questObjectiveTypeCount}
          handleRemoveQuestObjective={handleRemoveQuestObjective}
          inputfieldNotice={inputfieldNotice}
        />
      </QuestPropertySection>
    </>
  );
};

export default QuestObjectiveSection;
