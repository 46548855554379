import React, { createContext, useEffect } from "react";
  
interface ViewportContextType {
    width: number;
    height: number;
  }
  
export const Context = createContext<ViewportContextType>({
  width: window.innerWidth,
  height: window.innerHeight,
});

const ViewportProvider: React.FC<React.PropsWithChildren> = ({children}) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
  
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
      
    return (
        <Context.Provider value={{ width, height }}> 
            {children}
        </Context.Provider>
    );
};

export default ViewportProvider;
