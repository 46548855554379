import React from 'react';

interface ArrowSvgIconProps {
    direction: 'up' | 'down' | 'right' | 'left';
    size: number;
    fillColor: string;
}

const ArrowSvgIcon: React.FC<ArrowSvgIconProps> = ({ direction, size, fillColor }) => {
    let rotationDegree = 0;
    switch (direction) {
        case 'up':
            rotationDegree = 180;
            break;
        case 'down':
            rotationDegree = 0;
            break;
        case 'right':
            rotationDegree = 270;
            break;
        case 'left':
            rotationDegree = 90;
            break;
        default:
            rotationDegree = 0;
    }

    const fixedHeight = size / 1.8; // Assuming the fixed height is 1.5 times smaller than the size prop
    const originalWidth = 15.555; // Original width of the SVG
    const originalHeight = 15.555; // Original height of the SVG
    const calculatedWidth = (fixedHeight * originalWidth) / originalHeight;
    return (
            <svg
                width={calculatedWidth + 3}
                height={fixedHeight}
                viewBox="-2.5 -5 75 60"
                preserveAspectRatio="none"
                style={{transform: `rotate(${rotationDegree}deg)`}}
            >
                <path d="M0,0 l35,50 l35,-50" fill="none" stroke={fillColor} stroke-linecap="square" stroke-width="15" />
            </svg>
    );
};

export default ArrowSvgIcon;