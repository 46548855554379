import React, { ChangeEvent, useState } from 'react';
import { QuestObject, QuestStoryLine, StorylineDetails } from '../interfaces/quest';
import SelectOption from '../../SelectOption';
import { QuestStoryTypeEnum } from '../enums/QuestStoryType';
import styled from 'styled-components';
import WarningMessage from '../../WarningMessage';

const defaultSelectedStoryType: QuestStoryTypeEnum = QuestStoryTypeEnum.MAIN_STORY;

interface Props {
  questTitle: string;
  type: QuestStoryTypeEnum;
  setSelectedStoryline: React.Dispatch<React.SetStateAction<StorylineDetails>>;
  inputfieldNotice: { message: string, targetId: string }
}

const SectionQuestStorylineTitle: React.FC<Props> = ({ questTitle, setSelectedStoryline, inputfieldNotice, type }) => {

  const handleQuestTitleChange = (newTitle: string) => {
    setSelectedStoryline((prevStoryline) => ({
      ...prevStoryline,
      title: newTitle,
    }));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleQuestTitleChange(event.target.value);
  };

  const [selectedStoryType, setSelectedStoryType] = useState(type); // default value or based on initial state

  // If you need to perform additional logic when the story type changes, define a handler like so:
  const handleStoryTypeChange = (newType: string) => {
    // Assuming newType is always a valid QuestStoryTypeEnum value, 
    // you can assert it as such. This is safe as long as you're sure 
    // newType cannot be an invalid value.
    const validType = newType as QuestStoryTypeEnum;

    // Update the local state for selected story type
    setSelectedStoryType(validType);

    // Then update the selectedStoryline state to reflect the new story type
    setSelectedStoryline((prevStoryline) => ({
        ...prevStoryline,
        type: validType, // Use the validated/adjusted type here
    }));
};

  return (
    <>
      {inputfieldNotice.targetId === "storyline_title_section" && <WarningMessage message={inputfieldNotice.message} />}
      <TitleAndTypeQuestContainer id="storyline_title_section">
        <div>
          <h2>Story Line Title</h2>
          <input
            id="story_line_title"
            type="text"
            placeholder="Story Line Title"
            value={questTitle}
            onChange={handleChange}
          />
        </div>
        <div>
          <h2>Story Type</h2>
          <SelectOption
            htmlId={'quest_select_type'}
            htmlName={'quest_select_type'}
            options={Object.values(QuestStoryTypeEnum)}
            selected={selectedStoryType}
            onSelect={handleStoryTypeChange}
          />
        </div>
      </TitleAndTypeQuestContainer>
    </>
  );
};

const TitleAndTypeQuestContainer = styled.div`
  display: flex;
  gap: 4px;

  > div:nth-child(1) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  select {
    padding: 3px 6px;
  }
`

export default SectionQuestStorylineTitle;