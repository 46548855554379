import 
{ 
    QuestRequirementPredecessor,
    QuestRequirementProfessionLevel
} from '../interfaces/quest';
import QuestRequirementsEnum from '../enums/QuestRequirements';
import { ProfessionsEnum } from '../enums/ProfessionsEnum';

export const templateQuestRequirementPredecessor: QuestRequirementPredecessor = {
    id: 0,
    type: '',//QuestRequirementsEnum.QUEST_PREDECESSOR,
    type_count: 0,
    quest_id: "0",
    quest_name: ''
};

export const templateQuestRequirementProfessionLevel: QuestRequirementProfessionLevel = {
    id: 0,
    type: QuestRequirementsEnum.LEVEL_REQUIREMENT,
    type_count: 0,
    profession_id: 0,
    profession_name: ProfessionsEnum.ATTACK,
    profession_level: 0
};

