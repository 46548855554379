import React from 'react';
import styled from 'styled-components';
import QuestPropertyItem from '../components/QuestPropertyItem';
import {
  QuestObject
} from '../interfaces/quest';
import QuestRequirementsEnum from '../enums/QuestRequirements';
import { getIdForInputfieldDiv } from '../../../constants/quest/InputWarningNoticeHelpers';
import WarningMessage from '../../WarningMessage';


interface QuestRequirementsContainerProps {
  questObj: QuestObject;
  setQuestObj: React.Dispatch<React.SetStateAction<QuestObject>>;
  handleRemoveQuestObjective: (id: number) => void;
  inputfieldNotice: { message: string, targetId: string }

}

const QuestRequirementsContainer: React.FC<QuestRequirementsContainerProps> = ({
  questObj,
  setQuestObj,
  handleRemoveQuestObjective,
  inputfieldNotice
}) => {

  return (
    <OptionContainer>
      {Object.values(QuestRequirementsEnum).map((requirementType) => {
        if (questObj.requirements && Object.keys(questObj.requirements).length > 0) {
          const filteredRequirements = Object.values(questObj.requirements).filter(
            (requirement) => requirement.type === requirementType
          );

          if (filteredRequirements.length > 0) {
            return (
              <React.Fragment key={requirementType}>
                <h3>{requirementType}</h3>
                <SectionContainer>
                  {filteredRequirements.map((filteredRequirement) => (
                    <div key={filteredRequirement.id} id={getIdForInputfieldDiv("requirement", filteredRequirement.id.toString(), filteredRequirement.type)}>
                      {inputfieldNotice.targetId === getIdForInputfieldDiv("requirement", filteredRequirement.id.toString(), filteredRequirement.type) && <WarningMessage message={inputfieldNotice.message} />}

                      <QuestPropertyItem QuestProperty={filteredRequirement}
                        setQuestObj={setQuestObj}
                        deleteQuestProperty={handleRemoveQuestObjective}
                      />
                    </div>
                  ))}
                </SectionContainer>
              </React.Fragment>
            );
          }
        }

        return null;
      })}
    </OptionContainer>
  );
};

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    margin: 5px 0;
  }
`;

export default QuestRequirementsContainer;
