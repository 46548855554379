import { useState, useEffect } from 'react';
import { useFirebase } from '../contexts/Firebase/Firebase';
import { QuestObject } from '../components/quest/interfaces/quest';

const useFetchUserQuests = () => {
    const { retrieveQuestsForUserUid, user } = useFirebase();
    const [userQuests, setUserQuests] = useState<QuestObject[]>([]);

    useEffect(() => {
        const fetchUserQuests = async () => {
            if (!user) return;

            try {
                const firebaseQuests = await retrieveQuestsForUserUid(user);

                if (firebaseQuests.length > 0) {
                    setUserQuests(firebaseQuests);
                }
            } catch (error) {
                console.error("Error retrieving user's quests: ", error);
            }
        };

        fetchUserQuests();
    }, [user]);

    return userQuests;
};

export default useFetchUserQuests;