import React, { useState, useEffect } from 'react'
import Modal, { ModalProps } from '../../../Modal'
import styled from 'styled-components'
import { StorylineDetails } from '../../interfaces/quest';
import { useFirebase } from '../../../../contexts/Firebase/Firebase'
import Button from '../../../Button';
import { removeStorylineFromLocal } from '../../../../utils/localstorageManager';

export interface DeleteQuestConfirmModalProps extends ModalProps {
  questStoryline: StorylineDetails;
  modificationButtonHandler: (type: string, QuestStoryLine: StorylineDetails | undefined) => void;
  setUserStorylines: React.Dispatch<React.SetStateAction<StorylineDetails[]>>
}

const DeleteQuestConfirmModal: React.FC<DeleteQuestConfirmModalProps> = ({ onDismiss, questStoryline, modificationButtonHandler, setUserStorylines }) => {
  const [questObj, setQuestObj] = useState<StorylineDetails>(questStoryline);
  const [countdown, setCountdown] = useState(5); // Initial countdown value in seconds
  const [deletingQuestStoryline, setDeletingStoryline] = useState(false);
  const [storylineDeleted, setStorylineDeleted] = useState(false);
  const [startCountdown, setStartCoundown] = useState(false)
  const
    {
      deleteQuestStoryLineFromDatabase,
      user
    } = useFirebase();

  const closeQuestModal = () => {
    onDismiss && onDismiss();
  };

  const deleteStorylineButtonHandler = async () => {
    if (questObj) {
      setDeletingStoryline(true);

      try {
        const deletedStorylineId = await deleteQuestStoryLineFromDatabase(user, questStoryline.id)
        setStorylineDeleted(true);
        setStartCoundown(true);

        if (deletedStorylineId) {
          removeStorylineFromLocal(deletedStorylineId)
          setUserStorylines(prevStorylines => prevStorylines.filter(storyline => storyline.id !== deletedStorylineId));
          modificationButtonHandler("unset", undefined)
        }
      } catch (error) {
        console.error("Error deleting Quest Storyline:", error);
      } finally {
        setDeletingStoryline(false);
      }
    }
  }

  useEffect(() => {
    if (countdown > 0 && startCountdown) {
      // Countdown logic seems correct; just ensure it starts under the right conditions
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0 && startCountdown) {
      onDismiss && onDismiss(); // Close the modal immediately after countdown endsv

    }
  }, [countdown, startCountdown, onDismiss]);

  const getModalTitle = (): string => {
    if (deletingQuestStoryline && !storylineDeleted) {
        return "Attempting to delete your quest storyline"
    } else if (!deletingQuestStoryline && storylineDeleted) {
        return "Quest storyline has been succesfully deleted"
    } else {
        return "Are you sure you want to delete this Quest Storyline?"
    }
  };
  return (
    <Modal title={getModalTitle()} hideScroll={deletingQuestStoryline || storylineDeleted}>
      <QuestModalContainer>
        {deletingQuestStoryline || storylineDeleted ? (
          <SavingQuestContainer>
            <div>

              {deletingQuestStoryline && !storylineDeleted ? (
                <p>"Your quest storyline is being deleted. Please wait as we process your changes. Thank you for your patience."</p>
              ) : !deletingQuestStoryline && storylineDeleted ? (
                <>
                  <p>Your quest storyline has been <b>successfully</b> deleted.</p>
                  <p style={{ marginTop: '8px' }}>This interface will automatically close in <b>{countdown}</b> seconds.</p></>
              ) : (
                <p>"An error has happened. Please contact the administrators and they will help you."</p>
              )}

            </div>
            <div>
              <NewQuestButtonContainer onClick={closeQuestModal}>
                <div>
                  <h3>Close Interface</h3>
                </div>
              </NewQuestButtonContainer>
            </div>
          </SavingQuestContainer>
        ) : (
          <SavingQuestContainer>
            <p>Are you sure you want to delete your quest storyline?</p>
            <Button onClick={deleteStorylineButtonHandler}>Delete Quest Storyline</Button>
            <Button onClick={closeQuestModal}>Go back</Button>
          </SavingQuestContainer>
        )}
      </QuestModalContainer>
    </Modal>
  )
}

const NewQuestButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  > div {
    padding: 4px 8px;
    background-color: #1b1111;
    border: 1px solid #3d2224;
        
    background-color: #311414;
    border: 1px solid #3d2224;
    box-shadow: 0 0 8px 7px #0000003b inset;

    transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;

    > h3 {
        color: #c1b197 !important;
        font-weight: 700;
        user-select: none;
        margin: 0;
        text-align: center;
        font-size: 1.4rem;
        transition: color 0.2s ease-in-out;
    }

    &:hover {
      cursor:pointer;
      background-color: #3f1616;
      border: 1px solid #6a393d;

      h3 {
        color: #efb754 !important;
      }
    }
  }
`

const SavingQuestContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  > div {
    height: inherit;
    width: inherit;
    max-width: 80%;
  }

  p {
    margin: 0;
    color: #8b7e6e !important;
    text-align: center;
  }
`

const ContainerSubmitQuest = styled.div`
  margin-top: 12px;  
  border-top: 1px solid #463e32;
  width: 100%;
  padding: 12px 0 0 0;
  display: flex;
  justify-content: center;
  gap: 8px;
`


const QuestModalContainer = styled.div`
position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;

  transition: height 0.3s ease-in-out;

  form {
    display: flex;
    flex-direction: column;

      input {
        display: block;
      }

       input {
        background: #1f1e1d;
      
        margin-bottom: 0px;

        padding: 2px 6px;
        text-align: left;

        font-family: 'Goudy Bookletter 1911', sans-serif;
        letter-spacing: 1px;
        line-height: 1.1;
        font-size: 18px;
        color: #A99C8EFF;
        border: 1px solid #635a4d;
        padding: 2px 6px;
        outline: unset;
      }

      label {
        display: block;
        color: #9a9081;
      }

      span {
        color: #9a9081;
      }
        
      textarea {
        min-height: 100px;
        margin-bottom: 12px;


      }

       h2 
      {
        margin: 0 0 5px 0;
        color: #9a9081;
      }

      h3{
        color: #9a9081;
      }
  }
`


export default DeleteQuestConfirmModal