import 
{ 
    QuestObjectiveSlayCreature, 
    QuestObjectiveCollectItem, 
    QuestObjectiveEscortNpc, 
    QuestObjectiveTalkToNpc 
} from '../interfaces/quest';
import QuestObjectiveEnum from '../enums/QuestObjectiveEnum';

export const templateQuestObjectiveSlayCreature: QuestObjectiveSlayCreature = {
    type: QuestObjectiveEnum.SLAY_CREATURE,
    type_count: 0,
    npc_id: 0,
    npc_name: '',
    npc_kill_count: 0,
    id: 0
};

export const templateQuestObjectiveCollectItem: QuestObjectiveCollectItem = {
    type: QuestObjectiveEnum.COLLECT_ITEM,
    type_count: 0,
    item_id: 0,
    item_name: '',
    item_quantity: 0,
    id: 0
};

export const templateQuestObjectiveEscortNpc: QuestObjectiveEscortNpc = {
    type: QuestObjectiveEnum.ESCORT_NPC,
    type_count: 0,
    npc_id: 0,
    npc_name: '',
    id: 0
};

export const templateQuestObjectiveTalkToNpc: QuestObjectiveTalkToNpc = {
    type: QuestObjectiveEnum.TALK_WITH_NPC,
    type_count: 0,
    npc_id: 0,
    npc_name: '',
    id: 0
};