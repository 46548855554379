import { QuestDescriptionsEnum } from "./enums/QuestDescriptionsEnum";
import { QuestRewardsEnum } from "./enums/QuestRewardsEnum";
import { QuestObjectiveEnum } from "./enums/QuestObjectiveEnum";
import { QuestTitlesEnum } from "./enums/QuestTitlesEnum";
import { ProfessionsEnum } from "./enums/ProfessionsEnum";
import QuestRequirementsEnum from "../../components/quest/enums/QuestRequirements";
import { QuestRewardItemsEnum } from "./enums/QuestRewardItemsEnum"
import { NPCNamesEnum } from "./enums/NPCNamesEnum";
import {QuestStoryTypeEnum} from '../../components/quest/enums/QuestStoryType'

import {
    QuestObjectStoryLine,
    QuestStoryLine,
    QuestObject as Quest,
    QuestObjective,
    QuestRequirementPredecessor,
    QuestRequirementProfessionLevel,
    QuestRequirement,
    QuestReward,
    QuestObjectiveCollectItem,
    QuestObjectiveEscortNpc,
    QuestObjectiveSlayCreature,
    QuestObjectiveTalkToNpc,
    QuestRewardCurrency,
    QuestRewardExperience,
    QuestRewardItem
}
    from "../../components/quest/interfaces/quest"

// Function to generate a random number within a range
const getRandomNumber = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

// Function to generate a random quest title and matching description
const generateRandomQuestTitleAndDescription = (): { title: string, description: string } => {
    const titles = Object.keys(QuestTitlesEnum); // Get the keys instead of values
    const titlevalues = Object.values(QuestTitlesEnum);

    const randomIndex = getRandomNumber(0, titles.length - 1);
    const title = titlevalues[randomIndex]
    const titleKey = titles[randomIndex];
    let description = QuestDescriptionsEnum[titleKey]; // Use the title as key directly
    if (description === undefined) {
        console.warn(`No description found for title: ${titleKey}`);
        description = "No description available";
    }
    return { title, description };
};

// Function to generate random objectives for the quest
const generateRandomObjectives = (): QuestObjective[] => {
    const objectives: QuestObjective[] = [];
    const numObjectives = getRandomNumber(1, 6);

    let talkWithCount = 0;
    let slayCreatureCount = 0;
    let collectItemCount = 0;
    let escortCount = 0;
    for (let i = 0; i < numObjectives; i++) {
        const typeIndex = getRandomNumber(0, Object.keys(QuestObjectiveEnum).length - 1);
        const type = Object.values(QuestObjectiveEnum)[typeIndex];

        switch (type) {
            case QuestObjectiveEnum.TALK_WITH_NPC:
                objectives.push({
                    id: i,
                    type_count: talkWithCount,
                    type: QuestObjectiveEnum.TALK_WITH_NPC,
                    npc_id: getRandomNumber(0, Object.keys(NPCNamesEnum).length - 1),
                    npc_name: Object.values(NPCNamesEnum)[getRandomNumber(0, Object.keys(NPCNamesEnum).length - 1)]
                } as QuestObjectiveTalkToNpc);
                talkWithCount++;
                break;
            case QuestObjectiveEnum.ESCORT_NPC:
                objectives.push({
                    id: i,
                    type_count: escortCount,
                    type: QuestObjectiveEnum.ESCORT_NPC,
                    npc_id: getRandomNumber(0, Object.keys(NPCNamesEnum).length - 1),
                    npc_name: Object.values(NPCNamesEnum)[getRandomNumber(0, Object.keys(NPCNamesEnum).length - 1)]
                } as QuestObjectiveEscortNpc);
                escortCount++;
                break;
            case QuestObjectiveEnum.COLLECT_ITEM:
                objectives.push({
                    id: i,
                    type_count: collectItemCount,
                    type: QuestObjectiveEnum.COLLECT_ITEM,
                    item_id: getRandomNumber(0, Object.keys(QuestRewardItemsEnum).length - 1),
                    item_name: Object.values(QuestRewardItemsEnum)[getRandomNumber(0, Object.keys(QuestRewardItemsEnum).length - 1)],
                    item_quantity: getRandomNumber(1, 5)
                } as QuestObjectiveCollectItem);
                collectItemCount++;
                break;
            case QuestObjectiveEnum.SLAY_CREATURE:
                objectives.push({
                    id: i,
                    type_count: slayCreatureCount,
                    type: QuestObjectiveEnum.SLAY_CREATURE,
                    npc_id: getRandomNumber(0, Object.keys(NPCNamesEnum).length - 1),
                    npc_name: Object.values(NPCNamesEnum)[getRandomNumber(0, Object.keys(NPCNamesEnum).length - 1)],
                    npc_kill_count: getRandomNumber(5, 20)
                } as QuestObjectiveSlayCreature);
                slayCreatureCount++;
                break;
        }
    }

    return objectives;
};

// Function to generate random rewards for the quest
const generateRandomRewards = (): QuestReward[] => {
    const rewards: QuestReward[] = [];
    const numRewards = getRandomNumber(1, 6);

    let currencyCount = 0;
    let itemCount = 0;
    let xpCount = 0;

    for (let i = 0; i < numRewards; i++) {
        const typeIndex = getRandomNumber(currencyCount, Object.keys(QuestRewardsEnum).length - 1);
        const type = Object.values(QuestRewardsEnum)[typeIndex];

        switch (type) {
            case QuestRewardsEnum.CURRENCY_REWARD:
                rewards.push({
                    id: i,
                    type_count: currencyCount,
                    type: QuestRewardsEnum.CURRENCY_REWARD,
                    copper: getRandomNumber(10, 100),
                    silver: getRandomNumber(1, 10),
                    gold: getRandomNumber(1, 5)
                } as QuestRewardCurrency);
                currencyCount++;
                break;
            case QuestRewardsEnum.ITEM_REWARD:
                rewards.push({
                    id: i,
                    type_count: itemCount,
                    type: QuestRewardsEnum.ITEM_REWARD,
                    item_id: getRandomNumber(0, Object.keys(QuestRewardItemsEnum).length - 1),
                    item_name: Object.values(QuestRewardItemsEnum)[getRandomNumber(0, Object.keys(QuestRewardItemsEnum).length - 1)],
                    item_quantity: getRandomNumber(1, 3)
                } as QuestRewardItem);
                itemCount++;
                break;
            case QuestRewardsEnum.XP_REWARD:
                rewards.push({
                    id: i,
                    type_count: xpCount,
                    type: QuestRewardsEnum.XP_REWARD,
                    profession_id: getRandomNumber(0, Object.keys(ProfessionsEnum).length - 1),
                    profession_name: Object.values(ProfessionsEnum)[getRandomNumber(0, Object.keys(ProfessionsEnum).length - 1)],
                    profession_experience: getRandomNumber(100, 1000)
                } as QuestRewardExperience);
                xpCount++;
                break;
        }
    }

    return rewards;
};

// Function to generate random rewards for the quest
const generateRandomRequirement = (currentQuest: Quest | undefined, quests: Quest[] | undefined, forStoryline: boolean): QuestRequirement[] => {
    const requirements: QuestRequirement[] = [];
    const numRequirements = getRandomNumber(1, 6);

    let levelReqCount = 0;
    let predecessorCount = 0;

    for (let i = 0; i < numRequirements; i++) {
        const typeIndex = forStoryline ? 0 : getRandomNumber(0, Object.keys(QuestRequirementsEnum).length - 1);
        const type = Object.values(QuestRequirementsEnum)[typeIndex];

        switch (type) {
            case QuestRequirementsEnum.LEVEL_REQUIREMENT:
                requirements.push({
                    id: i,
                    type_count: levelReqCount,
                    type: QuestRequirementsEnum.LEVEL_REQUIREMENT,
                    profession_id: getRandomNumber(0, Object.keys(ProfessionsEnum).length - 1),
                    profession_name: Object.values(ProfessionsEnum)[getRandomNumber(0, Object.keys(ProfessionsEnum).length - 1)],
                    profession_level: getRandomNumber(1, 50)
                } as QuestRequirementProfessionLevel);
                levelReqCount++;
                break;
            //case QuestRequirementsEnum.QUEST_PREDECESSOR:
            //    if (currentQuest === undefined || quests === undefined) break;
            //    const filteredQuests = quests.filter(quest => quest.id !== currentQuest.id);
            //    const randomPredecessorIndex = getRandomNumber(0, filteredQuests.length - 1);
            //    const randomPredecessor = filteredQuests[randomPredecessorIndex];
            //    requirements.push({
            //        id: i,
            //        type_count: predecessorCount,
            //        type: QuestRequirementsEnum.QUEST_PREDECESSOR,
            //        quest_id: randomPredecessor.id,
            //        quest_name: randomPredecessor.title,
            //    } as QuestRequirementPredecessor);
            //    predecessorCount++;
            //    break;
        }
    }

    return requirements;
};

// Function to generate a random quest
//export const generateRandomQuest = (): Quest => {
//    const { title, description } = generateRandomQuestTitleAndDescription();
//    const quest: Quest = {
//        id: getRandomNumber(1, 1000),
//        title,
//        description,
//        type: Math.random() < 0.5 ? "Main Story" : "Side Story",
//        objectives: generateRandomObjectives(),
//        rewards: generateRandomRewards()
//    };
//    return quest;
//};

export const generateRandomQuestStoryLines = (quantity_storylines: number, quantity_quests: number): QuestStoryLine[] => {
    const storylines: QuestStoryLine[] = [];

    for (let j = 0; j < quantity_storylines; j++) {
        let questStorylines: QuestObjectStoryLine[] = [];

        for (let i = 0; i < quantity_quests; i++) {
            const { title, description } = generateRandomQuestTitleAndDescription();
            const quest: QuestObjectStoryLine = {
                uid: "",
                id: i.toString(), // Assigning the index as the ID
                title,
                description,
                type: Math.random() < 0.5 ? QuestStoryTypeEnum.MAIN_STORY : QuestStoryTypeEnum.SIDE_STORY,
                requirements: generateRandomRequirement(undefined, undefined, true),
                objectives: generateRandomObjectives(),
                rewards: generateRandomRewards(),
                prerequisite_quests: {},
                x: 0,
                y: 0,
            };
            questStorylines.push(quest);
        }

        //let storyline: QuestStoryLine = {
        //    id: j.toString(),
        //    title: title,
        //    description: description,
        //    quests: questStorylines,
        //    type: "Main Story"
        //};
//
        //for (let i = Object.values(storyline.quests).length - 1; i >= 0; i--) {
        //    const randomAmountPrerequisites = getRandomNumber(1, 3);
        //    const prerequisites: QuestObjectStoryLine[] = [];
//
        //    // Generate prerequisite quest IDs based on quest ID and adding +1, +2, +3, ...
        //    for (let b = 1; b <= randomAmountPrerequisites; b++) {
        //        const prerequisiteQuestId = storyline.quests[i].id + b; // Generate prerequisite quest ID
//
        //        // Check if the prerequisiteQuestId is within the bounds of the quests array
        //        if (prerequisiteQuestId < Object.values(storyline.quests).length) {
        //            const prerequisiteQuest = Object.values(storyline.quests).find((object) => object.id === prerequisiteQuestId);
        //            if (prerequisiteQuest) {
        //                prerequisites.push(prerequisiteQuest);
        //            }
        //        }
        //    }
//
        //    storyline.quests[i].prerequisite_quests = prerequisites;
        //}
//
        //storylines.push(storyline);
    }

    return storylines;
};


export const generateRandomQuestsByQuantity = (quantity: number): Quest[] => {
    let quests: Quest[] = [];
    for (let i = 0; i < quantity; i++) {
        const { title, description } = generateRandomQuestTitleAndDescription();
        const quest: Quest = {
            uid: "",
            id: i.toString(), // Assigning the index as the ID
            title,
            description,
            type: Math.random() < 0.5 ? QuestStoryTypeEnum.MAIN_STORY : QuestStoryTypeEnum.SIDE_STORY,
            requirements: {},
            objectives: generateRandomObjectives(),
            rewards: generateRandomRewards()
        };
        quests.push(quest);
    }
    // Generate requirements after quests are generated
    quests = quests.map((quest) => ({
        ...quest,
        requirements: generateRandomRequirement(quest, quests, false),
    }));
    return quests;
};
// Generate a random quest
//const randomQuest = generateRandomQuest();
//console.log(randomQuest);