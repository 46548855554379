import { QuestStoryTypeEnum } from "../enums/QuestStoryType";
import { QuestObjectStoryLine } from "../interfaces/quest";

export const questObjectStorylineTemplate: QuestObjectStoryLine = {
    uid: "",
    x: 0,
    y: 0,
    id: "-69",
    title: '',
    description: '',
    type: QuestStoryTypeEnum.MAIN_STORY,
    requirements: {},
    objectives: {},
    rewards: {},
    prerequisite_quests: {}
};
