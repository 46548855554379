export const QuestDescriptionsEnum: { [key: string]: string } = {
    TheCursedRuins: "Venture into the depths of the cursed ruins, where darkness lurks at every corner and ancient evils await those brave enough to seek them out.",
    ShadowsOfDarkness: "Navigate through the shadows of darkness that shroud the land, uncovering the mysteries that lie hidden beneath the veil of night.",
    TheForgottenTomb: "Explore the forgotten tomb of an ancient civilization, where untold riches and unspeakable horrors lie in wait for those who dare to disturb its slumber.",
    EchoesOfEternity: "Embark on a journey to uncover the echoes of eternity, delving into the realms of time and space to unravel the secrets of existence itself.",
    TheSecretsOfAvalon: "Unravel the secrets of Avalon, a mystical land steeped in legend and lore, where magic flows like water and danger lurks around every corner.",
    TheLostKingdom: "Discover the lost kingdom of a fallen empire, where the echoes of a once-great civilization still ring out across the land.",
    TrialsOfValor: "Face the trials of valor and prove your worth as a hero, battling fierce foes and overcoming impossible odds to emerge victorious.",
    TheDarkenedForest: "Brave the darkened forest, where twisted trees and sinister creatures lurk in the shadows, waiting to ensnare the unwary traveler.",
    TheFallenEmpire: "Explore the ruins of a fallen empire, where the ghosts of the past still haunt the crumbling halls and forgotten tombs.",
    TheSorcerersTower: "Ascend the heights of the sorcerer's tower, where magic crackles in the air and ancient spells lie waiting to be unleashed.",
    TheHauntedMoor: "Wander through the haunted moor, where the spirits of the departed roam freely and the veil between the living and the dead grows thin.",
    TheShadowedCitadel: "Infiltrate the shadowed citadel, stronghold of the enemy, and uncover their dark designs before it's too late.",
    TheFrozenWastes: "Traverse the frozen wastes, where the bitter cold threatens to freeze the blood in your veins and the howling wind whispers of ancient secrets buried beneath the ice.",
    TheEnchantedGrove: "Explore the enchanted grove, a place of tranquility and wonder, where the trees whisper secrets and the air is thick with magic.",
    TheMysticIsles: "Sail to the mystic isles, where the sea is calm and the skies are clear, and discover the hidden treasures that lie hidden beneath the waves.",
    TheTwilightRift: "Plunge into the twilight rift, a tear in the fabric of reality where light and darkness merge and strange creatures lurk in the shadows.",
    TheLabyrinthOfChaos: "Navigate the labyrinth of chaos, a maze of twisting passages and deadly traps, and emerge victorious to claim the prize that lies at its heart.",
    TheEmeraldShores: "Walk the emerald shores, where the waves crash against the cliffs and the sand is soft beneath your feet, and find the answers you seek in the depths of the ocean.",
    TheBurningDesert: "Cross the burning desert, where the sun beats down mercilessly and the sands stretch out endlessly before you, and discover the secrets that lie hidden beneath the scorching sands.",
    TheCrystalCaves: "Delve into the crystal caves, where the walls shimmer with the light of a thousand gems and the air is filled with the sound of dripping water echoing in the darkness.",
    TheEtherealFields: "Wander the ethereal fields, where the grass sways gently in the breeze and the flowers bloom in a riot of color, and let your worries drift away on the wind.",
    TheCelestialSanctuary: "Ascend to the celestial sanctuary, a place of peace and serenity, where the stars shine brightly overhead and the heavens stretch out endlessly before you.",
    TheForbiddenCity: "Enter the forbidden city, where the streets are empty and the buildings loom ominously overhead, and uncover the dark secrets that lie hidden within its walls.",
    TheEnigmaOfEldorado: "Decipher the enigma of Eldorado, a lost city of gold rumored to lie hidden somewhere in the depths of the jungle, and claim its riches for yourself.",
    TheShatteredRealms: "Explore the shattered realms, where the fabric of reality has been torn asunder and strange creatures roam freely, and uncover the truth behind the chaos that threatens to consume the world.",
    TheWhisperingWoods: "Enter the whispering woods, where the trees whisper secrets to one another and the air is thick with magic, and discover the truth that lies hidden beneath the surface.",
    TheEternalFlame: "Seek out the eternal flame, a beacon of hope in a world consumed by darkness, and let its light guide you on your journey to greatness.",
    TheForsakenKeep: "Conquer the forsaken keep, a stronghold of darkness and despair, and reclaim it for the forces of light before it's too late.",
    TheLostLands: "Explore the lost lands, a realm of mystery and danger where ancient ruins lie hidden beneath the earth and strange creatures lurk in the shadows.",
    TheDivineTemple: "Discover the divine temple, a place of worship and reverence where the gods themselves are said to walk among mortals, and seek their blessings for your quest.",
    TheCrypticCaverns: "Descend into the cryptic caverns, a maze of twisting tunnels and hidden passages where danger lurks around every corner, and emerge victorious to claim the treasure that lies at its heart.",
    TheMysticMarsh: "Navigate the mystic marsh, a swampy wilderness where the ground is soft beneath your feet and the air is thick with the scent of decay, and uncover the secrets that lie hidden within its murky depths.",
    TheShadowOfThrone: "Challenge the shadow of the throne, a dark presence that looms over the land like a specter of death, and defeat it to claim your rightful place as ruler of the realm.",
    TheAbyssalDepths: "Plunge into the abyssal depths, a realm of darkness and despair where the waters run deep and the creatures that dwell within are beyond comprehension, and emerge triumphant to claim the prize that lies at the bottom of the sea.",
    TheDarkMoonRising: "Witness the dark moon rising, a portent of doom and destruction that heralds the coming of a great evil, and prepare yourself for the battle to come.",
    TheFrozenHeart: "Melt the frozen heart, a symbol of the cold and unforgiving nature of the world, and let warmth and light return to the land once more.",
    TheSandsOfTime: "Race against the sands of time, a relentless force that threatens to consume all in its path, and find a way to turn back the clock before it's too late.",
    TheAncientRuins: "Explore the ancient ruins, a testament to the glory of civilizations long past, and uncover the secrets that lie buried beneath the dust and rubble.",
    TheGatesOfEternity: "Pass through the gates of eternity, a portal to another realm where time has no meaning and the laws of physics are but a distant memory, and discover the truth that lies beyond the veil of reality.",
    TheTwilightGrove: "Enter the twilight grove, a place of beauty and wonder where the trees stretch up to the sky and the air is filled with the sound of birdsong, and let its tranquility wash over you.",
    TheWhispersOfWisdom: "Listen to the whispers of wisdom, a voice in the darkness that speaks of ancient truths and forgotten knowledge, and let its words guide you on your journey to enlightenment.",
    TheLostLegacy: "Reclaim the lost legacy, a birthright stolen by betrayal and deceit, and restore honor and glory to your family name.",
    TheEternalForest: "Wander through the eternal forest, a place of magic and mystery where the trees stretch up to the heavens and the animals speak in hushed tones, and discover the secrets that lie hidden within its depths.",
    TheLairOfDarkness: "Confront the lair of darkness, a place of terror and despair where evil lurks around every corner and the air is thick with the stench of death, and emerge victorious to claim your rightful place as champion of the light.",
    TheDreamersGate: "Step through the dreamer's gate, a portal to another world where the laws of reality are but a distant memory and the impossible becomes possible, and let your imagination run wild as you explore its wonders.",
    TheSecretChamber: "Discover the secret chamber, a hidden room filled with untold riches and ancient artifacts that tell the story of a civilization long forgotten, and claim its treasures for yourself.",
    TheElderScrolls: "Unravel the secrets of the elder scrolls, ancient texts that hold the key to unlocking the mysteries of the universe, and become a master of magic and lore."
}