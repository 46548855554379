import React, { useState } from 'react'
import GenericList from '../../../../GenericListContainer';
import { QuestObject } from '../../../interfaces/quest';
import QuestDataContainer from '../../../../QuestDataContainer';
import Button from '../../../../Button';
import styled, { css, keyframes } from 'styled-components';
import TextContainer from '../../../../TextContainer';

interface TabFromYourQuestsProps {
    userQuests: QuestObject[];
    addQuestButtonHandler: (QuestObject: QuestObject) => void;
    onDismissButtonHandler: () => void;
}

const TabFromYourQuests: React.FC<TabFromYourQuestsProps> = ({ userQuests, addQuestButtonHandler, onDismissButtonHandler }) => {
    const [selectedQuest, setSelectedQuest] = useState<QuestObject | null>(null);
    const [hasAnimationPlayed, setHasAnimationPlayed] = useState(false);

    const ShortenTypeQuest = (typeQuest: string) => {
        if (typeQuest == "Main Story")
            return "Main";
        else if (typeQuest == "Side Story") {
            return "Side"
        }
    }

    const handleSelectQuest = (QuestObject: QuestObject) => {
        if (!hasAnimationPlayed) {
            setHasAnimationPlayed(true);
        }
        setSelectedQuest(QuestObject);
    };

    const addQuestToStorylineButtonHandler = () => {
        if (selectedQuest !== null) {
            addQuestButtonHandler(selectedQuest);
        }
    }

    return (

        <YourQuestContainer shouldAnimate={hasAnimationPlayed}>
            <div>
                {userQuests && userQuests.length > 0 ? (


                    <div>
                        <GenericList<QuestObject>
                            items={userQuests}
                            renderItem={(quest, isSelected) => (
                                <React.Fragment>
                                    <span>{quest.title}</span>
                                    <span>{ShortenTypeQuest(quest.type)}</span>
                                </React.Fragment>
                            )}
                            getKey={(quest) => quest.id}
                            selected={selectedQuest}
                            onSelect={handleSelectQuest}
                        />
                    </div>
                ) : (
                    <ContainerNoQuests>
                    <TextContainer>
                        <h1>No Quests Currently Available</h1>
                        <p>At this moment, there are no quests to add to your storyline. Check back when you have created more quests.</p>
                    </TextContainer>
                    </ContainerNoQuests>
                )}
                {selectedQuest && (
                    <QuestDataContainer questObj={selectedQuest} canEdit={false} />
                )}
            </div>
            <ButtonContainer>
                {userQuests.length > 0 && (<Button type="button" variant="primary" onClick={addQuestToStorylineButtonHandler} disabled={false}>Add Quest To Storyline</Button>)}
                <Button type="button" variant="primary" onClick={onDismissButtonHandler} disabled={false}>Close</Button>
            </ButtonContainer>
        </YourQuestContainer>

    )
}

const ContainerNoQuests = styled.div`
    display: flex;;
    flex-direction: column
    text-align: center;
    h1 {
        color: #c1b197 !important;
        margin: 0;
    text-align: center;

    }
    
    p {
    text-align: center;

    }
`

const ButtonContainer = styled.div`
margin-top: 8px;
    padding-top: 10px;
    border-top: 1px solid #2b2824; 
    display: flex;
    justify-content: center;
    gap: 8px;
`
const slideInRightQuestData = keyframes`
from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideInRightQuestCollection = keyframes`
from {
    width: 100%;
  }
  to {
    width: 50%; /* Adjust the scale factor to how much you want to shrink */
  }
`;

interface YourQuestContainerProps {
    shouldAnimate: boolean;

}

const YourQuestContainer = styled.div<YourQuestContainerProps>`
    display: flex;
    flex-direction: column;

    >div:nth-child(1){ 
        display: flex;
        max-height: 200px;
        position: relative; /* Ensure positioning context is set for children */

        > div:nth-child(1)  {
            ${({ shouldAnimate }) => shouldAnimate ? css`
            animation: ${slideInRightQuestCollection} 0.5s forwards;
            transform-origin: right center;
        ` : ''}
            /*flex: 1;*/
            border: 1px solid #2b2824;
            width: 100%;
            display: flex;
        }

        > div:nth-child(2) {
            animation: ${slideInRightQuestData} 0.5s forwards;
            /* Ensure it will animate from a translated state */
            transform: translateX(100%);
            opacity: 0;
            /* Rest of your styling */

            max-height: 400px;
            overflow-y: scroll;
            overflow-x: hidden;
            flex: 1;
            border: 1px solid #2b2824;
            border-left: unset;
            padding: 6px;
            white-space: nowrap; /* Prevent text from wrapping */
            text-overflow: ellipsis;
            > .quest__properties__grid__container {
                grid-template-columns: repeat(2, 1fr);
            }

            /* figure this out later to make the last element stretch to full width; */
            /*> .quest__properties__grid__container > div:nth-last-child(2):nth-child(odd) {
                grid-column: 1 / -1;
            }*/
        }
    }
 `

export default TabFromYourQuests