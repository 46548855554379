export type QuestRequirementsEnumType = {
    LEVEL_REQUIREMENT: string;
    //QUEST_PREDECESSOR: string;
};

export const QuestRequirementsEnum: QuestRequirementsEnumType = {
    LEVEL_REQUIREMENT: 'Level Requirement'//,
    //QUEST_PREDECESSOR: 'Quest requirement'
};

export default QuestRequirementsEnum