import React from 'react'
import Modal, { ModalProps } from '../../../../Modal'
import styled from 'styled-components'
import { QuestObject, QuestObjectStoryLine, StorylineDetails } from '../../../interfaces/quest';
import useFetchUserQuests from '../../../../../hooks/useFetchUserQuests'
import TabFromYourQuests from './TabFromYourQuests'

export interface AddQuestToStorylineModalProps extends ModalProps {
    setQuestStorylineObjectFromModal: React.Dispatch<React.SetStateAction<QuestObjectStoryLine | null>>;
    storyline: StorylineDetails;
}

const AddQuestToStorylineModal: React.FC<AddQuestToStorylineModalProps> = ({ onDismiss, setQuestStorylineObjectFromModal, storyline }) => {
    const userQuests = useFetchUserQuests();

    const getQuestIdsFromStoryline = (storyline: StorylineDetails) => {
        const questIds = new Set();
        Object.keys(storyline.story_quests).forEach(id => {
            questIds.add(id); // Add the main quest ID
        });
        return questIds;
    };

    const storylineQuestIds = getQuestIdsFromStoryline(storyline);

    // Filter out quests that are already in the storyline
    const availableQuests = userQuests.filter(quest => !storylineQuestIds.has(quest.id));

    const addQuestToStoryline = (QuestObject: QuestObject) => {
        const questObjectStoryLine: QuestObjectStoryLine = {
            ...QuestObject,
            x: 0,
            y: 0,
            prerequisite_quests: {}
        };

        setQuestStorylineObjectFromModal(questObjectStoryLine);
        onDismissButtonHandler();
    }

    const onDismissButtonHandler = () => {
        onDismiss && onDismiss();
    }

    return (
        <Modal title={'Add Quest To Storyline'} hideScroll>
            <TabContentContainer>
                <TabFromYourQuests userQuests={availableQuests} addQuestButtonHandler={addQuestToStoryline} onDismissButtonHandler={onDismissButtonHandler}/>
            </TabContentContainer>
        </Modal>
    )
}

const TabContentContainer = styled.div`
  padding: 8px;
  box-shadow: 0 0 14px 0px inset #000000bf;
  flex: 1;
  display: flex;
  flex-direction: column;
`

export default AddQuestToStorylineModal